import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ItemForm from "./ItemForm";
import { useNavigate, useParams } from "react-router-dom";
import itemService from "../../../services/wms/itemService";
import { IProperty } from "../../../types/IProperty";
import propertyService from "../../../services/wms/propertyService";
import itemGroupService from "../../../services/wms/itemGroupService";
import brandService from "../../../services/wms/brandService";
import useCheckPermission from "../../../hooks/useCheckPermission";

const ItemEdit = (props: any) => {
  const {
    onSave = null,
    navigateBack = true,
    handleCancel = null,
    clientId = null,
    isDialog = false,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({
    name: "",
    alternateName: "",
    itemNumber: "",
    itemGroupId: 0,
    isMarked: false,
    brandId: 0,
    barcode: "",
    qrCode: "",
    isComplexItem: false,
    parameters: [],
    parts: [],
    itemGroupName: "",
  });
  const [properties, setProperties] = useState<IProperty[]>();
  const [groups, setGroups] = useState<any[]>([{ id: "", name: "" }]);
  const [brands, setBrands] = useState<any[]>([{ id: "", name: "" }]);
  const { checkPermission } = useCheckPermission();
  const [items, setItems] = useState<any[]>([]);
  const id = clientId !== null ? clientId : params.id;

  const loadProperties = (itemGroupId = null) => {
    dispatch({ type: "SHOW_QUERY" });
    propertyService
      .list(itemGroupId)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
          return;
        }
        setProperties(response.records);
      })
      .finally(() => dispatch({ type: "HIDE" }));
  };

  useEffect(() => {
    itemGroupService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setGroups(response.records);
      }
    });

    brandService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setBrands(response.records);
      }
    });
  }, []);

  useEffect(() => {
    loadProperties();
  }, []);

  useEffect(() => {
    if (id) {
      itemService.get(id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
        } else {
          setEntity(response.result);
        }
      });
    }
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: "SHOW_SAVE" });
    itemService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Sikeres mentés!", {
            variant: "success",
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: "HIDE" });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    dispatch({ type: "SHOW_QUERY" });
    itemService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setItems(response.records);
      }
      dispatch({ type: "HIDE" });
    });
  }, []);

  return (
    <ItemForm
      entity={entity}
      readonly={!checkPermission(["ItemEdit"])}
      onItemGroupChange={(itemGroupId) => {
        loadProperties(itemGroupId);
      }}
      onNewItemGroupAdded={(itemGroup) => {
        setGroups([...groups, itemGroup]);
      }}
      properties={properties}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      onNewPropertyAdded={(property) => {
        setProperties([...properties, property]);
      }}
      onNewBrandAdded={(brand) => {
        setBrands([...brands, brand]);
      }}
      groups={groups}
      brands={brands}
      onSubmit={onSubmit}
      errors={errors}
      items={items}
      isDialog={isDialog}
    />
  );
};

export default ItemEdit;
