import { Delete, Print, Sync } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import { useBarcodeScanner } from '../../components/barcode-scanner/useBarcodeScanner';
import useCheckPermission from '../../hooks/useCheckPermission';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import acquisitionService from '../../services/acquisitions/acquisitionService';
import itemPriceService from '../../services/pricing/itemPriceService';
import {
  reset,
  setForm,
} from '../../stateManagement/actions/acquisitionActions';
import { AcquisitionStatuses } from '../../types/AcquisitionStatuses';
import { ClientTypes } from '../../types/ClientTypes';
import { EntityStatuses } from '../../types/EntityStatuses';
import ClientCreate from '../crm/clients/ClientCreate';
import ExpenseForm from '../erp/expenses/ExpenseForm';
import AcquisitionHistoryDialog from '../pricing/acquisitionPrices/AcquisitionHistoryDialog';
import AcquisitionItemList from './AcquisitionItemList';
import { parseJSON } from 'date-fns';

const AcquisitionForm = (props: any) => {
  const { acquisitionInProgress } = useSelector<any>(
    (state) => state.acquisition
  ) as any;

  const dispatch = useDispatch<any>();
  const { isDialog = false } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();
  const paymentTypes = ['Készpénz', 'Bankkártya', 'Átutalás', 'Utánvét'];

  const { setConfirmParams, ConfirmDialog } = useConfirmDialog();

  const [expenseDialogState, setExpenseDialogState] = useState({
    open: false,
    entity: {
      clinetId: '',
      companyId: '',
      taxTypeId: 0,
      paymentType: 'Készpénz',
      receiptNumber: '',
      paymentDeadline: new Date(),
      date: new Date(),
      isPaid: false,
      dateOfPayment: '',
      priceGross: 0,
      description: '',
      expenseTypeId: 0,
      status: EntityStatuses.SentToApproval,
    },
  });

  const {
    entity,
    items,
    warehouses,
    clients,
    onSubmit,
    errors,
    onClientChange,
    onNewItemAdded,
    onClientAdded,
    readonly = false,
    expenseTypes,
    setExpenseTypes,
    taxes,
  } = props;
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState({
    open: false,
    row: { acquisitionHistory: {}, itemId: 0 },
  });

  const [itemStocks, setItemStocks] = useState<any[]>([]);

  const {
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    errors: validationErrors,
  } = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    onSubmit: (values) => {
      setConfirmParams({
        open: true,
        name: 'Biztosan menteni szeretné a beszerzést?',
        onConfirm: async () => onSubmit(values),
      });
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.clientId) {
        errors.clientId = 'Kötelező mező!';
      }
      if (!values.companyId) {
        errors.companyId = 'Kötelező mező!';
      }
      if (!values.paymentType || values.paymentType === '') {
        errors.paymentType = 'Kötelező mező!';
      }

      if (!values.warehouseId) {
        errors.warehouseId = 'Kötelező mező!';
      }

      if (!values.invoiceNumber) {
        errors.invoiceNumber = 'Kötelező mező!';
      }
      if (!values.date) {
        errors.date = 'Kötelező mező!';
      }

      if (values.acquisitions !== null && values.acquisitions?.length === 0) {
        errors.acquisitions = 'Termék lista üres!';
      }

      for (
        let index = 0;
        values.acquisitions != null && index < values.acquisitions?.length;
        index++
      ) {
        if (!values.acquisitions[index].unitPrice) {
          if (!errors.acquisitions) {
            errors.acquisitions = [];
          }
          errors.acquisitions[index] = {
            ...errors.acquisitions[index],
            unitPrice: 'Kötelező mező!',
          };
        }
        if (!values.acquisitions[index].externalItemNumber) {
          if (!errors.acquisitions) {
            errors.acquisitions = [];
          }
          errors.acquisitions[index] = {
            ...errors.acquisitions[index],
            externalItemNumber: 'Kötelező mező!',
          };
        }
        if (
          !values.acquisitions[index].count ||
          !(values.acquisitions[index].count > 0)
        ) {
          if (!errors.acquisitions) {
            errors.acquisitions = [];
          }
          errors.acquisitions[index] = {
            ...errors.acquisitions[index],
            count: 'Kötelező mező!',
          };
        }
        if (
          !values.acquisitions[index].taxTypeId ||
          !(values.acquisitions[index].taxTypeId > 0)
        ) {
          if (!errors.acquisitions) {
            errors.acquisitions = [];
          }
          errors.acquisitions[index] = {
            ...errors.acquisitions[index],
            taxTypeId: 'Kötelező mező!',
          };
        }
      }

      return errors;
    },
  });

  const loadData = useCallback(() => {
    let ac = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    itemPriceService
      .listAcquisitionPrices(ac.signal)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setItemStocks(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      ac.abort();
    };
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (acquisitionInProgress && values.id === 0) {
        dispatch(setForm(values));
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [values, acquisitionInProgress]);

  const handleBarcodeRead = (barcode) => {
    if (barcode.item) {
      handleAddItemToAcquisiton(barcode.item);
    } else {
      enqueueSnackbar('Ismeretlen árucikk', {
        variant: 'error',
      });
    }
  };

  useBarcodeScanner(handleBarcodeRead, items);

  const handleAddItemToAcquisiton = (item) => {
    const found = values.acquisitions?.find((x) => x.itemId === item.id);
    if (!found) {
      setFieldValue('acquisitions', [
        ...(values.acquisitions ?? []),
        {
          itemId: item.id,
          externalItemNumber: item.externalItemNumber ?? '',
          unitPrice: item.unitPrice,
          count: item.count,
          taxTypeId: item.taxTypeId,
        },
      ]);
    } else {
      enqueueSnackbar('Kiválasztott termék már szerepel a listán!', {
        variant: 'warning',
      });
    }
  };

  const sumNet = values.acquisitions?.reduce(
    (acc, cur) => acc + cur.count * cur.unitPrice,
    0
  );

  const expenseTypeFormat = (expenseTypeId: number) => {
    let expenseType = expenseTypes.find((et) => et.id === expenseTypeId);
    return expenseType?.name ?? '';
  };

  const sumExpenses = values.expenses?.reduce(
    (acc, cur) => parseInt(acc) + parseInt(cur.priceGross),
    0
  );

  function convertObjectsToArrays(objects: any[]) {
    if (!Array.isArray(objects)) {
      throw new Error('Input must be an array of objects');
    }

    if (objects?.length === 0) {
      return [];
    }

    const translationMap = {
      externalItemNumber: 'Cikkszám',
      count: 'Darabszám',
    };

    const filteredKeys = ['externalItemNumber', 'count'];
    const headers = filteredKeys.map((key) =>
      translationMap[key]?.toUpperCase()
    );
    const rows = objects.map((object) => {
      if (typeof object !== 'object') {
        throw new Error('Elements of the input array must be objects');
      }

      return filteredKeys.map((key) => {
        const value = object[key];
        if (new Date(value).toString() !== 'Invalid Date') {
          const date = new Date(value);
          if (isNaN(date.getTime())) {
            return parseJSON(value).toLocaleDateString();
          }
          return parseJSON(value).toLocaleDateString();
        }
        if (typeof value === 'boolean') {
          return value ? 'Igen' : 'Nem';
        }

        return value;
      });
    });

    return [headers, ...rows];
  }

  function exportToExcel(data: any) {
    var array = convertObjectsToArrays(data);
    const ws = XLSX.utils.aoa_to_sheet(array);

    // Calculate column widths based on content length and header length
    const columnWidths = array[0].map((header, columnIndex) => {
      const columnValues = array.map((row) => String(row[columnIndex]));
      const headerLength = header?.length;
      const maxContentLength = Math.max(
        headerLength,
        ...columnValues.map((value) => value?.length)
      );
      return maxContentLength + 2; // Add extra space for readability
    });

    // Set column widths
    ws['!cols'] = columnWidths.map((width) => ({ width }));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Adatsor');
    const date = new Date();
    const dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const fileName = `Megrendelés_${dateString}.xlsx`;
    XLSX.writeFile(wb, fileName);
  }

  const getAcquisitionHistory = () => {
    return (
      itemStocks.find((x) => x.itemId === openDialog.row.itemId)
        ?.acquisitionHistory ?? []
    );
  };

  const syncItems = (clientId, warehouseId) => {
    if (clientId && warehouseId) {
      dispatch({ type: 'SHOW_QUERY' });
      acquisitionService
        .syncItems(clientId, warehouseId)
        .then((response) => {
          if (!response.hasError) {
            setFieldValue('acquisitions', response.records);
            enqueueSnackbar('Sikeres szinkronizáció', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(response.error, {
              variant: 'error',
            });
          }
        })
        .finally(() => {
          dispatch({ type: 'HIDE' });
        });
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Paper>
        <Grid container justifyContent="left" spacing={2} p={5}>
          <h2>Beszerzés {values.id > 0 ? 'szerkesztése' : 'létrehozása'}</h2>
          <Grid xs={12} item pt={5}>
            <Box color="red">
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
              {values.acquisitions &&
                values.acquisitions?.length === 0 &&
                validationErrors.acquisitions && (
                  <li>{validationErrors.acquisitions as string}</li>
                )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="companyId"
              disabled={values.id > 0 || readonly}
              value={values?.companyId ?? ''}
              onChange={(event, value) => {
                setFieldValue('companyId', value);
              }}
              getOptionLabel={(option) =>
                clients?.find((g) => g.id === option)?.companyName ?? ''
              }
              options={clients
                ?.filter((y) => y.clientType === ClientTypes.Distributor)
                .map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={!!touched.companyId && !!validationErrors.companyId}
                  helperText={
                    !!touched.companyId &&
                    !!validationErrors.companyId &&
                    (validationErrors.companyId as string)
                  }
                  fullWidth
                  label="Számlakibocsátó"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <CreateEntityDecorator
              hideAdd={values.id > 0 || checkPermission(['ClientAdd'])}
              AutocompleteComponent={
                <Autocomplete
                  disablePortal
                  id="clientId"
                  disabled={values.id > 0 || readonly}
                  value={values.clientId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('clientId', value);
                    onClientChange(value);
                  }}
                  getOptionLabel={(option) => {
                    var found = clients?.find((g) => g.id === option);
                    if (found) {
                      return `${
                        found?.companyName === ''
                          ? found?.name?.fullName
                          : found.companyName
                      } (${found.taxNumber}, ${found.id})}`;
                    } else return '';
                  }}
                  options={clients
                    // .filter((x) => x.clientType === ClientTypes.Supplier)
                    ?.map((g) => g.id)}
                  filterOptions={(options, params) => {
                    // Convert the search term to lowercase for case-insensitive search
                    const searchTermLower = params.inputValue.toLowerCase();

                    // Perform a fuzzy search by splitting the search term into tokens
                    const searchTokens = searchTermLower.split(/\s+/);

                    // Filter items based on the search tokens
                    const filteredItems = clients.filter((client) => {
                      // Convert the item name to lowercase
                      let name = `${
                        client?.companyName === ''
                          ? client?.name?.fullName
                          : client.companyName
                      } (${client.taxNumber}, ${client.id})}`;

                      const itemNameLower = name.toLowerCase();

                      // Check if each token is present in the item name
                      return searchTokens.every((token) =>
                        itemNameLower.includes(token)
                      );
                    });

                    return filteredItems.map((client) => client.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Beszállító"
                      required
                      fullWidth
                      error={!!touched.clientId && !!validationErrors.clientId}
                      helperText={
                        !!touched.clientId &&
                        !!validationErrors.clientId &&
                        (validationErrors.clientId as string)
                      }
                    />
                  )}
                />
              }
              onSave={(client) => {
                onClientAdded(client);
                setFieldValue('clientId', client.id);
                setFieldValue('externalItemNumber', '');
              }}
              CreateEntityComponent={ClientCreate}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              disablePortal
              id="warehouseId"
              value={values.warehouseId ?? ''}
              disabled={values.id > 0 || readonly}
              onChange={(event, value) => {
                setFieldValue('warehouseId', value);
                window.localStorage.setItem('acquisition.warehouseId', value);
              }}
              getOptionLabel={(option) =>
                warehouses.find((g) => g.id === option)?.name ?? ''
              }
              options={warehouses.map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  label="Raktár"
                  error={
                    !!touched.warehouseId && !!validationErrors.warehouseId
                  }
                  helperText={
                    !!touched.warehouseId &&
                    !!validationErrors.warehouseId &&
                    (validationErrors.warehouseId as string)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              value={values.invoiceNumber}
              onChange={handleChange}
              label="Számla sorszáma"
              required
              disabled={readonly}
              name="invoiceNumber"
              fullWidth
              error={
                !!touched.invoiceNumber && !!validationErrors.invoiceNumber
              }
              helperText={
                !!touched.invoiceNumber &&
                !!validationErrors.invoiceNumber &&
                (validationErrors.invoiceNumber as string)
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              value={values.orderNumber}
              onChange={handleChange}
              label="Megrendelés száma"
              disabled={readonly}
              name="orderNumber"
              fullWidth
              error={!!touched.orderNumber && !!validationErrors.orderNumber}
              helperText={
                !!touched.orderNumber &&
                !!validationErrors.orderNumber &&
                (validationErrors.orderNumber as string)
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              disablePortal
              id="paymentType"
              disabled={readonly}
              value={values?.paymentType ?? ''}
              onChange={(event, value) => {
                setFieldValue('paymentType', value);
              }}
              options={paymentTypes?.map((g) => g)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={
                    !!touched.paymentType && !!validationErrors.paymentType
                  }
                  helperText={
                    !!touched.paymentType &&
                    !!validationErrors.paymentType &&
                    (validationErrors.paymentType as string)
                  }
                  fullWidth
                  label="Fizetési mód"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DesktopDatePicker
              label="Beszerzés ideje"
              value={values.date ?? ''}
              inputFormat="yyyy-MM-dd"
              disabled={readonly}
              onChange={(value) => setFieldValue('date', value)}
              renderInput={(params) => (
                <TextField
                  required
                  fullWidth
                  {...params}
                  error={!!touched.date && !!validationErrors.date}
                  helperText={
                    !!touched.date &&
                    !!validationErrors.date &&
                    (validationErrors.date as string)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DesktopDatePicker
              label="Fizetési határidő"
              value={values.paymentDeadLine ?? ''}
              inputFormat="yyyy-MM-dd"
              disabled={readonly}
              onChange={(value) => setFieldValue('paymentDeadLine', value)}
              renderInput={(params) => (
                <TextField
                  required
                  fullWidth
                  {...params}
                  error={
                    !!touched.paymentDeadLine &&
                    !!validationErrors.paymentDeadLine
                  }
                  helperText={
                    !!touched.paymentDeadLine &&
                    !!validationErrors.paymentDeadLine &&
                    (validationErrors.paymentDeadLine as string)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              value={sumExpenses ?? 0}
              label="Kiadások"
              type="number"
              disabled={true}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">HUF</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              value={sumNet}
              label="Tételek nettó végösszege"
              type="number"
              disabled
              name="sumNet"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">HUF</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values.description ?? ''}
              onChange={handleChange}
              label="Megjegyzés"
              multiline
              rows={5}
              disabled={readonly}
              name="description"
              fullWidth
              error={!!touched.description && !!validationErrors.description}
              helperText={
                !!touched.description &&
                !!validationErrors.description &&
                (validationErrors.description as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2, paddingBottom: 2 }}>
              Raktárba helyezés
            </Divider>
          </Grid>
          <Grid item container xs={12} justifyContent={'end'}>
            <Grid item textAlign={'right'}>
              <Tooltip title="Terméklista szinkronizálása">
                <IconButton
                  disabled={
                    readonly ||
                    !(values.clientId > 0) ||
                    !(values.warehouseId > 0)
                  }
                  onClick={() => {
                    syncItems(values.clientId, values.warehouseId);
                  }}
                >
                  <Sync
                    color={
                      readonly ||
                      !(values.clientId > 0) ||
                      !(values.warehouseId > 0)
                        ? 'action'
                        : 'primary'
                    }
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item textAlign={'right'}>
              <Tooltip title="Terméklista exportálása">
                <IconButton
                  onClick={() => {
                    exportToExcel(values.acquisitions);
                  }}
                >
                  <Print color={'primary'} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AcquisitionItemList
              disabled={readonly}
              values={values}
              taxes={taxes}
              setFieldValue={setFieldValue}
              handleAddItemToAcquisiton={handleAddItemToAcquisiton}
              warehouses={warehouses}
              items={items}
              onNewItemAdded={onNewItemAdded}
              errors={validationErrors}
              touched={touched}
              getAcquisitionHistory={getAcquisitionHistory}
              loadData={loadData}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              itemStocks={itemStocks}
            />
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ mt: 2, paddingBottom: 2 }}>
                Kiadások
              </Divider>
            </Grid>
            <Grid item xs={12} justifyContent={'right'}>
              <Button
                disabled={readonly}
                variant="outlined"
                onClick={() => {
                  setExpenseDialogState({
                    open: true,
                    entity: {
                      clinetId: '',
                      companyId: '',
                      taxTypeId: 1,
                      isPaid: false,
                      dateOfPayment: '',
                      paymentType: values.paymentType ?? '',
                      receiptNumber: values.invoiceNumber ?? '',
                      paymentDeadline: values.paymentDeadLine ?? new Date(),
                      date: new Date(),
                      priceGross: 0,
                      description: 'Beszerzési költség',
                      expenseTypeId: -1,
                      status: EntityStatuses.SentToApproval,
                    },
                  });
                }}
              >
                Hozzáadás
              </Button>
            </Grid>
            {values?.expenses?.map((x, i) => {
              return (
                <Grid
                  item
                  container
                  spacing={2}
                  pt={2}
                  pb={1}
                  key={i}
                  alignItems="center"
                  textAlign={'center'}
                  style={{ borderBottom: '1px solid' }}
                >
                  <Grid item xs={12} md={2}>
                    <TextField
                      value={expenseTypeFormat(x.expenseTypeId)}
                      variant="standard"
                      label="Kiadás típusa"
                      type="text"
                      name="receiptNumber"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      value={x.priceGross ?? 0}
                      variant="standard"
                      label="Összeg"
                      type="number"
                      name="amount"
                      fullWidth
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Ft</InputAdornment>
                        ),
                      }}
                      sx={{ input: { textAlign: 'right' } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      value={x.receiptNumber}
                      variant="standard"
                      label="Bizonylatszám"
                      type="text"
                      name="receiptNumber"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      value={x.paymentType}
                      variant="standard"
                      label="Fizetési mód"
                      type="text"
                      name="receiptNumber"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      value={x.description}
                      variant="standard"
                      label="Leírás"
                      type="text"
                      name="expenseName"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{ position: 'relative', right: { xs: '0%' } }}
                  >
                    <IconButton
                      disabled={readonly}
                      onClick={() => {
                        setFieldValue('expenses', [
                          ...values.expenses?.filter(
                            (y) => y.receiptNumber !== x.receiptNumber
                          ),
                        ]);
                      }}
                      color={'primary'}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid container item pt={3} justifyContent="left">
            {!readonly && (
              <Grid item p={1}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!values.clientId}
                >
                  Mentés
                </Button>
              </Grid>
            )}
            {!readonly && !(values.id > 0) && (
              <Grid item p={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFieldValue('status', AcquisitionStatuses.Draft);
                    handleSubmit();
                  }}
                >
                  Mentés Piszkozatként
                </Button>
              </Grid>
            )}
            {!isDialog && (
              <Grid item p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    navigate('/acquisitions');
                    dispatch(reset());
                  }}
                >
                  Mégse
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Dialog
          open={expenseDialogState.open}
          onClose={() =>
            setExpenseDialogState({ ...expenseDialogState, open: false })
          }
        >
          <DialogContent style={{ padding: '0px' }}>
            <ExpenseForm
              entity={expenseDialogState.entity}
              readonly={readonly}
              isClosingSheet={true}
              taxes={taxes}
              onSubmit={(expense) => {
                setFieldValue('expenses', [...values.expenses, expense]);
                setExpenseDialogState({
                  ...expenseDialogState,
                  open: false,
                });
              }}
              onNewExpenseTypeAdded={(expenseType) => {
                setExpenseTypes([...expenseTypes, expenseType]);
              }}
              errors={errors}
              handleCancel={() =>
                setExpenseDialogState({
                  ...expenseDialogState,
                  open: false,
                })
              }
              expenseTypes={expenseTypes}
            />{' '}
          </DialogContent>
        </Dialog>
        <AcquisitionHistoryDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          getAcquisitionHistory={getAcquisitionHistory}
          loadData={loadData}
          hideIgnore={true}
        />
        <ConfirmDialog actionName="Mentés" />
      </Paper>
    </form>
  );
};

export default AcquisitionForm;
