import { Add, Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  SpeedDial,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useBarcodeScanner } from '../../../components/barcode-scanner/useBarcodeScanner';
import useCheckPermission from '../../../hooks/useCheckPermission';
import warehouseService from '../../../services/wms/warehouseService';
import AddBasicItemDialog from './AddBasicItemDialog';
import ItemSupplyItem from './ItemSupplyItem';

const ItemSupplyForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    warehouses,
    users,
    handleLoadItemPrices,
    items,
  } = props;
  const [addBasicItemDialog, setAddBasicItemDialog] = useState<any>({
    open: false,
    item: {},
  });
  const [employeeWarehouses, setEmployeeWarehouses] = useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();
  const {
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    errors: validationErrors,
  } = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, setSubmitting);
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.comment) {
        errors.comment = 'Kötelező mező!';
      }
      return errors;
    },
  });

  useEffect(() => {
    if (entity.sourceWarehouseId > 0) {
      handleLoadItemPrices(entity.sourceWarehouseId);
    }
  }, [entity.sourceWarehouseId]);

  const handleBarcodeRead = (barcode) => {
    if (readonly) return;
    if (barcode.item) {
      setAddBasicItemDialog({ open: true, item: barcode.item });
    } else {
      enqueueSnackbar('Ismeretlen árucikk', {
        variant: 'error',
      });
    }
  };

  useBarcodeScanner(handleBarcodeRead, items);

  useEffect(() => {
    if (values?.userId > 0) {
      warehouseService.listUser(values.userId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEmployeeWarehouses(response.records);
          if (!(values.targetWarehouseId > 0) && response.records.length > 0)
            setFieldValue('targetWarehouseId', response.records[0]?.id);
        }
      });
    }
  }, [values.userId]);

  const getAvailableAmount = (itemId: number) => {
    let warehouseAmount = 0;
    let found = items.find((x) => x.itemId === itemId);
    if (found) {
      if (found.isComplexItem) {
        let partCount = found.parts?.map((x) => {
          let available = getAvailableAmount(x.partItemId);

          return { count: Math.floor(available / x.partAmount) };
        }) ?? [{ count: 0 }];
        if (partCount.length > 0) {
          let minCount = Math.min(...partCount.map((x) => x.count));
          warehouseAmount = minCount;
        }
      } else {
        warehouseAmount = found.amount;
      }
    }
    if (values.items?.length > 0) {
      let cartAmount = 0;

      if (!found.isComplexItem) {
        let complexItemsInCart = values.items.filter(
          (x) => items.find((y) => x.itemId === y.itemId)?.isComplexItem
        );
        if (complexItemsInCart.length > 0) {
          complexItemsInCart.forEach((complexItem) => {
            let complexItemParts = items.find(
              (x) => x.itemId === complexItem.itemId
            )?.parts;
            complexItemParts?.forEach((part) => {
              if (part.partItemId === itemId) {
                cartAmount += part.partAmount * complexItem.amount;
              }
            });
          });
        }
        cartAmount += values.items
          .filter((x) => x.itemId === itemId)
          .reduce((acc, cur) => (acc += cur.amount), 0);
      }
      if (values.id > 0) {
        let item = entity.items.find((x) => x.itemId === itemId);
        if (item) {
          warehouseAmount += item.amount;
        }
      }
      return warehouseAmount - cartAmount;
    }
    return warehouseAmount;
  };

  const handleDelete = useCallback(
    (itemId) => {
      setFieldValue(
        'items',
        values.items.filter((x) => x.itemId !== itemId)
      );
    },
    [values.items]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <Grid container justifyContent="left" spacing={2} p={5}>
          <h2>
            Anyagfelvétel {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
          </h2>
          <Grid xs={12} item pt={5}>
            <Box color="red">
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="userId"
              disabled={readonly || values.workItemId > 0}
              value={values.userId}
              onChange={(event, value) => {
                setFieldValue('userId', value);
              }}
              getOptionLabel={(option) => {
                let user = users.find((g) => g.id === option);
                return `${user?.fullName} (${user?.email ?? ''})`;
              }}
              options={users.map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="Dolgozó" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="sourceWarehouseId"
              disabled={readonly || values.workItemId > 0}
              value={values?.sourceWarehouseId}
              onChange={(event, value) => {
                setFieldValue('sourceWarehouseId', value);
                handleLoadItemPrices(value);
              }}
              getOptionLabel={(option) => {
                let warehouse = warehouses.find((g) => g.id === option);
                return warehouse?.name;
              }}
              options={warehouses
                .filter((x) => x.id !== values.targetWarehouseId)
                .map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="Honnan" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="targetWarehouseId"
              disabled={readonly}
              value={values?.targetWarehouseId}
              onChange={(event, value) => {
                setFieldValue('targetWarehouseId', value);
              }}
              getOptionLabel={(option) => {
                if (readonly) {
                  return warehouses.find((g) => g.id === option)?.name;
                }
                return employeeWarehouses.find((g) => g.id === option)?.name;
              }}
              options={
                readonly
                  ? warehouses.map((g) => g.id)
                  : employeeWarehouses
                      .filter((x) => x.id !== values.sourceWarehouseId)
                      .map((g) => g.id)
              }
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="Hova" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left">
              <h4>Anyagok</h4>
            </Divider>
          </Grid>
          <Grid container justifyContent="right">
            {checkPermission(['ItemSupplyAddButton']) && (
              <Grid item sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  disabled={readonly}
                  variant="outlined"
                  onClick={() =>
                    setAddBasicItemDialog({ open: true, item: {} })
                  }
                >
                  Hozzáadás
                </Button>
              </Grid>
            )}
            <Grid item container xs={12}>
              <Grid item>
                <Typography variant="h6">
                  Ellenőrizve:{' '}
                  {values.items.reduce(
                    (acc, curr) => acc + (curr.checked ? 1 : 0),
                    0
                  )}
                  /{values.items.length}
                </Typography>
              </Grid>
              {values.items?.map((x, i) => {
                return (
                  <Grid
                    container
                    spacing={2}
                    key={i}
                    pt={1}
                    alignItems="center"
                    textAlign={'center'}
                    style={{ borderBottom: '1px solid' }}
                  >
                    <ItemSupplyItem
                      items={items}
                      setFieldValue={setFieldValue}
                      getAvailableAmount={getAvailableAmount}
                      readonly={readonly}
                      enqueueSnackbar={enqueueSnackbar}
                      index={i}
                      x={x}
                    />
                    <Grid item xs={12} md={1}>
                      <IconButton
                        disabled={readonly}
                        onClick={() => {
                          handleDelete(x.itemId);
                        }}
                        color={'primary'}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={12} pt={2}>
              <TextField
                value={values?.comment}
                onChange={(e) => setFieldValue('comment', e.target.value)}
                disabled={readonly}
                multiline
                required
                minRows={3}
                label={'Megjegyzés'}
                fullWidth
                error={!!touched.comment && !!validationErrors.comment}
                helperText={
                  !!touched.comment &&
                  !!validationErrors.comment &&
                  (validationErrors.comment as string)
                }
              ></TextField>{' '}
            </Grid>
            <Grid container item pt={3} justifyContent="left">
              {!readonly && (
                <Grid item p={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!values?.items?.every((x) => x.checked)}
                  >
                    Mentés
                  </Button>
                </Grid>
              )}
              <Grid item p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  Mégse
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AddBasicItemDialog
          items={items}
          open={addBasicItemDialog.open}
          setOpen={() => {
            setAddBasicItemDialog({ open: false, item: {} });
          }}
          cart={values.items}
          scannedItem={addBasicItemDialog.item}
          addToCart={(item) => {
            const i = values.items?.find((x) => x.itemId === item.id);
            if (!i) {
              setFieldValue('items', [
                ...values.items,
                {
                  itemId: item.id,
                  amount: item.amount,
                  checked: false,
                },
              ]);
            } else {
              setFieldValue('items', [
                ...values.items?.filter((x) => x.itemId !== i.itemId),
                {
                  ...i,
                  amount: item?.amount + i?.amount,
                  checked: false,
                },
              ]);
            }
            setAddBasicItemDialog({ open: false, item: {} });
          }}
        />
        <SpeedDial
          ariaLabel="Termék hozzáadása"
          onClick={() => setAddBasicItemDialog({ open: true, item: {} })}
          sx={{
            display: { md: 'none' },
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
          icon={<Add />}
        ></SpeedDial>
      </Paper>
    </form>
  );
};

export default ItemSupplyForm;
