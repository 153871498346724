export const GRID_HUNGARIAN_LOCALE_TEXT = {
  // Root
  noRowsLabel: "Nincs megjeleníthető adat",
  noResultsOverlayLabel: "Nincs találat",
  errorOverlayDefaultLabel: "Hiba történt",

  // Density selector toolbar button text
  toolbarDensity: "Sorköz",
  toolbarDensityLabel: "Sorköz",
  toolbarDensityCompact: "Kompakt",
  toolbarDensityStandard: "Szabványos",
  toolbarDensityComfortable: "Komfortos",

  // Columns selector toolbar button text
  toolbarColumns: "Oszlopok",
  toolbarColumnsLabel: "Oszlopok",

  // Filters toolbar button text
  toolbarFilters: "Szűrők",
  toolbarFiltersLabel: "Szűrők",
  toolbarFiltersTooltipHide: "Szűrők elrejtése",
  toolbarFiltersTooltipShow: "Szűrők mutatása",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} akív szürők` : `${count} aktív szürő`,

  // Export selector toolbar button text
  toolbarExport: "Exportálás",
  toolbarExportLabel: "Exportálás",
  toolbarExportCSV: "Exportálás CSV-ként",
  toolbarExportPrint: "Nyomtatás",

  // Columns panel text
  columnsPanelTextFieldLabel: "Oszlop neve",
  columnsPanelTextFieldPlaceholder: "Oszlop neve",
  columnsPanelDragIconLabel: "Oszlopok áthelyezése",
  columnsPanelShowAllButton: "Összes megjelenítése",
  columnsPanelHideAllButton: "Összes elrejtése",

  // Filter panel text
  filterPanelAddFilter: "Szűrő hozzáadása",
  filterPanelDeleteIconLabel: "Szűrő törlése",
  filterPanelLinkOperator: "Logikai művelet",
  filterPanelOperators: "Operátor", // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: "ÉS",
  filterPanelOperatorOr: "VAGY",
  filterPanelColumns: "Oszlopok",
  filterPanelInputLabel: "Érték",
  filterPanelInputPlaceholder: "Érték",

  // Filter operators text
  filterOperatorContains: "Tartalmazza",
  filterOperatorEquals: "Egyenlő",
  filterOperatorStartsWith: "Ezzel kezdődik",
  filterOperatorEndsWith: "Ezzel végződik",
  filterOperatorIs: "Ez",
  filterOperatorNot: "Nem",
  filterOperatorAfter: "Később",
  filterOperatorOnOrAfter: "Ezen vagy később",
  filterOperatorBefore: "Előtte",
  filterOperatorOnOrBefore: "Ezen vagy előtte",
  filterOperatorIsEmpty: "Üres",
  filterOperatorIsNotEmpty: "Nem üres",
  filterOperatorIsAnyOf: "Bármelyik",

  // Filter values text
  filterValueAny: "Bármelyik",
  filterValueTrue: "Igaz",
  filterValueFalse: "Hamis",

  // Column menu text
  columnMenuLabel: "Oszlop menü",
  columnMenuShowColumns: "Oszlopok megjelenítése",
  columnMenuFilter: "Szűrő",
  columnMenuHideColumn: "Oszlop elrejtése",
  columnMenuUnsort: "Rendezés törlése",
  columnMenuSortAsc: "Rendezés növekvő sorrendben",
  columnMenuSortDesc: "Rendezés csökkenő sorrendben",

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktív szűrők` : `${count} aktív szűrő`,
  columnHeaderFiltersLabel: "Szűrők",
  columnHeaderSortIconLabel: "Rendezés",

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} sor kiválasztva`
      : `${count.toLocaleString()} sor kiválasztva`,

  // Total row amount footer text
  footerTotalRows: "Összes sor:",

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: "Kijelölés",
  checkboxSelectionSelectAllRows: "Összes kijelölése",
  checkboxSelectionUnselectAllRows: "Kijelölés törlése",
  checkboxSelectionSelectRow: "Sor kijelölése",
  checkboxSelectionUnselectRow: "Sor kijelölésének törlése",

  // Boolean cell text
  booleanCellTrueLabel: "Igaz",
  booleanCellFalseLabel: "Hamis",

  // Actions cell more text
  actionsCellMore: "Több",

  // Column pinning text
  pinToLeft: "Rögzítés balra",
  pinToRight: "Rögzítés jobbra",
  unpin: "Rögzítés törlése",

  // Tree Data
  treeDataGroupingHeaderName: "Csoportosítás",
  treeDataExpand: "Kibontás",
  treeDataCollapse: "Összecsukás",

  // Grouping columns
  groupingColumnHeaderName: "Csoportosítás",
  groupColumn: (name) => `Oszlop csoportosítása: ${name}`,
  unGroupColumn: (name) => `Oszlop csoportosításának törlése: ${name}`,

  // Master/detail
  expandDetailPanel: "Részletes adatok megjelenítése",
  collapseDetailPanel: "Részletes adatok elrejtése",

  // Used core components translation keys
  MuiTablePagination: {
    labelRowsPerPage: "Sorok száma oldalanként",
    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
    /*labelRowsPerPageAll: "Mind",
    labelRowsPerPageAllRows: "Minden sor",
    firstAriaLabel: "Első oldal",
    firstTooltip: "Első oldal",
    previousAriaLabel: "Előző oldal",
    previousTooltip: "Előző oldal",
    nextAriaLabel: "Következő oldal",
    nextTooltip: "Következő oldal",
    lastAriaLabel: "Utolsó oldal",
    lastTooltip: "Utolsó oldal", */
  },

  // Row reordering text
  rowReorderingHeaderName: "Rendezés",
};
