import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import { TransactionCommands } from "./TransactionCommands";
import { useBarcodeScanner } from "../../../components/barcode-scanner/useBarcodeScanner";
import { useSnackbar } from "notistack";

const TransactionForm = (props: any) => {
  const {
    entity,
    items,
    warehouses,
    onSubmit,
    errors,
    ContainerComponent = Paper,
    handleCancel,
    command,
  } = props;

  const {
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    touched,
    handleSubmit,
    errors: validationErrors,
  } = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {};

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, setSubmitting);
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleBarcodeRead = (barcode) => {
    if (barcode.item) {
      setFieldValue("itemId", barcode.item.id);
    } else {
      enqueueSnackbar("Ismeretlen árucikk", {
        variant: "error",
      });
    }
  };

  useBarcodeScanner(handleBarcodeRead, items);

  const getCommandName = () => {
    switch (command) {
      case TransactionCommands.Add:
        return "Termék hozzáadása";
      case TransactionCommands.Remove:
        return "Termék kivétele";
      case TransactionCommands.Discard:
        return "Termék selejtezése";
      case TransactionCommands.Move:
        return "Termék áthelyezése";
      default:
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ContainerComponent>
        <Grid container justifyContent="left" spacing={2} p={5}>
          <h2>{getCommandName()}</h2>
          <Grid xs={12} item pt={5}>
            <Box color="red">
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="itemId"
              value={values.itemId}
              onChange={(event, value) => {
                setFieldValue("itemId", value);
              }}
              getOptionLabel={(option) => {
                return `${option}.${
                  items.find((g) => g.id === option)?.name ?? ""
                }`;
              }}
              options={items.map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Termék" />
              )}
            />
          </Grid>
          {command === TransactionCommands.Move ? (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="sourceWarehouseId"
                  value={values.sourceWarehouseId}
                  onChange={(event, value) => {
                    setFieldValue("sourceWarehouseId", value);
                  }}
                  getOptionLabel={(option) =>
                    warehouses.find((g) => g.id === option)?.name ?? ""
                  }
                  options={warehouses.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Áthelyezés innen" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="targetWarehouseId"
                  value={values.targetWarehouseId}
                  onChange={(event, value) => {
                    setFieldValue("targetWarehouseId", value);
                  }}
                  getOptionLabel={(option) =>
                    warehouses.find((g) => g.id === option)?.name ?? ""
                  }
                  options={warehouses.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Áthelyezés ide" />
                  )}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="warehouseId"
                value={values.warehouseId}
                onChange={(event, value) => {
                  setFieldValue("warehouseId", value);
                }}
                getOptionLabel={(option) =>
                  warehouses.find((g) => g.id === option)?.name ?? ""
                }
                options={warehouses.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Raktár" />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              size="small"
              type="number"
              fullWidth
              onChange={(event) => {
                setFieldValue(`amount`, event.target.value);
              }}
              label="Mennyiség"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">db</InputAdornment>
                ),
              }}
              value={values.amount}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values.description}
              onChange={handleChange}
              label="Megjegyzés"
              name="description"
              fullWidth
              error={!!touched.description && !!validationErrors.description}
              helperText={
                !!touched.description &&
                !!validationErrors.description &&
                (validationErrors.description as string)
              }
            />
          </Grid>
          <Grid container item pt={3} justifyContent="left">
            <Grid item p={1}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Mentés
              </Button>
            </Grid>
            <Grid item p={1}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Mégse
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ContainerComponent>
    </form>
  );
};

export default TransactionForm;
