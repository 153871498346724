import FileOpenIcon from '@mui/icons-material/FileOpen';
import MailIcon from '@mui/icons-material/Mail';
import { Grid, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import OrderEmailDialog from '../../sales/orders/OrderEmailDialog';
import OrderView from '../../sales/orders/OrderView';
import OfferEmailDialog from '../offers/OfferEmailDialog';
import OfferPrintView from '../offers/OfferPrintView';
import WorkItemEmailDialog from './WorkItemEmailDialog';
import { WorkItemStatus } from './WorkItemForm';
import WorkItemPrintView from './WorkItemPrintView';

export default function WorkItemPapersDialog(props: any) {
  const { open, setOpen, workItem } = props;
  const [offerEmailDialog, setOfferEmailDialog] = useState<any>({
    open: false,
    id: 0,
  });
  const [workItemEmailDialog, setWorkItemEmailDialog] = useState<any>({
    open: false,
    id: 0,
    sendAll: false,
  });
  const [orderEmailDialog, setOrderEmailDialog] = useState<any>({
    open: false,
    id: 0,
  });
  const [rows, setRows] = React.useState<any[]>([
    { name: 'Árajánlat', exists: false, id: 0 },
    { name: 'Munkalap', exists: false, id: 0 },
    { name: 'Nyugta', exists: false, id: 0 },
  ]);

  const [pdfDialog, setPdfDialog] = React.useState<any>({
    open: false,
    component: null,
  });

  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen({ open: false, workItem: null });
    setRows([
      { name: 'Árajánlat', exists: false, id: 0 },
      { name: 'Munkalap', exists: false, id: 0 },
      { name: 'Nyugta', exists: false, id: 0 },
    ]);
  };

  useEffect(() => {
    if (workItem && workItem?.id > 0) {
      if (
        workItem.offerId &&
        workItem.offerId > 0 &&
        workItem.pdfFileAttachmentId > 0
      ) {
        setRows((prev) => {
          return prev.map((x) => {
            if (x.name === 'Árajánlat') {
              return {
                ...x,
                exists: true,
                id: workItem.offerId,
              };
            }
            return x;
          });
        });
      }
      if (
        workItem.orderId &&
        workItem.orderId > 0 &&
        (workItem.workItemStatus === WorkItemStatus.Closed ||
          workItem.workItemStatus === WorkItemStatus.Evaluated) &&
        workItem.pdfFileAttachmentId > 0
      ) {
        setRows((prev) => {
          return prev.map((x) => {
            if (x.name === 'Nyugta') {
              return {
                ...x,
                exists: true,
                id: workItem.orderId,
              };
            }
            return x;
          });
        });
      }
      if (
        (workItem.workItemStatus === WorkItemStatus.Closed ||
          workItem.workItemStatus === WorkItemStatus.Evaluated) &&
        workItem.pdfFileAttachmentId > 0
      ) {
        setRows((prev) => {
          return prev.map((x) => {
            if (x.name === 'Munkalap') {
              return {
                ...x,
                exists: true,
                id: workItem.id,
              };
            }
            return x;
          });
        });
      }
    }
  }, [workItem]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Dokumentum',
      flex: 200,
    },
    {
      field: 'exists',
      headerName: 'Elérhető',
      valueFormatter: (params) => {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      disableExport: true,
      getActions: (params: GridRowParams, color: any) => [
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Megnyitás">
              <FileOpenIcon />
            </Tooltip>
          }
          disabled={!params.row.exists}
          label="Megnyitás"
          onClick={() => {
            if (params.row.name === 'Árajánlat') {
              setPdfDialog({
                open: true,
                component: <OfferPrintView offerId={params.row.id} />,
              });
            } else if (params.row.name === 'Munkalap') {
              setPdfDialog({
                open: true,
                component: <WorkItemPrintView workItemId={params.row.id} />,
              });
            } else {
              setPdfDialog({
                open: true,
                component: <OrderView orderId={params.row.id} />,
              });
            }
          }}
        />,
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Email">
              <MailIcon />
            </Tooltip>
          }
          disabled={!params.row.exists || !checkPermission(['SendEmail'])}
          label="Email"
          onClick={() => {
            if (params.row.name === 'Árajánlat') {
              setOfferEmailDialog({ open: true, id: params.row.id });
            } else if (params.row.name === 'Munkalap') {
              setWorkItemEmailDialog({ open: true, id: params.row.id });
            } else {
              setOrderEmailDialog({
                open: true,
                id: params.row.id,
              });
            }
          }}
        />,
      ],
    } as GridActionsColDef,
  ];

  return (
    <Grid>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Papirok:</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ paddingTop: 10 }}>
            <Grid item xs={12}>
              <DataList
                rows={rows}
                columns={columns}
                getRowId={(row) => row.name}
                minimal
                hideFooterPagination
                localStorageKey={'WorkItemPapersDialog'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={
              workItem?.workItemStatus !== WorkItemStatus.Closed &&
              workItem?.workItemStatus !== WorkItemStatus.Evaluated
            }
            onClick={() => {
              setWorkItemEmailDialog({
                open: true,
                id: workItem.id,
                sendAll: true,
              });
            }}
          >
            Összes küldés
          </Button>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={pdfDialog.open}
        onClose={() => {
          setPdfDialog({ open: false, component: null });
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Grid container textAlign="center" spacing={2} p={2}>
            <Grid item xs={12}>
              {pdfDialog.component}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setPdfDialog({ open: false, component: null });
            }}
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
      <OfferEmailDialog
        open={offerEmailDialog.open}
        setOpen={setOfferEmailDialog}
        offerId={offerEmailDialog.id}
        handleStatusChange={(id) => {}}
      />
      <OrderEmailDialog
        open={orderEmailDialog.open}
        setOpen={setOrderEmailDialog}
        orderId={orderEmailDialog.id}
        addressId={workItem?.workAddressId ?? 0}
      />
      <WorkItemEmailDialog
        open={workItemEmailDialog.open}
        setOpen={setWorkItemEmailDialog}
        workItemId={workItemEmailDialog.id}
        sendAll={workItemEmailDialog.sendAll}
      />
    </Grid>
  );
}
