import { Button, Grid, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import AcquisitionItem from './AcquisitionItem';
import AddItemDialog from './AddItemDialog';

const AcquisitionItemList = (props) => {
  const {
    warehouses,
    items,
    onNewItemAdded,
    values,
    setFieldValue,
    disabled,
    handleAddItemToAcquisiton,
    errors,
    touched,
    taxes,
    getAcquisitionHistory,
    loadData,
    openDialog,
    setOpenDialog,
    itemStocks,
  } = props;

  const [openAddItemDialog, setOpenAddItemDialog] = useState(false);

  const handleDelete = (itemId) => {
    let rest = values.acquisitions.filter((x) => x.itemId !== itemId);
    if (rest?.length > 0) setFieldValue('acquisitions', rest);
    else setFieldValue('acquisitions', null);
  };

  const handleAddItem = () => {
    setOpenAddItemDialog(true);
  };

  const acquisitions = values.acquisitions;

  return (
    <Stack>
      <Grid item container spacing={2} xs={12}>
        {acquisitions?.map((acquisition, index) => (
          <AcquisitionItem
            disabled={disabled}
            warehouses={warehouses}
            items={items}
            taxes={taxes}
            values={values}
            onDelete={handleDelete}
            setFieldValue={setFieldValue}
            index={index}
            key={index}
            errors={errors.acquisitions ? errors.acquisitions[index] : {}}
            touched={touched.acquisitions ? touched.acquisitions[index] : {}}
            getAcquisitionHistory={getAcquisitionHistory}
            loadData={loadData}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            itemStocks={itemStocks}
          />
        ))}
      </Grid>
      <Grid item container justifyContent="start" xs={12}>
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleAddItem}
            disabled={disabled}
          >
            Tétel hozzáadása
          </Button>
        </Grid>
      </Grid>
      <AddItemDialog
        open={openAddItemDialog}
        onNewItemAdded={onNewItemAdded}
        taxes={taxes}
        handleClose={() => setOpenAddItemDialog(false)}
        items={items.filter(
          (x) => !values.acquisitions?.find((y) => y.itemId === x.id)
        )}
        onAdd={(item) => {
          handleAddItemToAcquisiton(item);
          setOpenAddItemDialog(false);
        }}
      />
    </Stack>
  );
};

export default AcquisitionItemList;
