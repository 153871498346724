import {
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  IconButton,
  Tooltip,
  TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Print } from '@mui/icons-material';
import usePrintComponent from '../../../hooks/usePrintComponent';
import { formatCurrency } from '../../../utils/valueFormatters';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import workItemService from '../../../services/erp/workItemService';
import { useSnackbar } from 'notistack';
import taskTypeService from '../../../services/erp/taskTypeService';
import clientService from '../../../services/crm/clientService';
import userService from '../../../services/authority/userService';
import { PDFViewer } from '@react-pdf/renderer';
import { OfferPDF } from '../offers/OfferPDF';
import { WorkItemPDF } from './WorkItemPDF';

const WorkItemPrintView = (props: any) => {
  const { id } = useParams();
  const { workItemId } = props;
  const [workItem, SetWorkItem] = React.useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [taskTypes, setTaskTypes] = React.useState<any[]>([]);
  const [client, setClient] = React.useState<any>();
  const createdAt = new Date(workItem?.createdAt).toLocaleDateString('hu-HU');

  useEffect(() => {
    if (id || workItemId) {
      dispatch({ type: 'SHOW_QUERY' });
      workItemService
        .get(workItemId > 0 ? workItemId : parseInt(id))
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) SetWorkItem(response.result);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    taskTypeService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setTaskTypes(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    if (workItem) {
      dispatch({ type: 'SHOW_QUERY' });
      clientService
        .get(workItem?.clientId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setClient(response.result);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [workItem?.clientId]);

  const calculatefinalPrice = (isGross: boolean) => {
    let itemSum = 0;
    let serviceSum = 0;
    let itemPackagesSum = 0;

    if (workItem && workItem.selectedItems) {
      itemSum = workItem.selectedItems.reduce(
        (sum, item) =>
          sum +
          (isGross ? item.sellPriceGross : item.sellPriceNet) *
            (1 - item.discount / 100) *
            item.amount,
        0
      );
    }

    if (workItem && workItem.selectedServices) {
      serviceSum = workItem.selectedServices.reduce(
        (sum, service) =>
          sum +
          (isGross ? service.servicePriceGross : service.servicePriceNet) *
            (1 - service.discount / 100) *
            service.amount,
        0
      );
    }
    if (workItem && workItem.itemPackages) {
      itemPackagesSum = workItem.itemPackages.reduce(
        (sum, itemPackage) =>
          sum +
          (isGross ? itemPackage.sellPriceGross : itemPackage.sellPriceNet) *
            (1 - itemPackage.discount / 100) *
            itemPackage.amount,
        0
      );
    }

    return itemSum + serviceSum + itemPackagesSum;
  };

  return (
    <div>
      <PDFViewer
        width={'100%'}
        height={window.outerHeight - 50}
        children={
          <WorkItemPDF
            workItem={workItem}
            client={client}
            taskTypes={taskTypes}
            createdAt={createdAt}
            calculatefinalPrice={calculatefinalPrice}
            mechanicName={workItem?.mechanicName}
          />
        }
      ></PDFViewer>
    </div>
  );
};

export default WorkItemPrintView;
