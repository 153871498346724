import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PaymentsIcon from '@mui/icons-material/Payments';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import {
  Box,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePickerHeader from '../../components/DatePickerHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import financeService from '../../services/statistics/financeService';
import { formatCurrency } from '../../utils/valueFormatters';
import EmployeeRankingForm from './EmployeeRankingForm';

const EmployeeRankingPage = () => {
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor('FinancePage.title');
  const [tabValue, setTabValue] = React.useState<any>(2);
  const { checkPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery('(max-width:1400px)');
  const [data, setData] = useState<any[]>([]);
  const [isGross, setIsGross] = useState<boolean>(true);
  const [totalData, setTotalData] = useState<any>({
    totalIncomeValue: 0,
    totalPaidIncomeValue: 0,
    totalOutstandingIncomeValue: 0,
    totalDueIncomeValue: 0,
    totalIncomeValueNet: 0,
    totalPaidIncomeValueNet: 0,
    totalOutstandingIncomeValueNet: 0,
    totalDueIncomeValueNet: 0,
  });
  const [filteredTotalData, setFilteredTotalData] = useState<any>({
    totalPaidIncomeValue: 0,
    totalOutstandingIncomeValue: 0,
    totalDueIncomeValue: 0,
  });
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filteredMyData, setFilteredMyData] = useState<any[]>([]);
  const [myData, setmyData] = useState<any>({
    userId: 0,
    employeeName: '',
    imageUrl: '',
    totalIncome: 0,
    paidIncome: 0,
    outstandingIncome: 0,
    dueIncome: 0,
    commission: 0,
  });
  const [now, setNow] = useState<any>(new Date());
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: now,
    endDate: now,
  });
  const [myExpired, setMyExpired] = useState<any[]>([]);
  const [myNotExpired, setMyNotExpired] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    let abort = new AbortController();

    dispatch({ type: 'SHOW_QUERY' });
    financeService
      .employeeRankingQuery(
        {
          startDate: selectedInterval.startDate,
          endDate: selectedInterval.endDate,
        },
        abort.signal
      )
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setData(response.records);
          setmyData(response.myData);
          setMyExpired(response.myData?.dueIncomes ?? []);
          setMyNotExpired(response.myData?.outstandingIncomes ?? []);
          setTotalData(response.totalData);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });

    return () => {
      abort.abort();
    };
  }, [selectedInterval]);

  useEffect(() => {
    if (isGross) {
      setFilteredData(
        data.map((item) => {
          return {
            ...item,
            totalIncomeValue: item.totalIncomeValue,
            paidIncomeValue: item.paidIncomeValue,
            outstandingIncomeValue: item.outstandingIncomeValue,
            dueIncomeValue: item.dueIncomeValue,
          };
        })
      );
      setFilteredMyData({
        ...myData,
        totalIncomeValue: myData?.totalIncomeValue ?? 0,
        paidIncomeValue: myData?.paidIncomeValue ?? 0,
        outstandingIncomeValue: myData?.outstandingIncomeValue ?? 0,
        dueIncomeValue: myData?.dueIncomeValue ?? 0,
      });
      setFilteredTotalData({
        totalPaidIncomeValue: totalData?.totalPaidIncomeValue ?? 0,
        totalOutstandingIncomeValue:
          totalData?.totalOutstandingIncomeValue ?? 0,
        totalDueIncomeValue: totalData?.totalDueIncomeValue ?? 0,
        totalIncomeValue: totalData?.totalIncomeValue ?? 0,
      });
    } else {
      setFilteredData(
        data.map((item) => {
          return {
            ...item,
            totalIncomeValue: item.totalIncomeValueNet,
            paidIncomeValue: item.paidIncomeValueNet,
            outstandingIncomeValue: item.outstandingIncomeValueNet,
            dueIncomeValue: item.dueIncomeValueNet,
          };
        })
      );
      setFilteredMyData({
        ...myData,
        totalIncomeValue: myData?.totalIncomeValueNet ?? 0,
        paidIncomeValue: myData?.paidIncomeValueNet,
        outstandingIncomeValue: myData?.outstandingIncomeValueNet ?? 0,
        dueIncomeValue: myData?.dueIncomeValueNet ?? 0,
      });
      setFilteredTotalData({
        totalPaidIncomeValue: totalData?.totalPaidIncomeValueNet ?? 0,
        totalOutstandingIncomeValue:
          totalData?.totalOutstandingIncomeValueNet ?? 0,
        totalDueIncomeValue: totalData?.totalDueIncomeValueNet ?? 0,
        totalIncomeValue: totalData?.totalIncomeValueNet ?? 0,
      });
    }
  }, [data, myData, isGross]);

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <DatePickerHeader
              selectedInterval={selectedInterval}
              setSelectedInterval={setSelectedInterval}
              tabValue={tabValue}
              setTabValue={setTabValue}
              netGrossPicker={true}
              isGross={isGross}
              setIsGross={setIsGross}
              localStorageKey="employeeRanking"
              setNullSelectedInterval={true}
            />
            <Grid container>
              <Grid
                container
                item
                xs={12}
                style={{
                  background: '#E4E4E4',
                  borderRadius: 20,
                }}
                pl={2}
              >
                <Grid item container xs={12} xl={3} textAlign={'left'}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', xl: 'left' },
                      height: '100%',
                      wordBreak: 'break-word',
                    }}
                  >
                    <Tooltip title="Összesen">
                      <PaymentsIcon
                        fontSize="small"
                        color="primary"
                        sx={{ marginRight: '5px' }}
                      />
                    </Tooltip>
                    {formatCurrency(
                      filteredTotalData?.totalIncomeValue?.toFixed(0) ?? 0
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  xl={3}
                  container
                  alignItems={'center'}
                  textAlign={'left'}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', xl: 'left' },
                      height: '100%',
                      wordBreak: 'break-word',
                    }}
                  >
                    <Tooltip title="Teljesített">
                      <FavoriteIcon
                        fontSize="small"
                        color="success"
                        sx={{ marginRight: '5px' }}
                      />
                    </Tooltip>
                    {formatCurrency(
                      filteredTotalData.totalPaidIncomeValue?.toFixed(0) ?? 0
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} xl={3} container textAlign={'left'}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', xl: 'left' },
                      height: '100%',
                      wordBreak: 'break-word',
                    }}
                  >
                    <Tooltip title="Kintlévő">
                      <SwapVerticalCircleIcon
                        fontSize="small"
                        color="warning"
                        sx={{ marginRight: '5px' }}
                      />
                    </Tooltip>
                    {formatCurrency(
                      filteredTotalData.totalOutstandingIncomeValue?.toFixed(
                        0
                      ) ?? 0
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  xl={3}
                  container
                  alignContent={'center'}
                  textAlign={'left'}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', xl: 'left' },
                      height: '100%',
                      wordBreak: 'break-word',
                    }}
                  >
                    <Tooltip title="Lejárt">
                      <AccessTimeFilledIcon
                        fontSize="small"
                        color="error"
                        sx={{ marginRight: '5px' }}
                      />
                    </Tooltip>
                    {formatCurrency(
                      filteredTotalData.totalDueIncomeValue?.toFixed(0) ?? 0
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <EmployeeRankingForm
                  myData={filteredMyData}
                  tabValue={tabValue}
                  showGross={isGross}
                  myOutstandingOrders={myExpired
                    .concat(myNotExpired)
                    .sort(
                      (a, b) =>
                        new Date(b.dueDate).getTime() -
                        new Date(a.dueDate).getTime()
                    )}
                  allData={filteredData}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmployeeRankingPage;
