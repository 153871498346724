import moment from "moment";

//formats value to currency in hungarian forint it support string and number rounded to 0 decimal
export const formatCurrency = (value: string | number) => {
  return new Intl.NumberFormat("hu-HU", {
    style: "currency",
    currency: "HUF",
    minimumFractionDigits: 0,
  }).format(Number(value));
};

export const formatTime = (params: string | { value: string } = "timeSpan") => {
  // If params is a string, create an object with a value property set to the string value
  const value = typeof params === "string" ? params : params?.value;
  if (value === null || value === undefined || value === "") {
    return "Nincs";
  }

  // Create a Date object from the input value, assuming it is in UTC format
  const date = new Date(value);
  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  const localTime = new Date(milliseconds);

  // Format the local time as HH:mm
  const formattedTime = localTime.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  // Return the formatted time
  return formattedTime;
};

export const formatRole = (params) => {
  return params === "Dispatcher"
    ? "Diszpécser"
    : params === "Surveyor"
    ? "Felmérő"
    : params === "OfferGiver"
    ? "Ajánlatkészítő"
    : params === "ProcurementSpecialist"
    ? "Anyagbeszerző"
    : params === "Warehouseman"
    ? "Előkészítő"
    : params === "Mechanic"
    ? "Szerelő"
    : params === "Evaluator"
    ? "Kiértékelő"
    : null;
};
