import PaidIcon from '@mui/icons-material/Paid';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../utils/valueFormatters';
import RankingCardComponent from './RankingComponents/RankingCardComponent';
import RankingOutStandingOrderTableComponent from './RankingComponents/RankingOutStandingOrderTableComponent';
import RankingPodiumComponent from './RankingComponents/RankingPodiumComponent';
import RankingTableComponent from './RankingComponents/RankingTableComponent';
import useCheckPermission from '../../hooks/useCheckPermission';

const EmployeeRankingForm = (props: any) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const user = useSelector((state: any) => state.user.userInfo);
  const { myData, myOutstandingOrders, allData, tabValue, showGross } = props;
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const isSmallScreen = useMediaQuery('(max-width:1400px)');
  const { checkPermission } = useCheckPermission();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  useEffect(() => {
    if (page * rowsPerPage > myOutstandingOrders.length) {
      setPage(0);
    }
  }, [myOutstandingOrders]);

  useEffect(() => {
    setPage2(0);
  }, [allData]);

  return (
    <Box>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          xl={6}
          p={2}
          sx={{
            pr: { xs: 0, xl: 2 },
          }}
        >
          <Grid item xs={12}>
            <h2>Üdvözlöm, {user?.fullName}! </h2>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <RankingCardComponent
                allData={myData}
                cardHeader={
                  <Grid container justifyContent={'space-between'}>
                    <Grid item>
                      <Typography
                        fontWeight={'bold'}
                        display="flex"
                        alignItems="center"
                      >
                        <PaidIcon color="success" />
                        Bevétel
                      </Typography>
                    </Grid>
                    <Grid item>
                      {tabValue === 2 && (
                        <Grid item xs={12}>
                          <Grid
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                          >
                            <Grid item>
                              <Typography variant="body1">
                                Várható jutalék(bruttó):
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">
                                {formatCurrency(
                                  myData?.commission?.toFixed(0) ?? 0
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Készpénz:
                {formatCurrency(myData?.cash?.toFixed(0) ?? 0)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} pt={3}>
            <h3>Kintlévőségek:</h3>
          </Grid>
          <Grid item xs={12} pt={2}>
            <RankingOutStandingOrderTableComponent
              myOutstandingOrders={myOutstandingOrders}
              page={page}
              showGross={showGross}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} xl={6} p={2}>
          {!isSmallScreen && (
            <Grid item xs={12}>
              <RankingPodiumComponent allData={allData} />
            </Grid>
          )}
          <Grid item xs={12} pt={4}>
            <RankingTableComponent
              allData={allData}
              page={page2}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage2}
              showCommission={
                tabValue === 2 && checkPermission(['ViewCommission'])
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeRankingForm;
