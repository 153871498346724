import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { EmailTemplateType } from '../../../types/EmailTemplateType';
import juice from 'juice';
import 'suneditor/dist/css/suneditor.min.css';
import sunEditorCss from '../../../css/suneditor';

const EmailTemplateForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel, readonly = false } = props;
  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const templateTypes = [
    EmailTemplateType.Offer,
    EmailTemplateType.Order,
    EmailTemplateType.WorkItem,
    EmailTemplateType.ChimneySweepingCertificate,
  ];

  const { checkPermission } = useCheckPermission();

  const [suneditorCss, setSuneditorCss] = useState<string>(sunEditorCss);

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Email sablon{' '}
                {readonly
                  ? 'megtekintése'
                  : values.id !== 0
                  ? 'szerkesztése'
                  : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors?.map((error) => <li>{error}</li>) ?? []}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.cc}
                  onChange={handleChange}
                  label="Másolat (CC)"
                  name="cc"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.cc && !!validationErrors.cc}
                  helperText={
                    !!touched.cc &&
                    !!validationErrors.cc &&
                    (validationErrors.cc as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.replyTo}
                  onChange={handleChange}
                  label="Válasz cím"
                  name="replyTo"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.replyTo && !!validationErrors.replyTo}
                  helperText={
                    !!touched.replyTo &&
                    !!validationErrors.replyTo &&
                    (validationErrors.replyTo as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Név"
                  name="name"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.subject}
                  onChange={handleChange}
                  label="Tárgy"
                  name="subject"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.subject && !!validationErrors.subject}
                  helperText={
                    !!touched.subject &&
                    !!validationErrors.subject &&
                    (validationErrors.subject as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="type"
                  disabled={readonly}
                  value={values?.type ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('type', value);
                  }}
                  getOptionLabel={(option) =>
                    option === EmailTemplateType.Offer
                      ? 'Árajánlat'
                      : option === EmailTemplateType.Order
                      ? 'Megrendelés'
                      : option === EmailTemplateType.ChimneySweepingCertificate
                      ? 'Kéménysperői tanúsítvány'
                      : option === EmailTemplateType.WorkItem
                      ? 'Munkalap'
                      : ''
                  }
                  options={templateTypes?.map((g) => g) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!touched.type && !!validationErrors.type}
                      helperText={
                        !!touched.type &&
                        !!validationErrors.type &&
                        (validationErrors.type as string)
                      }
                      fullWidth
                      label="Típus"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SunEditor
                  getSunEditorInstance={getSunEditorInstance}
                  width="100%"
                  height="500px"
                  setContents={values.body}
                  placeholder="Please type here..."
                  disable={readonly}
                  defaultValue={values.body}
                  onChange={(content: string) => {
                    var result = juice(
                      `<div class="sun-editor-editable">${content}</div>`,
                      {
                        extraCss: suneditorCss,
                      }
                    );
                    setFieldValue('body', result);
                  }}
                  onImageUploadBefore={(files, info, uploadHandler) => {
                    return null;
                  }}
                  setOptions={{
                    buttonList: [
                      ['undo', 'redo'],
                      ['font', 'fontSize', 'formatBlock'],
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript',
                      ],
                      ['fontColor', 'hiliteColor'],
                      ['removeFormat'],
                      '/', // line break
                      ['outdent', 'indent'],
                      ['align', 'horizontalRule', 'list', 'table'],
                      ['link', 'image', 'video'],
                      ['fullScreen', 'showBlocks', 'codeView'],
                      ['preview', 'print'],
                      ['math'],
                    ],
                    katex: 'window.katex',
                    templates: [],
                  }}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        onSubmit(values);
                      }}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default EmailTemplateForm;
