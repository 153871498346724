import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ItemForm from "./ItemForm";
import { useNavigate } from "react-router-dom";
import itemService from "../../../services/wms/itemService";
import propertyService from "../../../services/wms/propertyService";
import { IProperty } from "../../../types/IProperty";
import itemGroupService from "../../../services/wms/itemGroupService";
import brandService from "../../../services/wms/brandService";
import useCheckPermission from "../../../hooks/useCheckPermission";

const ItemCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [properties, setProperties] = useState<IProperty[]>();
  const [groups, setGroups] = useState<any[]>([{ id: "", name: "" }]);
  const [brands, setBrands] = useState<any[]>([{ id: "", name: "" }]);
  const { checkPermission } = useCheckPermission();
  const [items, setItems] = useState<any[]>([]);

  const loadProperties = (itemGroupId = null) => {
    dispatch({ type: "SHOW_QUERY" });
    propertyService
      .list(itemGroupId)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
          return;
        }
        setProperties(response.records);
      })
      .finally(() => dispatch({ type: "HIDE" }));
  };

  useEffect(() => {
    itemGroupService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setGroups(response.records);
      }
    });

    brandService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setBrands(response.records);
      }
    });
  }, []);

  useEffect(() => {
    loadProperties();
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: "SHOW_SAVE" });
    itemService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Sikeres mentés!", {
            variant: "success",
          });
          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: "HIDE" });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    dispatch({ type: "SHOW_QUERY" });
    itemService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setItems(response.records);
      }
      dispatch({ type: "HIDE" });
    });
  }, []);

  return (
    <ItemForm
      readonly={!checkPermission(["ItemCreate"])}
      entity={{
        id: 0,
        name: "",
        alternateName: "",
        itemNumber: "",
        itemGroupId: 0,
        brandId: 0,
        barcode: "",
        qrCode: "",
        isMarked: false,
        parameters: [],
        parts: [],
        isComplexItem: false,
      }}
      onItemGroupChange={(itemGroupId) => {
        loadProperties(itemGroupId);
      }}
      onNewItemGroupAdded={(itemGroup) => {
        setGroups([...groups, itemGroup]);
      }}
      properties={properties}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      onNewPropertyAdded={(property) => {
        setProperties([...properties, property]);
      }}
      onNewBrandAdded={(brand) => {
        setBrands([...brands, brand]);
      }}
      groups={groups}
      brands={brands}
      onSubmit={onSubmit}
      errors={errors}
      items={items}
    />
  );
};

export default ItemCreate;
