import {
  Add as AddIcon,
  CheckCircle as ApproveIcon,
  Delete as DeleteIcon,
  Cancel as RejectIcon,
  Sync,
} from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  Badge,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import EntityNavigator from '../../../components/EntityNavigator';
import FilePageDialog from '../../../components/FilePageDialog';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import expenseService from '../../../services/erp/expenseService';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import { EntityStatuses } from '../../../types/EntityStatuses';
import { EntityTypes } from '../../../types/EntityTypes';
import { formatCurrency } from '../../../utils/valueFormatters';
import RejectDialog from '../../wms/items/components/RejectDialog';
import AnimationIcon from '@mui/icons-material/Animation';
import clientService from '../../../services/crm/clientService';
import billingoService from '../../../services/billingoService';
import { DatePicker } from '@mui/x-date-pickers';

export interface Expense {
  id: string;
  name: string;
  description: string;
}

const ExpensePage = (props: any) => {
  const { externalRows = null } = props;
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('ExpensePage.title');
  const [statusSentToApproval, setStatusSentToApproval] =
    useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusApproved, setStatusApproved] = useState<boolean>(true);
  const [syncDialog, setSyncDialog] = useState(false);
  const [startOfSync, setStartOfSync] = useState(new Date());
  const [endOfSync, setEndOfSync] = useState(new Date());
  const [status, setStatus] = useState(
    statusApproved
      ? EntityStatuses.Approved
      : statusSentToApproval
      ? EntityStatuses.SentToApproval
      : EntityStatuses.Rejected
  );
  const [openRejectDialog, setOpenRejectDialog] = useState({
    open: false,
    entity: {},
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (externalRows) {
      return setRows(externalRows);
    }
    dispatch({ type: 'SHOW_QUERY' });
    expenseService
      .list(status)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [status, externalRows]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    if (
      status === EntityStatuses.SentToApproval &&
      checkPermission(['ExpenseApprove'])
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'success'}
          icon={
            <Tooltip title="Jóváhagyás">
              <ApproveIcon />
            </Tooltip>
          }
          label="Jóváhagyás"
          onClick={() => handleApprove(params.row.id)}
        />
      );
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'error'}
          icon={
            <Tooltip title="Elutasítás">
              <RejectIcon />
            </Tooltip>
          }
          label="Elutasítás"
          onClick={() =>
            setOpenRejectDialog({ open: true, entity: params.row })
          }
        />
      );
    }

    let readonly =
      !checkPermission(['ExpenseEdit']) ||
      params.row.status !== EntityStatuses.SentToApproval;
    actions.push(
      <EntityNavigator
        entityType={EntityTypes.Expense}
        entityId={params.row.id}
        value={readonly ? 'Megtekintés' : 'Szerkesztés'}
        showIcon
        readonly={readonly}
      />
    );

    if (checkPermission(['ExpenseDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleDateString();
      },
    },
    { field: 'createdByName', headerName: 'Dolgozó', flex: 100 },
    { field: 'companyName', headerName: 'Számlakibocsátó', flex: 100 },
    {
      field: 'clientNameForQuickSearch',
      headerName: 'ÜgyfélKereső',
      flex: 100,
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'clientName',
      headerName: 'Ügyfél',
      flex: 100,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
            disableLink={externalRows}
          />
        );
      },
    },
    { field: 'acquisitionId', headerName: 'Beszerzés Azonosító', flex: 50 },
    { field: 'expenseTypeName', headerName: 'Típus', flex: 100 },
    { field: 'taxTypeName', headerName: 'Adótípus', flex: 100 },
    {
      field: 'priceGross',
      headerName: 'Ár(bruttó)',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'approverComment',
      headerName: 'Jóváhagyó megjegyzés',
      flex: 100,
    },
    {
      field: 'status',
      headerName: 'Státusz',
      flex: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.value === EntityStatuses.Draft ? (
              <span style={{ color: 'orange' }}>Tervezés alatt</span>
            ) : params.value === EntityStatuses.Approved ? (
              <span style={{ color: 'green' }}>Elfogadva</span>
            ) : params.value === EntityStatuses.Rejected ? (
              <span style={{ color: 'red' }}>Elutasított</span>
            ) : params.value === EntityStatuses.SentToApproval ? (
              <span style={{ color: '#FFCC00' }}>Elküldött</span>
            ) : null}
          </div>
        );
      },
    },
    { field: 'description', headerName: 'Leírás', flex: 200 },
    {
      field: 'actions',
      type: 'actions',
      flex: 150,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    expenseService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows?.filter((row) => row.id !== id));
      }
    });
  };

  const handleReject = (entity: any, comment: string) => {
    setOpenRejectDialog({ open: false, entity: {} });
    expenseService.reject(entity.id, comment).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres visszautasítás', {
          variant: 'success',
        });

        setRows(rows.filter((row) => row.id !== entity.id));
      }
    });
  };

  const handleApprove = (id: number) => {
    expenseService.approve(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres jóváhagyás', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Kiadások{titleDescriptor}</h2>
        </Grid>
        {!externalRows && (
          <>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                marginBottom={2}
              >
                <RadioGroup
                  row
                  value={
                    statusApproved
                      ? 'approved'
                      : statusSentToApproval
                      ? 'sentToApproval'
                      : 'rejected'
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    setStatusApproved(value === 'approved');
                    setStatusSentToApproval(value === 'sentToApproval');
                    setStatusRejected(value === 'rejected');
                    setStatus(
                      value === 'approved'
                        ? EntityStatuses.Approved
                        : value === 'sentToApproval'
                        ? EntityStatuses.SentToApproval
                        : EntityStatuses.Rejected
                    );
                  }}
                >
                  <FormControlLabel
                    value="approved"
                    control={<Radio color="primary" />}
                    label="Jóváhagyott"
                  />
                  <FormControlLabel
                    value="sentToApproval"
                    control={<Radio color="primary" />}
                    label="Jóváhagyásra váró"
                  />
                  <FormControlLabel
                    value="rejected"
                    control={<Radio color="primary" />}
                    label="Elutasított"
                  />
                </RadioGroup>
              </Stack>
            </Grid>
            <Grid container item xs={12} p={2} justifyContent="end">
              {checkPermission(['ExpenseSync']) && (
                <Tooltip title="Kiadás szinkronizálása">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setSyncDialog(true);
                      }}
                      color="primary"
                    >
                      <Sync />
                    </IconButton>
                  </Grid>
                </Tooltip>
              )}
              {checkPermission(['ExpenseCreate']) && (
                <Grid item>
                  <Tooltip title="Kiadás létrehozása">
                    <IconButton
                      component={RouterLink}
                      to={`/erp/expenses/create`}
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'ExpensePage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.Expense}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'ExpensePage'}
        />
        <RejectDialog
          open={openRejectDialog.open}
          entity={openRejectDialog.entity}
          setOpen={() =>
            setOpenRejectDialog((state) => ({ ...state, open: false }))
          }
          onReject={handleReject}
        />
        <Dialog open={syncDialog} onClose={() => setSyncDialog(false)}>
          <DialogTitle>Szinkronizáció időpontja:</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} pt={2}>
              <Grid item>
                <DatePicker
                  value={startOfSync}
                  label="-tól:"
                  onChange={(value) => {
                    setStartOfSync(value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  value={endOfSync}
                  label="-ig:"
                  onChange={(value) => {
                    setEndOfSync(value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                dispatch({ type: 'SHOW_QUERY' });
                billingoService
                  .syncExpenses(startOfSync, endOfSync)
                  .then((e) => {
                    enqueueSnackbar('Sikeres szinkronizálás!', {
                      variant: 'success',
                    });
                    setSyncDialog(false);
                    setTimeout(() => {
                      expenseService.list(status).then((response) => {
                        if (response.canceled) return;
                        if (!response.hasError) setRows(response.records);
                        else
                          enqueueSnackbar(response.errorMessages.join(','), {
                            variant: 'error',
                          });
                      });
                    }, 1000);
                  })
                  .catch((e) => {
                    enqueueSnackbar('Sikertelen szinkronizálás!', {
                      variant: 'error',
                    });
                  })
                  .finally(() => dispatch({ type: 'HIDE' }));
              }}
              variant="contained"
              disabled={!startOfSync || !endOfSync}
            >
              Szinkronizálás
            </Button>
            <Button
              onClick={() => {
                setSyncDialog(false);
              }}
              color="primary"
              variant="outlined"
            >
              Mégse
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Paper>
  );
};

export default ExpensePage;
