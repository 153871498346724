import { createAction } from '@reduxjs/toolkit';
import { Item } from '../../pages/sales/SalesPage';

export const setWarehouseId = createAction<number>('items/setWarehouseId');
export const setIncludeUnpriced = createAction<boolean>(
  'items/setIncludeUnpriced'
);
export const setPriceCategoryId = createAction<number>(
  'items/setPriceCategoryId'
);

export const add = createAction<Item>('items/add');
export const clear = createAction('items/clear');
export const remove = createAction<number>('items/remove');
export const update = createAction<{
  item: Item;
  id: number;
}>('items/replace');
