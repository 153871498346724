import { DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';

export default function CreateEntityDialog(props: any) {
  const {
    open,
    handleCancel,
    CreateEntityComponent,
    isClientCreate = false,
    ...rest
  } = props;

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500 }}
      fullWidth
      maxWidth="lg"
      onClose={handleCancel}
    >
      <DialogContent
        sx={{ backgroundColor: isClientCreate ? '#EFEFEF' : 'white' }}
      >
        <CreateEntityComponent handleCancel={handleCancel} {...rest} />
      </DialogContent>
    </Dialog>
  );
}
