import {
  Autocomplete,
  Chip,
  Grid,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import userGroupService from '../../../../../services/authority/userGroupService';
import { translateRoleName } from '../../../../../utils/nameFormatters';
import { ResourcePlannerDialog } from '../../resourcePlanner/ResourcePlannerDialog';
import { WorkItemAssignmentStatus } from './WorkItemAssignmentStep';

export default function WorkItemAssignmentDialog(props: any) {
  const {
    open,
    setOpen,
    addToTable,
    workItemAssignments,
    roles,
    worItemId,
    isCompleted,
    taskTypeAbilities,
  } = props;
  const [workItemAssignment, setWorkItemAssignment] = React.useState({
    userId: 0,
    roleId: 0,
    WorkItemAssignmentStatus: WorkItemAssignmentStatus.InProgress,
    order: workItemAssignments?.length + 1 ?? 1,
  });
  const [employees, setEmployees] = React.useState<any[]>([
    { id: 0, fullName: '', abilities: [] },
  ]);
  const [employeeFilters, setEmployeeFilters] = useState<any[]>([]);
  const [turnOffFilter, setTurnOffFilter] = useState<boolean>(false);
  const [resourcePlannerDialogState, setResourcePlannerDialogState] =
    useState<any>({ open: false, workItemId: 0, roleId: 0 });

  const handleClose = () => {
    setEmployeeFilters([]);
    setOpen(false);
  };

  useEffect(() => {
    if (turnOffFilter) {
      setEmployeeFilters([]);
    } else {
      setEmployeeFilters(taskTypeAbilities.map((x: any) => x.id));
    }
  }, [turnOffFilter]);

  useEffect(() => {
    setWorkItemAssignment({
      userId: 0,
      roleId: 0,
      WorkItemAssignmentStatus: WorkItemAssignmentStatus.InProgress,
      order:
        workItemAssignments?.length + 1 > 0
          ? workItemAssignments?.length + 1
          : 1,
    });
  }, [open]);

  useEffect(() => {
    userGroupService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        const newEmployees = response.records
          .filter((x) => x.roles.some((y) => y === workItemAssignment.roleId))
          .map((x) => x.users)
          .flat();

        const updatedEmployees = newEmployees.map((x) => {
          let found = props.employees.find((g) => g.id === x);
          if (found) {
            return {
              id: x,
              fullName: found.fullName,
              abilities: found.abilities,
            };
          }
          return {
            id: 0,
          };
        });

        setEmployees(updatedEmployees.filter((x) => x.id !== 0));
      }
    });
  }, [workItemAssignment.roleId]);

  const { enqueueSnackbar } = useSnackbar();

  const isPlannable = () => {
    return (
      workItemAssignment.roleId ===
        roles.find((g) => g.name === 'Mechanic')?.id ||
      workItemAssignment.roleId === roles.find((g) => g.name === 'Surveyor')?.id
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
      <DialogTitle>Új hozzárendelés</DialogTitle>
      <DialogContent style={{ minHeight: '570px', paddingTop: 2 }}>
        <Grid container spacing={2} style={{ paddingTop: 5 }}>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="roleId"
              value={workItemAssignment.roleId ?? ''}
              onChange={(event, value: any) => {
                setWorkItemAssignment({
                  ...workItemAssignment,
                  userId: 0,
                  roleId: value,
                });
              }}
              getOptionLabel={(option) => {
                return translateRoleName(
                  roles.find((g) => g.id === option)?.name ?? ''
                );
              }}
              options={
                workItemAssignments?.some(
                  (x) =>
                    x.roleName === 'Warehouseman' &&
                    x.workItemAssignmentStatus ===
                      WorkItemAssignmentStatus.Assigned
                )
                  ? roles
                      .filter(
                        (y) =>
                          y.isWorkFlowRole === true && y.name === 'Mechanic'
                      )
                      .map((x) => x.id)
                  : isCompleted
                  ? roles.filter((y) => y.name === 'Evaluator').map((x) => x.id)
                  : roles
                      .filter(
                        (y) =>
                          y.isWorkFlowRole === true && y.name !== 'Dispatcher'
                      )
                      .map((x) => x.id)
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Munkakör" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={workItemAssignment?.order}
              onChange={(event) => {
                let order = parseFloat(event.target.value);
                setWorkItemAssignment({
                  ...workItemAssignment,
                  order: order,
                });
              }}
              label="Sorszám"
              name="order"
              fullWidth
              type="number"
            />
          </Grid>
          {isPlannable() && (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={turnOffFilter}
                      onChange={(e, checked) => setTurnOffFilter(checked)}
                      color="primary"
                    />
                  }
                  label="Szűrő kikapcsolása"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setResourcePlannerDialogState({
                      open: true,
                      workItemId: props.workItemId,
                      roleId: workItemAssignment.roleId,
                    });
                  }}
                >
                  Erőforrástervező
                </Button>
              </Grid>
            </>
          )}
          {!isPlannable() && workItemAssignment.roleId !== 0 && (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  disabled={workItemAssignment.roleId === 0}
                  id="employeeId"
                  value={workItemAssignment?.userId}
                  onChange={(event, value: any) => {
                    setWorkItemAssignment({
                      ...workItemAssignment,
                      userId: value,
                    });
                  }}
                  getOptionLabel={(option) => {
                    var employees = props.employees.find(
                      (g) => g.id === option
                    );

                    if (employees == null || employees === undefined) {
                      return '';
                    }

                    return `${employees?.fullName} (${employees?.email ?? ''})`;
                  }}
                  options={
                    employeeFilters.length > 0
                      ? employees
                          .filter((employee) =>
                            employeeFilters.every((filterId) =>
                              employee.abilities?.some(
                                (ability) => ability.id === filterId
                              )
                            )
                          )
                          .map((x) => x.id)
                      : employees.map((x) => x.id)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Dolgozó" fullWidth />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (
              workItemAssignment.userId !== 0 &&
              workItemAssignment.roleId !== 0 &&
              workItemAssignment.order !== 0
            ) {
              addToTable(workItemAssignment);
              setWorkItemAssignment({
                userId: 0,
                WorkItemAssignmentStatus: WorkItemAssignmentStatus.InProgress,
                roleId: 0,
                order: workItemAssignment.order + 1,
              });
            } else {
              enqueueSnackbar(
                'Nem adtál meg minden adatot vagy már létezik ilyen hozzárendelés!'
              );
            }
          }}
        >
          Hozzáadás
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
      <ResourcePlannerDialog
        open={resourcePlannerDialogState.open}
        onClose={() => {
          setResourcePlannerDialogState({ open: false, workItemId: 0 });
        }}
        employees={
          employeeFilters.length > 0
            ? employees.filter((employee) =>
                employeeFilters.every((filterId) =>
                  employee.abilities?.some((ability) => ability.id === filterId)
                )
              )
            : employees
        }
        handleResourceSelected={(
          employeeId,
          startDate: Date,
          endDate: Date,
          workItemId
        ) => {
          var updatedWorkItemAssignment = {
            ...workItemAssignment,
            userId: employeeId,
          };
          if (
            updatedWorkItemAssignment.userId !== 0 &&
            updatedWorkItemAssignment.roleId !== 0 &&
            updatedWorkItemAssignment.order !== 0
          ) {
            addToTable(updatedWorkItemAssignment, {
              employeeId: employeeId,
              startDate: startDate,
              endDate: endDate,
              workItemId: workItemId,
            });
            setWorkItemAssignment({
              userId: 0,
              WorkItemAssignmentStatus: WorkItemAssignmentStatus.InProgress,
              roleId: 0,
              order: workItemAssignment.order + 1,
            });
            setResourcePlannerDialogState({
              open: false,
              workItemId: 0,
            });
          } else {
            enqueueSnackbar(
              'Nem adtál meg minden adatot vagy már létezik ilyen hozzárendelés!'
            );
          }
        }}
        workItemId={worItemId}
      />
    </Dialog>
  );
}
