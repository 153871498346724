import { GridColDef } from '@mui/x-data-grid';
import {
  Paper,
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
  TextField,
  Autocomplete,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataList from '../../../components/DataList';
import itemPriceService from '../../../services/pricing/itemPriceService';
import priceCategoryService from '../../../services/pricing/priceCategoryService';
import { useSnackbar } from 'notistack';
import { Info, Search, Warehouse } from '@mui/icons-material';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { useBarcodeScanner } from '../../../components/barcode-scanner/useBarcodeScanner';
import ItemViewDialog from './ItemViewDialog';
import warehouseService from '../../../services/wms/warehouseService';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import { formatCurrency } from '../../../utils/valueFormatters';

export interface ItemPrice {
  itemId: number;
  itemName: string;
  totalAmmount: number;
  itemPriceCategories: any[];
}

const ItemPricePage = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [priceCategories, setPriceCategories] = useState<GridColDef[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<number>(0);
  const [itemViewDialogState, setItemViewDialogState] = useState<any>({
    open: false,
    data: {},
  });
  const [onlyInStock, setOnlyInStock] = useState(true);
  const titleDescriptor = useFunctionDescriptor('ItemPricePage.title');

  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();
  const dispatch = useDispatch<any>();
  const isSmallScreen = useMediaQuery('(min-width:600px)');

  const handleBarcodeRead = (barcode) => {
    if (barcode.item) {
      setItemViewDialogState({ open: true, data: barcode.item });
    } else {
      enqueueSnackbar('Ismeretlen árucikk', {
        variant: 'error',
      });
    }
  };

  useBarcodeScanner(handleBarcodeRead, rows);

  useEffect(() => {
    if (selectedWarehouseId === 0) return;

    dispatch({ type: 'SHOW_QUERY' });

    itemPriceService
      .list(selectedWarehouseId)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else if (response.errorMessages)
          enqueueSnackbar(response.errorMessages?.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [selectedWarehouseId]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    priceCategoryService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setCategories(response.records);
        if (response.errorMessages)
          enqueueSnackbar(response.errorMessages?.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    let selectedCategories = JSON.parse(
      window.localStorage.getItem('pricing.selectedCategories')
    );
    if (selectedCategories) setSelectedCategories(selectedCategories);

    let warehouseId = parseInt(
      window.localStorage.getItem('pricing.warehouseId')
    );
    if (!isNaN(warehouseId)) {
      setSelectedWarehouseId(warehouseId);
    }
  }, []);

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    let temp = [];
    for (var record of categories.filter((x) =>
      selectedCategories.some((c) => c === x.id)
    )) {
      let recordId = record.id;
      temp.push({
        field: record.name + 'net',
        headerName: record.name + '(Nettó)',
        width: 150,
        _valueGetter: (params) => {
          var price = params.row.itemPriceCategories.find(
            (x) => x.priceCategoryId === recordId
          );
          return parseInt(price.net?.toFixed(2));
        },
        get valueGetter() {
          return this._valueGetter;
        },
        set valueGetter(value) {
          this._valueGetter = value;
        },
      });
      temp.push({
        field: record.name + 'gross',
        headerName: record.name + '(Bruttó)',
        width: 150,
        valueGetter: (params) => {
          var price = params.row.itemPriceCategories.find(
            (x) => x.priceCategoryId === recordId
          );

          return parseInt(price.gross?.toFixed(2));
        },
      });
    }

    setPriceCategories(temp);
  }, [selectedCategories, categories]);

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const searchItems = () => {
    // Convert the search term to lowercase for case-insensitive search
    const searchTermLower = searchTerm.toLowerCase();

    var data = onlyInStock ? rows.filter((row) => row.totalAmount > 0) : rows;

    // Perform a fuzzy search by splitting the search term into tokens
    const searchTokens = searchTermLower.split(/\s+/);

    // Filter items based on the search tokens
    const filteredItems = data.filter((item) => {
      // Convert the item name to lowercase
      const itemNameLower = item.itemName.toLowerCase();

      // Check if each token is present in the item name
      return searchTokens.every((token) => itemNameLower.includes(token));
    });

    return filteredItems;
  };

  return (
    <Paper>
      <Grid container p={3} spacing={2}>
        <Grid item xs={12}>
          <h2>Árazás{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            id="warehouseId"
            value={selectedWarehouseId ?? ''}
            onChange={(event, value) => {
              window.localStorage.setItem('pricing.warehouseId', value);
              setSelectedWarehouseId(value);
            }}
            getOptionLabel={(option) =>
              warehouses.find((g) => g.id === option)?.name ?? ''
            }
            options={warehouses.map((g) => g.id)}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Raktár" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyInStock}
                onChange={(event) => setOnlyInStock(event.target.checked)}
                color="primary"
              />
            }
            label="Csak raktáron lévő termékek listázása"
          />
        </Grid>

        <Grid item xs={12} textAlign="center">
          {categories.map((x, index) => (
            <FormControlLabel
              value="top"
              key={index}
              sx={{ m: 2 }}
              control={
                <Checkbox
                  style={{ width: '100px' }}
                  checked={selectedCategories.some((c) => x.id === c)}
                  onChange={(e) => {
                    let temp = selectedCategories.filter((c) => c !== x.id);
                    temp = e.target.checked ? [...temp, x.id] : temp;

                    setSelectedCategories(temp);

                    window.localStorage.setItem(
                      'pricing.selectedCategories',
                      JSON.stringify(temp)
                    );
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={x.name}
              labelPlacement="top"
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={searchTerm ?? ''}
            onChange={(e) => handleSearchChange(e.target.value)}
            label="Termék keresése"
            name="name"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={searchItems()}
            localStorageKey={'ItemPricePage'}
            columns={[
              {
                field: 'itemName',
                headerName: 'Termék',
                width: 300,
              },
              {
                field: 'totalAmount',
                headerName: 'Raktárkészlet',
                align: 'center',
                width: 150,
                renderCell: (params) => (
                  <Grid
                    container
                    spacing={1}
                    m="auto"
                    justifyContent={isSmallScreen ? 'space-between' : 'center'}
                  >
                    <Grid item>{params.row.totalAmount} db</Grid>
                    {checkPermission(['ItemStockView']) && (
                      <Grid item>
                        <Tooltip
                          title={
                            <Grid container>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={7}>
                                <Typography>Raktár</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography>Elérhető</Typography>
                              </Grid>
                              {params.row.itemStocks.map((itemStock, index) => (
                                <Fragment key={index}>
                                  <Grid item xs={2}>
                                    <Warehouse />
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography>
                                      {itemStock.warehouseName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography>
                                      {itemStock.amount} db
                                    </Typography>
                                  </Grid>
                                </Fragment>
                              ))}
                            </Grid>
                          }
                        >
                          <Info color="info" />
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                ),
              },
              ...priceCategories,
            ]}
            getRowId={(row) => row.itemId}
          />
        </Grid>
      </Grid>
      <ItemViewDialog
        open={itemViewDialogState.open}
        entity={itemViewDialogState.data}
        handleClose={() =>
          setItemViewDialogState({
            open: false,
            data: {
              itemStocks: [],
              itemPriceCategories: [],
              itemName: '',
              itemNumber: '',
              totalAmount: 0,
            },
          })
        }
      />
    </Paper>
  );
};

export default ItemPricePage;
