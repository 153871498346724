import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Grid, Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';
import { InvoiceTypes } from '../types/InvoiceTypes';
import { InvoiceTypePickerCard } from './InvoiceTypePickerCard';
import DescriptionIcon from '@mui/icons-material/Description';

export const InvoiceTypePicker = (props) => {
  const [open, setOpen] = useState(!props.collapsed);
  const {
    color = 'white',
    invoiceType = InvoiceTypes.Invoice,
    setInvoiceType,
    disabledTypes = [],
  } = props;

  const onSelect = (type) => {
    setInvoiceType(type);
    setOpen((x) => !x);
  };

  return (
    <Paper sx={{ bgcolor: color, border: '1px solid lightgrey' }}>
      <ListItem>
        <ListItemText
          onClick={() => setOpen((x) => !x)}
          sx={{ color: 'black', userSelect: 'none' }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container item alignItems="center">
                <Grid item>
                  <Typography fontSize={'20px'} fontWeight={'bold'}>
                    {invoiceType === InvoiceTypes.Invoice ? (
                      <DescriptionIcon color="primary" fontSize="large" />
                    ) : invoiceType === InvoiceTypes.Cancellation ? (
                      <DescriptionIcon color="error" fontSize="large" />
                    ) : invoiceType === InvoiceTypes.Modification ? (
                      <DescriptionIcon color="info" fontSize="large" />
                    ) : invoiceType === InvoiceTypes.DepositInvoice ? (
                      <DescriptionIcon color="warning" fontSize="large" />
                    ) : invoiceType === InvoiceTypes.FeeColleciton ? (
                      <DescriptionIcon
                        style={{ color: '#ffd12e' }}
                        fontSize="large"
                      />
                    ) : invoiceType === InvoiceTypes.WayBill ? (
                      <DescriptionIcon
                        style={{ color: 'green' }}
                        fontSize="large"
                      />
                    ) : (
                      ''
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize={'20px'} fontWeight={'bold'}>
                    {invoiceType === InvoiceTypes.Invoice
                      ? 'Számla'
                      : invoiceType === InvoiceTypes.Cancellation
                      ? 'Sztornó'
                      : invoiceType === InvoiceTypes.Modification
                      ? 'Módosító'
                      : invoiceType === InvoiceTypes.DepositInvoice
                      ? 'Előlegszámla'
                      : invoiceType === InvoiceTypes.FeeColleciton
                      ? 'Díjbekérő'
                      : invoiceType === InvoiceTypes.WayBill
                      ? 'Szállítólevél'
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item> {open ? <ExpandLess /> : <ExpandMore />}</Grid>
          </Grid>
        </ListItemText>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container>
          {!disabledTypes.some((x) => x === InvoiceTypes.Invoice) && (
            <Grid item xs={6} p={2}>
              <InvoiceTypePickerCard
                icon={<DescriptionIcon color="primary" fontSize="large" />}
                text="Számla"
                isPicked={invoiceType === InvoiceTypes.Invoice}
                onSelect={() => {
                  onSelect(InvoiceTypes.Invoice);
                }}
              />
            </Grid>
          )}
          {!disabledTypes.some((x) => x === InvoiceTypes.FeeColleciton) && (
            <Grid item xs={6} p={2}>
              <InvoiceTypePickerCard
                icon={
                  <DescriptionIcon
                    style={{ color: '#ffd12e' }}
                    fontSize="large"
                  />
                }
                text="Díjbekérő"
                isPicked={invoiceType === InvoiceTypes.FeeColleciton}
                onSelect={() => {
                  onSelect(InvoiceTypes.FeeColleciton);
                }}
              />
            </Grid>
          )}
          {!disabledTypes.some((x) => x === InvoiceTypes.DepositInvoice) && (
            <Grid item xs={6} p={2}>
              <InvoiceTypePickerCard
                icon={<DescriptionIcon color="warning" fontSize="large" />}
                text="Előlegszámla"
                isPicked={invoiceType === InvoiceTypes.DepositInvoice}
                onSelect={() => {
                  onSelect(InvoiceTypes.DepositInvoice);
                }}
              />
            </Grid>
          )}
          {!disabledTypes.some((x) => x === InvoiceTypes.WayBill) && (
            <Grid item xs={6} p={2}>
              <InvoiceTypePickerCard
                icon={
                  <DescriptionIcon
                    style={{ color: 'green' }}
                    fontSize="large"
                  />
                }
                text="Szállítólevél"
                isPicked={invoiceType === InvoiceTypes.WayBill}
                onSelect={() => {
                  onSelect(InvoiceTypes.WayBill);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Paper>
  );
};
