import { formatCurrency } from '../../../utils/valueFormatters';

import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
  Link,
} from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
});
Font.register({
  family: 'Roboto-bold',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
});

export const OrderPDF = (props) => {
  const { order, calculatefinalPrice, client, addressId } = props;

  const styles = StyleSheet.create({
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableCol: {
      width: '20%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    tableCol2: {
      width: '45%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    tableCol3: {
      width: '15%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    tableCell: {
      margin: 'auto',
      marginTop: 5,
      fontSize: 12,
    },
    page: {
      fontFamily: 'Roboto',
      padding: 20,
      fontSize: 12,
    },
    tableHeader: {
      color: 'white',
      backgroundColor: 'black',
      fontWeight: 'bold',
      margin: 'auto',
      flexDirection: 'row',
    },
    header: {
      flexDirection: 'column',
      marginBottom: 20,
    },
    logo: {
      width: '50%',
      alignContent: 'center',
      marginBottom: 20,
    },
    contactInfo: {
      width: '100%',
    },
    companyName: {
      fontSize: 12,
      fontWeight: 'bold',
      fontFamily: 'Roboto-bold',
    },
    address: {
      fontSize: 12,
    },
    phone: {
      fontSize: 12,
    },
    content: {
      marginTop: 20,
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    total: {
      textAlign: 'right',
      marginTop: 20,
    },
    date: {
      textAlign: 'right',
    },
  });

  return (
    <Document language="hu" keywords="lokuki">
      <Page style={styles.page}>
        <View style={styles.header} fixed>
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              marginBottom: '20px ',
            }}
          >
            <Image src="letis_logoNew.png" style={{ width: '50%' }} />
          </View>
          <View style={{ width: '100%' }}>
            <Text style={{ textAlign: 'center' }}>
              Zárbolt & Kulcsmásoló - Letis Magyarország Kft.
            </Text>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%' }}>
                <Text>0-24 ZÁRSZERVIZ:</Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text>06-30-929-7006</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>Székhely/Postacím:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>1037 Budapest, Erdőalja út 46.</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%' }}>
                <Text>ZÁRBOLT & KULCSMÁSOLÓ:</Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text>06-30-990-8102</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>Bemutatóterem/Üzlet:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>1149 Budapest, Várna utca 5/b.</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%' }}>
                <Text>TŰZVÉDELEM/LAKATOSMŰHELY:</Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text>06-70-378-5829</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>Adószám:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>24151131-2-41</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%', borderBottom: '1px solid black' }}>
                <Text>E-mail:</Text>
              </View>
              <View style={{ width: '20%', borderBottom: '1px solid black' }}>
                <Text>iroda@zarszerviz.hu</Text>
              </View>
              <View style={{ width: '25%', borderBottom: '1px solid black' }}>
                <Text>Cégjegyzék szám:</Text>
              </View>
              <View style={{ width: '25%', borderBottom: '1px solid black' }}>
                <Text>01-09-992862</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.title}>Nyugta</Text>
        <View style={{ flexDirection: 'row', margin: '20px' }}>
          <View style={{ width: '50%', textAlign: 'left' }}>
            <Text style={styles.companyName}>Vevő:</Text>
            <Text style={styles.address}>
              {client?.isPrivatePerson
                ? client?.name?.fullName
                : client?.companyName}
            </Text>
            <Text style={styles.address}>
              {client?.addresses.find((a) => a.id === addressId)?.fullAddress ??
                ''}
            </Text>
            <Text>{client?.contact?.phone}</Text>
            <Text>{client?.contact?.email}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}> Termék megnevezése</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCell}>Darabszám</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}> Ár&nbsp;(nettó)</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Ár&nbsp;(bruttó)</Text>
            </View>
          </View>
          {order?.items?.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {item.itemAlternateName
                      ? item.itemAlternateName
                      : item.itemName}
                  </Text>
                  <Text style={styles.tableCell}>{item.comment}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{item.amount}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.sellPriceNet.toFixed(0))}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.sellPriceGross.toFixed(0))}
                  </Text>
                </View>
              </View>
            );
          })}
          {order?.services?.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                  <Text style={styles.tableCell}>{item.comment}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>
                    {item.quantity > 0 ? item.quantity : item.amount} (
                    {item.unitOfQuantity})
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.servicePriceNet.toFixed(0))}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.servicePriceGross.toFixed(0))}
                  </Text>
                </View>
              </View>
            );
          })}
          {order?.itemPackages?.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                  <Text style={styles.tableCell}>{item.comment}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{item.amount}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.sellPriceNet.toFixed(0))}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.sellPriceGross.toFixed(0))}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        <View
          style={{
            marginTop: '20px',
            position: 'absolute',
            bottom: 80,
            right: 10,
          }}
        >
          {order?.discount > 0 && (
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ width: '50%' }}>
                <Text style={{ textAlign: 'left', fontSize: 12 }}>
                  Engedmény a végösszegből:
                </Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={{ textAlign: 'right', fontSize: 12 }}>
                  {order?.discount}%
                </Text>
              </View>
            </View>
          )}
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetési mód:
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {order?.paymentType}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetendő(Nettó):
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {formatCurrency(calculatefinalPrice(false).toFixed(0))}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetendő(ÁFA):
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {formatCurrency(
                  (
                    calculatefinalPrice(true) - calculatefinalPrice(false)
                  ).toFixed(0)
                )}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetendő(Bruttó):
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {formatCurrency(calculatefinalPrice(true).toFixed(0))}{' '}
              </Text>
            </View>
          </View>
        </View>{' '}
        <View
          style={{
            width: '100%',
            position: 'absolute',
            bottom: '20px',
            right: 10,
          }}
        >
          <Text>Számla megtekintése:</Text>
          <Link src={order.invoiceUrl}>{order.invoiceUrl}</Link>
        </View>
      </Page>
    </Document>
  );
};
