import { Close } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  Paper,
} from '@mui/material';
import DataList from '../../../components/DataList';
import acquisitionService from '../../../services/acquisitions/acquisitionService';
import { formatCurrency } from '../../../utils/valueFormatters';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

export default function AcquisitionHistoryDialog(props: any) {
  const {
    openDialog,
    setOpenDialog,
    getAcquisitionHistory,
    loadData,
    hideIgnore = false,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  return (
    <Dialog
      open={openDialog.open}
      onClose={() =>
        setOpenDialog({
          open: false,
          row: { acquisitionHistory: {}, itemId: 0 },
        })
      }
      fullWidth
    >
      <DialogTitle sx={{ m: 1, p: 2 }}>
        <p style={{ fontWeight: 'bold', margin: '0' }}>Beszerzési árak</p>
        <IconButton
          aria-label="close"
          onClick={() =>
            setOpenDialog({
              open: false,
              row: { acquisitionHistory: {}, itemId: 0 },
            })
          }
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Grid item>
        <Box>
          <Paper
            style={{
              width: '100%',
              padding: '10px',
            }}
          >
            <Box>
              <DataList
                rows={getAcquisitionHistory()}
                localStorageKey={'AcquisitionPricePageHistory'}
                minimal
                columns={[
                  {
                    field: 'clientName',
                    headerName: 'Beszállító',
                    flex: 200,
                  },
                  {
                    field: 'acquisitionDate',
                    headerName: 'Beszerzés ideje',
                    valueGetter: (params) =>
                      new Date(params.row.acquisitionDate).toLocaleDateString(
                        'hu-HU'
                      ),
                    flex: 200,
                  },
                  {
                    field: 'unitPrice',
                    headerName: 'Beszerzési ár',
                    valueGetter: (params) =>
                      formatCurrency(params.row.unitPrice),
                    flex: 200,
                  },
                  {
                    field: 'ignored',
                    headerName: 'Figyelmen kívül hagyva',
                    flex: 300,
                    renderCell: (params) => (
                      <Checkbox
                        checked={params.row.ignored}
                        onChange={(e) => {
                          dispatch({ type: 'SHOW_SAVE' });
                          acquisitionService
                            .ignoreItemAcquisition({
                              ignored: e.target.checked,
                              itemAcquisitionId: params.row.itemAcquisitionId,
                            })
                            .then((response) => {
                              if (response.canceled) return;
                              if (response.hasError) {
                                enqueueSnackbar(
                                  response.errorMessages.join(','),
                                  {
                                    variant: 'error',
                                  }
                                );
                              } else {
                                enqueueSnackbar('Sikeres mentés!', {
                                  variant: 'success',
                                });
                                loadData();
                              }
                            })
                            .finally(() => {
                              dispatch({ type: 'HIDE' });
                            });
                        }}
                      />
                    ),
                  },
                ].filter((x) => (hideIgnore ? x.field !== 'ignored' : true))}
                getRowId={(e) => e.itemAcquisitionId}
              />{' '}
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Dialog>
  );
}
