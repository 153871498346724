import CircleIcon from '@mui/icons-material/Circle';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Checkbox, Grid, Paper, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export const InvoiceTypePickerCard = (props) => {
  const { icon, text, isPicked, onSelect } = props;

  return (
    <Paper
      sx={{
        border: `1px solid ${isPicked ? 'blue' : 'lightgrey'}`,
        borderRadius: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <ListItem>
        <ListItemText
          onClick={() => onSelect()}
          sx={{ color: 'black', userSelect: 'none' }}
        >
          <Grid container p={2}>
            <Grid container justifyContent="space-between">
              <Grid item>{icon}</Grid>
              <Grid item>
                <Checkbox
                  checked={isPicked}
                  icon={<PanoramaFishEyeIcon />}
                  checkedIcon={<CircleIcon style={{ color: 'blue' }} />}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize={'17px'} fontWeight={'bold'}>
                {text}
              </Typography>
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>
    </Paper>
  );
};
