import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import Divider from '@mui/material/Divider';
import { useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { formatCurrency } from '../../../utils/valueFormatters';
import clientService from '../../../services/crm/clientService';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { useDispatch, useSelector } from 'react-redux';
import { reset, setForm } from '../../../stateManagement/actions/offerActions';
import { useNavigate } from 'react-router-dom';
import SelectClient from '../../crm/clients/SelectClient';
import SelectItems from '../../wms/items/components/SelectItems';
import SelectServices from '../services/SelectServices';
import SelectItemPackages from '../../wms/items/components/SelectItemPackages';
import { usePDF, PDFViewer } from '@react-pdf/renderer';
import { OfferPDF } from './OfferPDF';

const OfferForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    readonly = false,
    priceCategories,
    listPrices,
    onNewItemAdded,
    services,
    setPriceCategoryId,
    clients,
    loadItemPrices,
    handleItemDelete,
    setClients,
    taxes,
    isWorkItem,
    documentBlocks,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [addresses, setAddresses] = useState<any[]>([]);
  const { checkPermission } = useCheckPermission();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.userInfo);

  const [representative, setRepresentative] = useState<any>({
    clientId: 0,
    representativeName: { firstName: '', lastName: '' },
    representativePhone: '',
  });

  const {
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    errors: validationErrors,
  } = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {};
      if (!values.offerNumber && !(values.documentBlockId > 0)) {
        errors.offerNumber = 'Kötelező mező';
      }
      if (!values.expireAt) {
        errors.expireAt = 'Kötelező mező';
      }
      if (!values.clientId) {
        errors.clientId = 'Kérem válasszon ügyfelet';
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      let value = instance.blob;

      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(',')[1];
        onSubmit(values, setSubmitting, representative, base64String);
      };
    },
  });

  const handleClientSelected = (clientId: number) => {
    if (clientId > 0) {
      clientService.get(clientId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setAddresses(response.result.addresses);
          setRepresentative({
            clientId: response.result.id,
            representativeName: response.result.representativeName,
            representativePhone: response.result.representativePhone,
          });
          setFieldValue(
            'clientName',
            response.result.isPrivatePerson
              ? response.result.name?.fullName
              : response.result.companyName
          );
          setFieldValue('clientPhone', response.result.contactPhone);
          setFieldValue('clientEmail', response.result.contactEmail);
        }
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setForm(values));
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [values]);

  useEffect(() => {
    if (entity.clientId > 0) {
      handleClientSelected(entity.clientId);
    }
  }, [entity.clientId]);

  const calculateFinalPrice = (
    selectedItems,
    selectedServices,
    itemPackages
  ) => {
    let totalPrice = 0;
    selectedItems?.forEach((item) => {
      const { sellPriceGross, amount, discount } = item;
      const itemPrice = sellPriceGross * amount * (1 - discount / 100);
      totalPrice += itemPrice;
    });

    selectedServices?.forEach((service) => {
      const { servicePriceGross, amount, discount } = service;
      const servicePrice = servicePriceGross * amount * (1 - discount / 100);
      totalPrice += servicePrice;
    });

    itemPackages?.forEach((itemPackage) => {
      const { sellPriceGross, amount, discount } = itemPackage;
      const itemPackagePrice = sellPriceGross * amount * (1 - discount / 100);
      totalPrice += itemPackagePrice;
    });

    return totalPrice;
  };

  useEffect(() => {
    let result = calculateFinalPrice(
      values?.selectedItems,
      values?.selectedServices,
      values?.itemPackages
    );
    setFieldValue('price', result);
  }, [values]);

  const calculateNetSum = useCallback(() => {
    let itemNetSum = 0;
    let serviceNetSum = 0;
    let itemPackagesNetSum = 0;

    if (values && values.selectedItems) {
      itemNetSum = values.selectedItems.reduce(
        (sum, item) => sum + item.sellPriceNet,
        0
      );
    }

    if (values && values.selectedServices) {
      serviceNetSum = values.selectedServices.reduce(
        (sum, service) => sum + service.servicePriceNet,
        0
      );
    }

    if (values && values.itemPackages) {
      itemPackagesNetSum = values.itemPackages.reduce(
        (sum, itemPackage) =>
          sum +
          itemPackage.sellPriceNet *
            (1 - itemPackage.discount / 100) *
            itemPackage.amount,
        0
      );
    }

    return itemNetSum + serviceNetSum + itemPackagesNetSum;
  }, [values]);

  const [instance, update] = usePDF({
    document: (
      <OfferPDF
        offer={values}
        user={user}
        createdAt={new Date(values?.createdAt).toLocaleDateString('hu-HU')}
        calculateNetSum={calculateNetSum}
      />
    ),
  });

  useEffect(() => {
    update(
      <OfferPDF
        offer={values}
        user={user}
        createdAt={new Date(values?.createdAt).toLocaleDateString('hu-HU')}
        calculateNetSum={calculateNetSum}
      />
    );
  }, [values]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Paper>
        <Grid container justifyContent="left" spacing={2} p={5}>
          <h2>Ajánlat {values.id > 0 ? 'szerkesztése' : 'létrehozása'}</h2>
          <Grid xs={12} item pt={5}>
            <Box color="red">
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2 }}>
              Időpontok
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="documentBlockId"
              disabled={readonly}
              value={values?.documentBlockId}
              onChange={(event, value) => {
                setFieldValue('documentBlockId', value);
              }}
              getOptionLabel={(option) =>
                documentBlocks?.find((g) => g.id === option)?.name ?? ''
              }
              options={documentBlocks?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    !!touched.documentBlockId &&
                    !!validationErrors.documentBlockId
                  }
                  helperText={
                    !!touched.documentBlockId &&
                    !!validationErrors.documentBlockId &&
                    (validationErrors.documentBlockId as string)
                  }
                  fullWidth
                  label="Bizonylattömb"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values?.offerNumber}
              onChange={(e) => setFieldValue('offerNumber', e.target.value)}
              label="Ajánlat száma"
              name="offerNumber"
              InputLabelProps={{ shrink: true }}
              disabled={readonly || values.documentBlockId > 0}
              required
              fullWidth
              multiline
              error={!!touched.offerNumber && !!validationErrors.offerNumber}
              helperText={
                !!touched.offerNumber &&
                !!validationErrors.offerNumber &&
                (validationErrors.offerNumber as string)
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DesktopDatePicker
              label="Lejárati dátum"
              value={values.expireAt}
              inputFormat="yyyy-MM-dd"
              autoFocus
              disabled={readonly}
              onChange={(value) => setFieldValue('expireAt', value)}
              renderInput={(params) => (
                <TextField
                  required
                  fullWidth
                  {...params}
                  error={!!touched.expireAt && !!validationErrors.expireAt}
                  helperText={
                    !!touched.expireAt &&
                    !!validationErrors.expireAt &&
                    (validationErrors.expireAt as string)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DesktopDateTimePicker
              label="Tervezett munkavégzés"
              value={values.planned}
              inputFormat="yyyy-MM-dd HH:mm"
              disabled={readonly}
              onChange={(value) => setFieldValue('planned', value)}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
              Ügyfél
            </Divider>
            <Box color="red">
              {validationErrors.clientId && (
                <li>{validationErrors.clientId as string}</li>
              )}
            </Box>
            <SelectClient
              values={values}
              setFieldValue={setFieldValue}
              readonly={readonly}
              clients={clients}
              isOffer={true}
              addresses={addresses}
              onWorkAddressSelected={(addressId) => {
                let foundAddress = addresses.find((x) => x.id === addressId);
                if (foundAddress) {
                  setFieldValue('workAddress', foundAddress.fullAddress);
                }
              }}
              setRepresentative={setRepresentative}
              representative={representative}
              onClientChange={handleClientSelected}
              onClientAdded={(client) => {
                if (clients.find((x) => x.id === client.id)) {
                  setClients((clients) =>
                    clients.map((x) => (x.id === client.id ? client : x))
                  );
                } else {
                  setClients((clients) => [...clients, client]);
                }
              }}
              onAddressAdded={(address) =>
                setAddresses((addresses) => [...addresses, address])
              }
              showContactInName={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
              Feladat
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values?.task}
              onChange={(e) => setFieldValue('task', e.target.value)}
              disabled={readonly}
              label={'Feladat'}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values?.taskDescription}
              onChange={(e) => setFieldValue('taskDescription', e.target.value)}
              disabled={readonly}
              multiline
              minRows={3}
              label={'Feladatleírás'}
              fullWidth
            ></TextField>
          </Grid>
          {!isWorkItem && (
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ mt: 2 }}>
                Árkategória
              </Divider>
              <Grid item xs={12} md={2} pb={2}>
                <Autocomplete
                  disablePortal
                  id="priceCategoryId"
                  disabled={readonly}
                  value={values?.priceCategoryId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('priceCategoryId', value);
                    setPriceCategoryId(value);
                  }}
                  getOptionLabel={(option) => {
                    let priceCategory = priceCategories.find(
                      (g) => g.id === option
                    );
                    return priceCategory?.name ?? '';
                  }}
                  options={priceCategories?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Árkategória" />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2 }}>
              Termékek
            </Divider>
            <SelectItems
              values={values}
              listPrices={listPrices}
              taxes={taxes}
              loadItemPrices={(a, b) => loadItemPrices(a, b, setFieldValue)}
              readOnly={readonly}
              setFieldValue={setFieldValue}
              isOffer={true}
              onNewItemAdded={onNewItemAdded}
              handleItemDelete={handleItemDelete}
              canOverrideGrossPrice
            />
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2 }}>
              Szolgáltatások
            </Divider>
            <SelectServices
              values={values}
              isOffer={true}
              taxes={taxes}
              readOnly={readonly}
              setFieldValue={setFieldValue}
              services={services}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2 }}>
              Termékcsomagok
            </Divider>
            <SelectItemPackages
              values={values}
              services={services}
              listPrices={listPrices}
              taxes={taxes}
              loadItemPrices={(a, b) => loadItemPrices(a, b, setFieldValue)}
              readOnly={readonly}
              setFieldValue={setFieldValue}
              isOffer={true}
              onNewItemAdded={onNewItemAdded}
              handleItemDelete={handleItemDelete}
              canOverrideGrossPrice
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2 }}>
              Végösszeg
            </Divider>
            <Typography variant="h5">
              {formatCurrency(
                calculateFinalPrice(
                  values?.selectedItems,
                  values?.selectedServices,
                  values?.itemPackages
                )
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2 }}>
              Feltételek
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values.conditions}
              onChange={(e) => setFieldValue('conditions', e.target.value)}
              label="Feltételek"
              name="conditions"
              InputLabelProps={{ shrink: true }}
              disabled={readonly}
              required
              fullWidth
              multiline
              error={!!touched.conditions && !!validationErrors.conditions}
              helperText={
                !!touched.conditions &&
                !!validationErrors.conditions &&
                (validationErrors.conditions as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2 }}>
              Határidő
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values.deadLine}
              onChange={(e) => setFieldValue('deadLine', e.target.value)}
              label="Határidő"
              name="deadLine"
              InputLabelProps={{ shrink: true }}
              disabled={readonly}
              required
              fullWidth
              multiline
              error={!!touched.deadLine && !!validationErrors.deadLine}
              helperText={
                !!touched.deadLine &&
                !!validationErrors.deadLine &&
                (validationErrors.deadLine as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2 }}>
              Garancia
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values.guarantee}
              onChange={(e) => setFieldValue('guarantee', e.target.value)}
              label="Garancia"
              name="guarantee"
              InputLabelProps={{ shrink: true }}
              disabled={readonly}
              required
              fullWidth
              multiline
              error={!!touched.guarantee && !!validationErrors.guarantee}
              helperText={
                !!touched.guarantee &&
                !!validationErrors.guarantee &&
                (validationErrors.guarantee as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mt: 2 }}>
              Megjegyzés
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values.remark}
              onChange={handleChange}
              label="Megjegyzés"
              name="remark"
              multiline
              minRows={4}
              InputLabelProps={{ shrink: true }}
              disabled={readonly}
              fullWidth
              error={!!touched.remark && !!validationErrors.remark}
              helperText={
                !!touched.remark &&
                !!validationErrors.remark &&
                (validationErrors.remark as string)
              }
            />
          </Grid>
          <Grid container item pt={3} justifyContent="left">
            {!readonly && (
              <Grid item p={1}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Mentés
                </Button>
              </Grid>
            )}
            <Grid item p={1}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  navigate('/erp/offers');
                  dispatch(reset());
                }}
              >
                Mégse
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default OfferForm;
