import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import OrderView from '../pages/sales/orders/OrderView';

export default function OrderPDFDialog(props: any) {
  const { orderId, open, onClose, values = null } = props;

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth={'lg'}>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <OrderView orderId={orderId} values={values} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={() => onClose()}>
          Kilépés
        </Button>
      </DialogActions>
    </Dialog>
  );
}
