import { formatCurrency } from '../../../utils/valueFormatters';

import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
});
Font.register({
  family: 'Roboto-bold',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
});

export const WorkItemPDF = (props) => {
  const {
    workItem,
    client,
    taskTypes,
    createdAt,
    calculatefinalPrice,
    mechanicName,
  } = props;

  const styles = StyleSheet.create({
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableCol: {
      width: '20%',
      borderStyle: 'solid',
      borderWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 1,
    },
    tableCell: {
      margin: 'auto',
      marginTop: 5,
      fontSize: 12,
    },
    page: {
      fontFamily: 'Roboto',
      padding: 20,
      fontSize: 12,
    },
    tableHeader: {
      color: 'white',
      backgroundColor: 'black',
      fontWeight: 'bold',
      margin: 'auto',
      flexDirection: 'row',
    },
    header: {
      flexDirection: 'column',
      marginBottom: 20,
    },
    logo: {
      width: '50%',
      alignContent: 'center',
      marginBottom: 20,
    },
    contactInfo: {
      width: '100%',
    },
    companyName: {
      fontSize: 12,
      fontWeight: 'bold',
      fontFamily: 'Roboto-bold',
    },
    address: {
      fontSize: 12,
    },
    phone: {
      fontSize: 12,
    },
    content: {
      marginTop: 20,
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    total: {
      textAlign: 'right',
      marginTop: 20,
    },
    date: {
      textAlign: 'right',
    },
  });

  return (
    <Document language="hu">
      <Page style={styles.page}>
        <View style={styles.header} fixed>
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              marginBottom: '20px ',
            }}
          >
            <Image src="letis_logoNew.png" style={{ width: '50%' }} />
          </View>
          <View style={{ width: '100%' }}>
            <Text style={{ textAlign: 'center' }}>
              Zárbolt & Kulcsmásoló - Letis Magyarország Kft.
            </Text>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%' }}>
                <Text>0-24 ZÁRSZERVIZ:</Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text>06-30-929-7006</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>Székhely/Postacím:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>1037 Budapest, Erdőalja út 46.</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%' }}>
                <Text>ZÁRBOLT & KULCSMÁSOLÓ:</Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text>06-30-990-8102</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>Bemutatóterem/Üzlet:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>1149 Budapest, Várna utca 5/b.</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%' }}>
                <Text>TŰZVÉDELEM/LAKATOSMŰHELY:</Text>
              </View>
              <View style={{ width: '20%' }}>
                <Text>06-70-378-5829</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>Adószám:</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text>24151131-2-41</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', fontSize: 10 }}>
              <View style={{ width: '30%', borderBottom: '1px solid black' }}>
                <Text>E-mail:</Text>
              </View>
              <View style={{ width: '20%', borderBottom: '1px solid black' }}>
                <Text>iroda@zarszerviz.hu</Text>
              </View>
              <View style={{ width: '25%', borderBottom: '1px solid black' }}>
                <Text>Cégjegyzék szám:</Text>
              </View>
              <View style={{ width: '25%', borderBottom: '1px solid black' }}>
                <Text>01-09-992862</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.title}>Munkalap({workItem?.workItemNumber})</Text>
        <View style={{ flexDirection: 'row', margin: '20px' }}>
          <View style={{ width: '50%', textAlign: 'left' }}>
            <Text style={styles.companyName}>Vevő:</Text>
            <Text style={styles.address}>
              {client?.isPrivatePerson
                ? client?.name?.fullName
                : client?.companyName}
            </Text>
            <Text style={styles.phone}>
              {client?.addresses.find((a) => a.id === workItem.workAddressId)
                ?.fullAddress ?? ''}
            </Text>
            <Text>{client?.contact?.phone}</Text>
            <Text>{client?.contact?.email}</Text>
          </View>
          <View style={{ width: '50%', textAlign: 'right' }}>
            <Text style={styles.companyName}>Munkatárs:</Text>
            <Text style={styles.companyName}>{mechanicName ?? ''}</Text>
          </View>
        </View>
        <Text
          style={{ textAlign: 'center', width: '100%', marginBottom: '20px' }}
        >
          {taskTypes?.find((g) => g.id === workItem?.taskTypeId)?.name ?? ''}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}> Termék megnevezése</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Darabszám</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell}> Ár&nbsp;(nettó)</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Ár&nbsp;(bruttó)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Összesen&nbsp;(bruttó)</Text>
            </View>
          </View>
          {workItem?.selectedItems?.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item.itemAlternateName !== '' &&
                    item.itemAlternateName !== null
                      ? item.itemAlternateName
                      : item.itemName}
                  </Text>
                  <Text style={styles.tableCell}>{item.comment}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.amount}</Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.sellPriceNet.toFixed(0))}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.sellPriceGross.toFixed(0))}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (item.sellPriceGross * item.amount).toFixed(0)
                    )}{' '}
                  </Text>
                </View>
              </View>
            );
          })}
          {workItem?.selectedServices?.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                  <Text style={styles.tableCell}>{item.comment}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.amount}</Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.servicePriceNet.toFixed(0))}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.servicePriceGross.toFixed(0))}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (item.servicePriceGross * item.amount).toFixed(0)
                    )}{' '}
                  </Text>
                </View>
              </View>
            );
          })}
          {workItem?.itemPackages?.map((item, index) => {
            return (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                  <Text style={styles.tableCell}>{item.comment}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.amount}</Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.sellPriceNet.toFixed(0))}
                  </Text>
                </View>

                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(item.sellPriceGross.toFixed(0))}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatCurrency(
                      (item.sellPriceGross * item.amount).toFixed(0)
                    )}{' '}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
        <View
          style={{ flexDirection: 'row', width: '100%', marginTop: '20px' }}
        >
          <View style={{ width: '20%' }}>
            <Text style={{ textAlign: 'left' }}>Megjegyzés:</Text>
          </View>
          <View style={{ width: '80%' }}>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              {workItem?.comment}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: '20px',
            position: 'absolute',
            bottom: 30,
            right: 10,
          }}
        >
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetendő(Nettó):
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {formatCurrency(calculatefinalPrice(false).toFixed(0))}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetendő(ÁFA):
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {formatCurrency(
                  (
                    calculatefinalPrice(true) - calculatefinalPrice(false)
                  ).toFixed(0)
                )}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Fizetendő(Bruttó):
              </Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ textAlign: 'right', fontSize: 12 }}>
                {formatCurrency(calculatefinalPrice(true).toFixed(0))}{' '}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={{ width: '100%', marginTop: '20px' }}>
          <View style={{ width: '100%', fontFamily: 'Roboto-bold' }}>
            <Text style={{ textAlign: 'left' }}>TÁJÉKOZTATÁS:</Text>
          </View>
          <View style={{ width: '100%' }}>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              Jelen jegyzőkönyv egy egyedi vállalatirányítási rendszer része,
              amely több funkciót is elláthat: munkalap, felmérés,
              egyszerűsített szerződés, számlarészletező, teljesítés igazolás,
              jótállási jegy… A jegyzőkönyv egyaránt képviseli Ügyfeleink,
              Társaságaink és Munkatársaink érdekeit is és hozzásegít bennünket
              szolgáltatásaink színvonalának növeléséhez.
            </Text>
          </View>
        </View>
        <View style={{ width: '100%', marginTop: '20px' }}>
          <View style={{ width: '100%', fontFamily: 'Roboto-bold' }}>
            <Text style={{ textAlign: 'left' }}>
              JÓTÁLLÁSI JEGY ÉS KARBANTARTÁSI UTASÍTÁS
            </Text>
          </View>
          <View style={{ width: '100%' }}>
            <Text
              style={{ textAlign: 'left', fontSize: 12, marginBottom: '10px' }}
            >
              Kedves Vásárlónk!
            </Text>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              1. A jótállási idő a Vevő részére történő átadás napjától, a
              hatályos rendelkezések szerinti időtartamra szól.
            </Text>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              2. A jótállási igény kizárólag a jótállási jeggyel érvényesíthető.
            </Text>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              3. A jótállási időn belüli meghibásodás esetén a Vevő kötelessége
              a meghibásodást azonnal bejelenteni (az esetleges konfliktusok
              elkerülése végett írásban) és - amennyiben megoldható - a terméket
              haladéktalanul használaton kívül helyezni. A termék meghibásodás
              utáni használata a jótállás teljes elvesztését vonhatja maga után.
            </Text>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              4. A jótállás kizárólag a gyártás, ill. szerelési hibákból történő
              esetekre terjed ki. Semmi esetben nem áll fenn jótállási
              kötelezettségünk a következő esetekben: karbantartási
              hiányosságból (szükség esetén - tapasztalat szerint évente - a
              zsanérok, zárnyelvek, lamellák pl. GREENTEQ SPRAY-vel kenhetők;
              zárbetétek kizárólag grafitporral kenhetők), nem rendeltetésszerű
              használatból (pl. a zárbetét olajozása, erőszakos behatolás
              kísérlete, külső behatás miatti deformáció…), helytelen
              telepítésből (amennyiben nem cégünk által kijelölt szakember
              végezte), stb. eredő hiba.
            </Text>
            <Text style={{ textAlign: 'left', fontSize: 12 }}>
              5. Ha a hiba jellegének tekintetében (pl. a termék
              kijavíthatósága, javítás módja, stb.) vita merül fel, úgy a
              jogszabályokban felhatalmazott minőségellenőrző intézet vizsgálata
              és szakvéleménye kérhető. Az Eladó ls Vevő között esetlegesen
              felmerülő vitás kérdések tisztázásában a PTK. idevágó
              rendelkezései a mérvadóak.
            </Text>
            <Text
              style={{ textAlign: 'left', fontSize: 12, marginTop: '10px' }}
            >
              Jótállási és szavatossági idők a szolgáltatásokra és átadott
              termékekre: Hatályos rendelkezések szerint.
            </Text>
          </View>
          <View style={{ width: '100%', marginTop: '20px' }}>
            <View style={{ width: '100%', fontFamily: 'Roboto-bold' }}>
              <Text style={{ textAlign: 'left' }}>
                EGYSZERŰSÍTETT SZERZŐDÉS
              </Text>
            </View>
            <View style={{ width: '100%' }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontSize: 12,
                  marginBottom: '10px',
                }}
              >
                (ÁTUTALÁSOS teljesítéshez és/vagy SZERZŐDÉSKÖTELES munkákhoz)
              </Text>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Megrendelő (nem természetes személy esetén képviselője -
                továbbiakban Megrendelő) kijelenti, hogy a munkák megrendelésére
                jogosult. Kijelenti továbbá, hogy amennyiben e jogosultsága
                tekintetében Kivitelezőt (vagy képviselőjét - továbbiakban
                Kivitelező) megtévesztette, úgy az ebből eredő - Kivitelezőnél
                felmerülő - valamennyi kár megtérítésére köteles. Kivitelező
                készpénzfizetési -, vagy 8/15/30 napos teljesítési határidővel -
                átutalásos számlát jogosult kiállítani. Átutalás esetén a
                teljesítés napja az a nap, amelyiken Kivitelező bankszámláján a
                számla ellenértéke jóváírásra kerül.
              </Text>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Megrendelő kijelenti, hogy a feltüntetett termékek és munkálatok
                szükségességét és ellenértékét elismeri, megértette és a
                továbbiakban sem vitatja.
              </Text>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Megrendelő tudomásul veszi, hogy az átutalandó összeget-
                Kivitelezőnek bankszámláján történő - jóváírásáig a felhasznált
                anyagok kivitelező tulajdonát képzik, ill. Kivitelezőnek a
                munkaterületre szabad bejárása van.
              </Text>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Azon kérdések esetén, amelyeket jelen szerződés nem szabályoz, a
                Ptk. vonatkozó rendelkezéseit kell irányadónak tekinteni.
                Megrendelő elismeri, hogy a számla első példányát átvette és
                annak kezeléséért teljes felelősséggel tartozik.
              </Text>
              <Text style={{ textAlign: 'left', fontSize: 12 }}>
                Felek kijelenteik, hogy az általuk megadott adatok a valóságnak
                megfelelnek és kifejezetten kijelentik, hogy az ügyben
                eljárhatnak.
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            position: 'absolute',
            bottom: 30,
            right: 10,
          }}
          fixed
        >
          <View style={{ width: '100%' }}>
            <Text style={{ textAlign: 'right' }}>
              Budapest, {createdAt ?? ''}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
