import { AddressTypes } from '../types/AddressTypes';
import { AttachmentTypes } from '../types/AttachmentTypes';
import { ChimneyControlAppointmentStatuses } from '../types/ChimneyControlAppointmentStatuses';
import { ClientTaxType } from '../types/ClientTaxType';
import { ClientTypes } from '../types/ClientTypes';
import { DamageScale } from '../types/DamageScale';
import { DocumentBlockTypes } from '../types/DocumentBlockTypes';
import { EmailStatuses } from '../types/EmailStatuses';
import { Modules } from '../types/Modules';
import { ModuleSettingsType } from '../types/ModuleSettingsType';

export const translateRoleName = (name: string) => {
  switch (name) {
    case 'Admin':
      return 'Admin';
    case 'Operator':
      return 'Kezelő';
    case 'Assistant':
      return 'Bolti munkatárs';
    case 'ServiceEmployee':
      return 'Szerviz munkatárs';
    case 'Dispatcher':
      return 'Diszpécser';
    case 'Surveyor':
      return 'Felmérő';
    case 'OfferGiver':
      return 'Árajánlat adó';
    case 'ProcurementSpecialist':
      return 'Anyagbeszerző';
    case 'Warehouseman':
      return 'Raktáros';
    case 'Mechanic':
      return 'Szerelő';
    case 'Evaluator':
      return 'Kiértékelő';
    default:
      return name;
  }
};

export const translateGroupName = (name: string) => {
  switch (name) {
    case 'Administrators':
      return 'Adminisztrátorok';
    case 'Operators':
      return 'Kezelők';
    case 'Assistants':
      return 'Bolti munkatársak';
    case 'ServiceEmployees':
      return 'Szerviz munkatársak';
    case 'Dispatchers':
      return 'Diszpécserek';
    case 'Surveyors':
      return 'Felmérők';
    case 'OfferGivers':
      return 'Árajánlat adók';
    case 'ProcurementSpecialists':
      return 'Anyagbeszerzők';
    case 'Warehousemen':
      return 'Raktárosok';
    case 'Mechanics':
      return 'Szerelők';
    case 'Evaluators':
      return 'Kiértékelők';
    default:
      return name;
  }
};
export const translateAttachmentTypesName = (
  attachmentType: AttachmentTypes
): string => {
  switch (attachmentType) {
    case AttachmentTypes.ChimneySweepingCertificate:
      return 'Kéményseprői tanúsítvány';
    case AttachmentTypes.Decision:
      return 'Határozat';
    case AttachmentTypes.Contract:
      return 'Szerződés';
    case AttachmentTypes.WorkItem:
      return 'Munkalap';
    case AttachmentTypes.Order:
      return 'Megrendelés';
    case AttachmentTypes.Acquisition:
      return 'Beszerzés';
    case AttachmentTypes.Instrument:
      return 'Műszer';
    case AttachmentTypes.Project:
      return 'Projekt';
    case AttachmentTypes.Task:
      return 'Feladat';
    case AttachmentTypes.Expense:
      return 'Kiadás';
    case AttachmentTypes.Client:
      return 'Ügyfél';
    case AttachmentTypes.Vehicle:
      return 'Jármű';
    case AttachmentTypes.User:
      return 'Felhasználó';
    case AttachmentTypes.VehicleDamageReport:
      return 'Jármű kárbejelentő';
    case AttachmentTypes.Report:
      return 'Bejelentés';
    case AttachmentTypes.VehicleSerivce:
      return 'Jármű szerviz';
    case AttachmentTypes.Offer:
      return 'Árajánlat';
    default:
      return 'Nem definiált típus';
  }
};

export const translateDamageScaleName = (
  attachmentType: DamageScale
): string => {
  switch (attachmentType) {
    case DamageScale.Small:
      return 'Enyhe';
    case DamageScale.MediumSmall:
      return 'Közepesen enyhe';
    case DamageScale.Medium:
      return 'Közepes';
    case DamageScale.MediumLarge:
      return 'Közepesen súlyos';
    case DamageScale.Large:
      return 'Súlyos';
    case DamageScale.Busted:
      return 'Totálkár';
    default:
      return 'Nem definiált típus';
  }
};
export const translateChimneyControlAppointmentStatusesName = (
  ChimneyControlAppointmentStatus: ChimneyControlAppointmentStatuses
): string => {
  switch (ChimneyControlAppointmentStatus) {
    case ChimneyControlAppointmentStatuses.Completed:
      return 'Teljesítve';
    case ChimneyControlAppointmentStatuses.CompletedByOther:
      return 'Teljesítve más cég által';
    case ChimneyControlAppointmentStatuses.Failed:
      return 'Sikertelen';
    case ChimneyControlAppointmentStatuses.Planned:
      return 'Egyeztetett';
    default:
      return 'Nem definiált típus';
  }
};

export const translateDocumentBlockTypesName = (
  attachmentType: DocumentBlockTypes
): string => {
  switch (attachmentType) {
    case DocumentBlockTypes.Contract:
      return 'Szerződés';
    case DocumentBlockTypes.Decision:
      return 'Határozat';
    case DocumentBlockTypes.Invoice:
      return 'Számla';
    case DocumentBlockTypes.Offer:
      return 'Árajánlat';
    case DocumentBlockTypes.Project:
      return 'Projekt';
    case DocumentBlockTypes.Certificate_of_completion:
      return 'Teljesítés igazolás';
    case DocumentBlockTypes.Dossier:
      return 'Dosszié';
    case DocumentBlockTypes.Order:
      return 'Megrendelés';
    case DocumentBlockTypes.Receipt:
      return 'Nyugta';
    case DocumentBlockTypes.Waybill:
      return 'Fuvarlevél';
    default:
      return 'Nem definiált típus';
  }
};
export const translateEmailStatusName = (
  attachmentType: EmailStatuses
): string => {
  switch (attachmentType) {
    case EmailStatuses.Sent:
      return 'Elküldve';
    case EmailStatuses.Failed:
      return 'Sikertelen';
    case EmailStatuses.Delivered:
      return 'Kézbesítve';
    case EmailStatuses.Bounced:
      return 'Visszapattant';
    case EmailStatuses.Expanded:
      return 'Kiterjesztett';
    case EmailStatuses.FilteredSpam:
      return 'Spam';
    case EmailStatuses.Opened:
      return 'Megnyitva';
    case EmailStatuses.Quarantined:
      return 'Karantén';
    case EmailStatuses.Suppressed:
      return 'Elfojtott';
    default:
      return 'Nem definiált típus';
  }
};
export const translateAddressTypeName = (
  attachmentType: AddressTypes
): string => {
  switch (attachmentType) {
    case AddressTypes.Site:
      return 'Telephely';
    case AddressTypes.BranchLocation:
      return 'Fiok hely';
    case AddressTypes.Headquarters:
      return 'Székhely';

    default:
      return 'Nincs';
  }
};
export const translateClientTaxTypeName = (
  attachmentType: ClientTaxType
): string => {
  switch (attachmentType) {
    case ClientTaxType.HasTaxNumber:
      return 'Adószámmal rendelkezik (Belföldi ÁFA alany)';
    case ClientTaxType.HasNoTaxNumber:
      return 'Adószámmal nem rendelkezik (Természetes személy)';
    case ClientTaxType.Foreign:
      return 'Külföldi, egyéb';

    default:
      return 'Nem definiált típus';
  }
};
export const translateClientTypeName = (
  attachmentType: ClientTypes
): string => {
  switch (attachmentType) {
    case ClientTypes.Customer:
      return 'Vásárló';
    case ClientTypes.Distributor:
      return 'Számlakibocsátó';
    case ClientTypes.Supplier:
      return 'Beszállító';

    default:
      return 'Nem definiált típus';
  }
};
export const translateModuleSettingType = (
  type: ModuleSettingsType
): string => {
  switch (type) {
    case ModuleSettingsType.ChimneySweepingCertificateInvoice:
      return 'Kéményseprői tanúsítvány számla';
    default:
      return 'Nem definiált típus';
  }
};
export const translateModules = (type: Modules): string => {
  switch (type) {
    case Modules.ChimneySweeping:
      return 'Kéményseprés';
    case Modules.Client:
      return 'Ügyfél';
    case Modules.Pricing:
      return 'Árazás';
    case Modules.Report:
      return 'Bejelentés';
    case Modules.Sales:
      return 'Eladás';
    case Modules.Statistics:
      return 'Statisztika';
    case Modules.SystemSettings:
      return 'Rendszer';
    case Modules.UserSettings:
      return 'Felhasználók';
    case Modules.Warehouse:
      return 'Raktárak';
    case Modules.Registration:
      return 'Nyílvántartások';
    case Modules.Acquisition:
      return 'Beszerzések';
    case Modules.Billing:
      return 'Számlázás';
    case Modules.Email:
      return 'Email';
    case Modules.Finance:
      return 'Pénzügyek';
    case Modules.FilesAndAttachments:
      return 'Fájlok és mellékletek';
    case Modules.HR:
      return 'HR';
    case Modules.ItemsAndServices:
      return 'Termékek és szolgáltatások';
    case Modules.Offer:
      return 'Árajánlatok';
    case Modules.Project:
      return 'Projektek';
    case Modules.Task:
      return 'Feladatok';
    case Modules.WorkItem:
      return 'Munkalapok';
    default:
      return 'Nem definiált típus';
  }
};
