import { Edit as EditIcon, Visibility } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import StarIcon from '@mui/icons-material/Star';
import { Badge, Grid, Paper, Rating, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import EntityNavigator from '../../../components/EntityNavigator';
import FilePageDialog from '../../../components/FilePageDialog';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useSignalREffect from '../../../hooks/useSignalREffect';
import workItemService from '../../../services/erp/workItemService';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import { EntityTypes } from '../../../types/EntityTypes';
import { formatRole } from '../../../utils/valueFormatters';
import { WorkItemStatus } from './WorkItemForm';
import WorkItemPapersDialog from './WorkItemPapersDialog';

const WorkItemsWithInvoiceAfterwards = (props: any) => {
  const { externalRows = null } = props;
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor(
    'WorkItemsPageInvoiceAfterwards.title'
  );
  const [workItemPapersDialog, setWorkItemPapersDialog] = useState<any>({
    open: false,
    workItem: null,
  });

  const [coloumnsToShow, setColoumnsToShow] = useState<string[]>([
    'createdAt',
    'workFlowDate',
    'workItemNumber',
    'clientName',
    'workAddress',
    'employeeName',
    'workItemStatus',
    'roleName',
    'statisticRating',
    'actions',
  ]);

  const connection = useSignalREffect('workItemHub');
  const handleWorkItemChanged = useCallback(
    (
      workitemNumber: string,
      oldUserName: string,
      newUserName: string,
      oldStatus: WorkItemStatus,
      newStatus: WorkItemStatus
    ) => {
      if (externalRows) {
        return;
      }
      if (checkPermission(['WorkItemViewInvoiceAfterwards'])) {
        workItemService.list(true).then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setRows(response.records);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        });
      }
    },
    []
  );

  useEffect(() => {
    if (!connection) return;

    var callback = handleWorkItemChanged;
    connection.on('WorkItemStatusChanged', callback);

    return () => {
      connection.off('WorkItemStatusChanged', callback);
    };
  }, [connection, handleWorkItemChanged]);

  const user = useSelector((state: any) => state.user.userInfo);
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (checkPermission(['WorkItemViewInvoiceAfterwards'])) {
      dispatch({ type: 'SHOW_QUERY' });
      workItemService
        .list(true)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setRows(response.records);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    let readonly =
      !checkPermission(['WorkItemEdit']) ||
      params.row.workItemStatus === WorkItemStatus.Closed ||
      params.row.workItemStatus === WorkItemStatus.Evaluated ||
      params.row.assignedToId !== user.userId;
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => {
          navigate(`/erp/workitems/edit/${params.row.id}/0/workItem`);
        }}
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Papirok">
            <ArticleIcon />
          </Tooltip>
        }
        label={'Papirok'}
        onClick={() =>
          setWorkItemPapersDialog({ open: true, workItem: params.row })
        }
      />
    );

    return actions;
  };

  const getPriorityText = (priority: number) => {
    switch (priority) {
      case 1:
        return 'Alacsony';
      case 2:
        return 'Közepes';
      case 3:
        return 'Magas';
      default:
        return '';
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Létrehozás dátuma',
      flex: 150,
      valueFormatter: (params) => {
        if (!params.value) return '';
        if (new Date(params.value).getFullYear() < 2000) {
          return 'Nincs';
        }
        const previousValue = parseJSON(params.value).toLocaleDateString();
        const date = new Date(parseJSON(params.value));
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${previousValue} ${hours}:${minutes
          .toString()
          .padStart(2, '0')}`;
        return formattedTime;
      },
    },
    {
      field: 'workFlowDate',
      headerName: 'Tervezett Dátum',
      flex: 150,
      valueFormatter: (params) => {
        if (!params.value) return '';
        if (new Date(params.value).getFullYear() < 2000) {
          return 'Nincs';
        }
        const previousValue = parseJSON(params.value).toLocaleDateString();
        const date = new Date(parseJSON(params.value));
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${previousValue} ${hours}:${minutes
          .toString()
          .padStart(2, '0')}`;
        return formattedTime;
      },
    },
    { field: 'workItemNumber', headerName: 'Sorszám', flex: 100 },
    {
      field: 'clientNameForQuickSearch',
      headerName: 'ÜgyfélKereső',
      flex: 100,
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'clientName',
      headerName: 'Ügyfél',
      flex: 100,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
            disableLink={externalRows}
          />
        );
      },
    },
    { field: 'employeeName', headerName: 'Dolgozó', flex: 100 },
    { field: 'workAddress', headerName: 'Cím', flex: 100 },
    {
      field: 'workItemStatus',
      headerName: 'Munkalap státusza',
      flex: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.value === WorkItemStatus.Draft ? (
              <span style={{ color: 'orange' }}>Tervezés alatt</span>
            ) : params.value === WorkItemStatus.New ? (
              <span style={{ color: '#A0A0A0' }}>Nincs hozzárendelve</span>
            ) : params.value === WorkItemStatus.Assigned ? (
              <span style={{ color: '#82C0FF' }}>Tervezett</span>
            ) : params.value === WorkItemStatus.Completed ? (
              <span style={{ color: 'lightgreen' }}>Teljesített</span>
            ) : params.value === WorkItemStatus.Suspended ? (
              <span style={{ color: '#FFCC00' }}>Felfüggesztett</span>
            ) : params.value === WorkItemStatus.Closed ? (
              <span style={{ color: 'green' }}>Lezárt</span>
            ) : params.value === WorkItemStatus.Evaluated ? (
              <span style={{ color: '#90EE90' }}>Kiértékelt</span>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'roleName',
      headerName: 'Munkafolyamat státusza',
      flex: 130,
      valueFormatter: (params) => {
        return formatRole(params.value);
      },
    },
    // {
    //   field: "priority",
    //   headerName: "Prioritás",
    //   flex: 100,
    //   valueFormatter: (params) => getPriorityText(params.value),
    // },
    {
      field: 'statisticRating',
      headerName: 'Értékelés',
      flex: 150,
      renderCell: (params) => (
        <div>
          <Rating
            name="hover-feedback"
            value={params.value}
            readOnly={true}
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ].filter((x) => coloumnsToShow.some((y) => y === x.field));

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Utólag számlázandó munkalapok {titleDescriptor}</h2>
        </Grid>
        {checkPermission(['WorkItemView']) && (
          <Grid item xs={12}>
            <DataList
              rows={rows}
              columns={columns}
              localStorageKey={'WorkItemsPage'}
            />
          </Grid>
        )}
        <ConfirmDeleteDialog />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.WorkItem}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'WorkItemsPage'}
        />
      </Grid>
      <WorkItemPapersDialog
        open={workItemPapersDialog.open}
        setOpen={setWorkItemPapersDialog}
        workItem={workItemPapersDialog.workItem}
      />
    </Paper>
  );
};

export default WorkItemsWithInvoiceAfterwards;
