import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Tooltip } from '@mui/material';

const CalendarWithNumberIcon = (props: any) => {
  const { number = 0, tooltipLabel = '' } = props;

  return (
    <>
      <div>
        <Tooltip title={tooltipLabel}>
          <CalendarTodayIcon fontSize="large" />
        </Tooltip>
      </div>
      <div style={{ position: 'fixed' }}>
        <span style={{ fontSize: '14px' }}>{number > 99 ? '99+' : number}</span>
      </div>
    </>
  );
};

export default CalendarWithNumberIcon;
