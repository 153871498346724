import {
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import {
  translateAddressTypeName,
  translateDocumentBlockTypesName,
} from '../../../utils/nameFormatters';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';
import { AddressTypes } from '../../../types/AddressTypes';

const AddressForm = (props: any) => {
  const {
    address,
    onAddressChange,
    errors,
    touched,
    readonly = false,
    hasBillingAddress = false,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          value={address?.type}
          onChange={(event, newValue) => {
            onAddressChange({
              ...address,
              type: newValue,
            });
          }}
          options={Object.values(AddressTypes).filter((x) =>
            Number.isFinite(x)
          )}
          getOptionLabel={(option) => {
            return translateAddressTypeName(option);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Típus"
              name="type"
              disabled={readonly}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item>
        <TextField
          value={address?.country}
          onChange={(e) =>
            onAddressChange({ ...address, country: e.target.value })
          }
          label="Ország"
          name="country"
          required
          disabled={readonly}
          error={!!touched?.country && !!errors?.country}
          helperText={
            !!touched?.country && !!errors?.country && errors?.country
          }
        />
      </Grid>
      <Grid item>
        <TextField
          value={address?.postalCode ?? ''}
          onChange={(e) =>
            onAddressChange({ ...address, postalCode: e.target.value })
          }
          label="Irányítószám"
          name="postalCode"
          required
          disabled={readonly}
          error={!!touched?.postalCode && !!errors?.address?.postalCode}
          helperText={
            !!touched?.postalCode && !!errors?.postalCode && errors?.postalCode
          }
        />
      </Grid>
      <Grid item>
        <TextField
          value={address?.city}
          onChange={(e) =>
            onAddressChange({ ...address, city: e.target.value })
          }
          label="Város"
          name="city"
          required
          disabled={readonly}
          error={!!touched?.city && !!errors?.city}
          helperText={!!touched?.city && !!errors?.city && errors?.city}
        />
      </Grid>
      <Grid item>
        <TextField
          value={address?.street}
          disabled={readonly}
          required
          onChange={(e) =>
            onAddressChange({ ...address, street: e.target.value })
          }
          label="Utca"
          name="street"
          error={!!touched?.street && !!errors?.street}
          helperText={!!touched?.street && !!errors?.street && errors?.street}
        />
      </Grid>
      <Grid item>
        <TextField
          value={address?.houseNumber ?? ''}
          disabled={readonly}
          onChange={(e) =>
            onAddressChange({ ...address, houseNumber: e.target.value })
          }
          label="Házszám"
          name="houseNumber"
          error={!!touched?.houseNumber && !!errors?.houseNumber}
          helperText={
            !!touched?.houseNumber &&
            !!errors?.houseNumber &&
            errors?.houseNumber
          }
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Radio
              checked={address?.isBillingAddress}
              name="isBillingAddress"
              disabled={readonly || hasBillingAddress}
              onChange={(e) =>
                onAddressChange({
                  ...address,
                  isBillingAddress: e.target.checked,
                })
              }
              color="primary"
            />
          }
          label="Megjelölés számlázási címként"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={address?.comment ?? ''}
          multiline
          rows={2}
          disabled={readonly}
          fullWidth
          onChange={(e) =>
            onAddressChange({ ...address, comment: e.target.value })
          }
          label="Megjegyzés a címhez"
          name="comment"
          error={!!touched?.comment && !!errors?.comment}
          helperText={
            !!touched?.comment && !!errors?.comment && errors?.comment
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox />}
          onChange={(event, checked) =>
            onAddressChange({ ...address, hasPerson: checked })
          }
          label="Kapcsolattartó"
          value={address?.hasPerson}
          checked={address?.hasPerson}
        />
      </Grid>
      {address?.hasPerson && (
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Divider textAlign="left">Kapcsolattartó adatai</Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="lastName"
              disabled={readonly}
              value={address?.personName?.lastName}
              onChange={(e) =>
                onAddressChange({
                  ...address,
                  personName: {
                    ...address.personName,
                    lastName: e.target.value,
                  },
                })
              }
              label="Vezetéknév"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="firstName"
              disabled={readonly}
              value={address?.personName?.firstName}
              onChange={(e) =>
                onAddressChange({
                  ...address,
                  personName: {
                    ...address.personName,
                    firstName: e.target.value,
                  },
                })
              }
              label="Keresztnév"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={address?.personPhone}
              disabled={readonly}
              name="representativePhone"
              onChange={(e) =>
                onAddressChange({
                  ...address,
                  personPhone: e.target.value,
                })
              }
              label="Telefonszám"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={address?.personEmail}
              disabled={readonly}
              name="representativePhone"
              onChange={(e) =>
                onAddressChange({
                  ...address,
                  personEmail: e.target.value,
                })
              }
              label="Email cím"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AddressForm;
