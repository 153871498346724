import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cashLogService from '../../../services/billing/cashLogService';
import CashLogBankNoteComponent from './CashLogBankNoteComponent';
import { formatCurrency } from '../../../utils/valueFormatters';

export default function CashLogVerifyDialog(props: any) {
  const { entity, open, onClose, onVerified } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [finalPriceGross, setFinalPriceGross] = React.useState<number>(0);
  const [result, setResult] = React.useState<number>(0);
  const [bankNoteCount, setBankNoteCount] = React.useState<any[]>([
    { bankNote: 20000, amount: 0 },
    { bankNote: 10000, amount: 0 },
    { bankNote: 5000, amount: 0 },
    { bankNote: 2000, amount: 0 },
    { bankNote: 1000, amount: 0 },
    { bankNote: 500, amount: 0 },
    { bankNote: 200, amount: 0 },
    { bankNote: 100, amount: 0 },
    { bankNote: 50, amount: 0 },
    { bankNote: 20, amount: 0 },
    { bankNote: 10, amount: 0 },
    { bankNote: 5, amount: 0 },
  ]);

  useEffect(() => {
    setResult(finalPriceGross - entity?.closingCashGross?.toFixed(0));
  }, [finalPriceGross, entity?.closingCashGross]);

  useEffect(() => {
    setFinalPriceGross(
      bankNoteCount.reduce((acc, item) => acc + item.bankNote * item.amount, 0)
    );
  }, [bankNoteCount]);

  const onSubmit = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    cashLogService
      .update({ ...entity, isVerified: true })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          onClose();
          onVerified({ ...entity, isVerified: true });
          setBankNoteCount([
            { bankNote: 20000, amount: 0 },
            { bankNote: 10000, amount: 0 },
            { bankNote: 5000, amount: 0 },
            { bankNote: 2000, amount: 0 },
            { bankNote: 1000, amount: 0 },
            { bankNote: 500, amount: 0 },
            { bankNote: 200, amount: 0 },
            { bankNote: 100, amount: 0 },
            { bankNote: 50, amount: 0 },
            { bankNote: 20, amount: 0 },
            { bankNote: 10, amount: 0 },
            { bankNote: 5, amount: 0 },
          ]);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setBankNoteCount([
          { bankNote: 20000, amount: 0 },
          { bankNote: 10000, amount: 0 },
          { bankNote: 5000, amount: 0 },
          { bankNote: 2000, amount: 0 },
          { bankNote: 1000, amount: 0 },
          { bankNote: 500, amount: 0 },
          { bankNote: 200, amount: 0 },
          { bankNote: 100, amount: 0 },
          { bankNote: 50, amount: 0 },
          { bankNote: 20, amount: 0 },
          { bankNote: 10, amount: 0 },
          { bankNote: 5, amount: 0 },
        ]);
        onClose();
      }}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle>Pénztár ellenőrzés:</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} p={2}>
          <Grid container item xs={12} spacing={2}>
            {bankNoteCount.map((item) => (
              <Grid item xs={12}>
                <CashLogBankNoteComponent
                  bankNote={item.bankNote}
                  onChange={(bankNote: number, amount: number) => {
                    setBankNoteCount(
                      bankNoteCount.map((item) =>
                        item.bankNote === bankNote
                          ? { ...item, amount: amount }
                          : item
                      )
                    );
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item>
              <TextField
                fullWidth
                label="Saját Végösszeg"
                value={formatCurrency(finalPriceGross)}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Házipénztár Végösszege"
                value={formatCurrency(entity?.closingCashGross.toFixed(0) ?? 0)}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Különbség"
                value={formatCurrency(result)}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          disabled={result >= 5 || result <= -5 || isNaN(result)}
          onClick={() => {
            onSubmit(entity);
          }}
        >
          Jóváhagyás
        </Button>
        <Button
          color="error"
          variant="outlined"
          onClick={() => {
            setBankNoteCount([
              { bankNote: 20000, amount: 0 },
              { bankNote: 10000, amount: 0 },
              { bankNote: 5000, amount: 0 },
              { bankNote: 2000, amount: 0 },
              { bankNote: 1000, amount: 0 },
              { bankNote: 500, amount: 0 },
              { bankNote: 200, amount: 0 },
              { bankNote: 100, amount: 0 },
              { bankNote: 50, amount: 0 },
              { bankNote: 20, amount: 0 },
              { bankNote: 10, amount: 0 },
              { bankNote: 5, amount: 0 },
            ]);
            onClose();
          }}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
