import AssignmentIcon from '@mui/icons-material/Assignment';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { formatCurrency } from '../../../utils/valueFormatters';
import CashLogCreate from '../cashLogs/CashLogCreate';
import RankingCardComponent from './RankingCardComponent';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';
import { useSelector } from 'react-redux';

const RankingTableComponent = (props: any) => {
  const { allData, page, rowsPerPage, handleChangePage, showCommission } =
    props;
  const isSmallScreen = useMediaQuery('(max-width:1400px)');
  const { checkPermission } = useCheckPermission();
  const user = useSelector((state: any) => state.user.userInfo);

  const [cashLogDialog, setCashLogDialog] = useState<any>({
    open: false,
    cash: 0,
    employeeId: 0,
  });
  const [commissionDialog, setCommissionDialog] = useState<any>({
    open: false,
    commissionOrders: [],
  });

  return (
    <>
      {!isSmallScreen && (
        <div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h3>#</h3>
                  </TableCell>
                  <TableCell>
                    <h3>Név:</h3>
                  </TableCell>
                  <TableCell>
                    <h3>Teljesített:</h3>
                  </TableCell>
                  <TableCell>
                    <h3>Kintlévő:</h3>
                  </TableCell>
                  <TableCell>
                    <h3>Lejárt:</h3>
                  </TableCell>
                  {checkPermission(['EditCashLog']) && (
                    <TableCell>
                      <h3>Készpénz:</h3>
                    </TableCell>
                  )}
                  <TableCell>
                    <h3>Jutalék:</h3>
                  </TableCell>

                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow key={data.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{data.employeeName}</TableCell>
                      <TableCell>
                        {formatCurrency(data?.paidIncomeValue.toFixed(0) ?? 0)}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(
                          data?.outstandingIncomeValue.toFixed(0) ?? 0
                        )}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(data?.dueIncomeValue.toFixed(0) ?? 0)}
                      </TableCell>
                      {checkPermission(['EditCashLog']) && (
                        <TableCell>
                          {formatCurrency(data?.cash.toFixed(0) ?? 0)}
                        </TableCell>
                      )}
                      {showCommission && (
                        <TableCell>
                          {(showCommission || user.userId === data?.userId) &&
                            formatCurrency(
                              data?.commission.toFixed(0) ?? 0
                            )}{' '}
                        </TableCell>
                      )}
                      <TableCell>
                        {checkPermission(['EditCashLog']) && (
                          <Tooltip title="Hozzáadás házi pénztárhoz">
                            <IconButton
                              onClick={() => {
                                setCashLogDialog({
                                  open: true,
                                  cash: data.cash.toFixed(0),
                                  employeeId: data.userId,
                                });
                              }}
                            >
                              <PointOfSaleIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {(showCommission || user.userId === data?.userId) && (
                          <Tooltip title="Jutalék megrendelései">
                            <IconButton
                              onClick={() => {
                                setCommissionDialog({
                                  open: true,
                                  orders: data.totalCommissionIncomes ?? [],
                                });
                              }}
                            >
                              <AssignmentIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={allData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </div>
      )}
      {isSmallScreen && (
        <Grid container>
          {allData.map((data, index) => (
            <Grid item xs={12} pb={2}>
              <RankingCardComponent
                allData={data}
                cardHeader={
                  <Grid item>
                    <Typography
                      fontWeight={'bold'}
                      display="flex"
                      textAlign="center"
                    >
                      <Typography fontWeight={'bold'}>{index + 1}.</Typography>
                    </Typography>
                  </Grid>
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Dialog
        open={cashLogDialog.open}
        onClose={() => {
          setCashLogDialog({ open: false, cash: 0, employeeId: 0 });
        }}
      >
        <DialogContent style={{ padding: 0 }}>
          <CashLogCreate
            navigateBack={false}
            onSave={() => {
              setCashLogDialog({ open: false, cash: 0, employeeId: 0 });
            }}
            handleCancel={() => {
              setCashLogDialog({ open: false, cash: 0, employeeId: 0 });
            }}
            externalEntity={{
              receiptNumber: '',
              userId: cashLogDialog.employeeId,
              clientId: '',
              description: 'Készpénz elszámolás',
              incomeGross: cashLogDialog.cash,
              expenseGross: 0,
              cashRegisterId: 0,
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={commissionDialog.open}
        onClose={() => {
          setCommissionDialog({ open: false, commissionOrders: [] });
        }}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Jutalék alapját képző bevételek:</DialogTitle>
        <DialogContent>
          <Grid item xs={12} pb={2}>
            <Typography fontWeight={'bold'} display="flex" textAlign="center">
              <Typography fontWeight={'bold'}>
                Összesen:{' '}
                {formatCurrency(
                  commissionDialog.orders
                    ?.reduce((prev, curr) => prev + curr.finalPriceGross, 0)
                    .toFixed(0) ?? 0
                )}{' '}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} pt={2}>
            <DataList
              rows={commissionDialog.orders || []}
              localStorageKey={'RankingTableComponent'}
              columns={[
                {
                  field: 'dateOfPayment',
                  headerName: 'Fizetés dátuma',
                  flex: 75,
                  valueFormatter: (params) => {
                    return params.value
                      ? new Date(params.value).toLocaleDateString()
                      : 'Nincs';
                  },
                },
                {
                  field: 'clientNameForQuickSearch',
                  headerName: 'ÜgyfélKereső',
                  flex: 100,
                  valueGetter: (params: any) => {
                    return params.row.clientName;
                  },
                },
                {
                  field: 'clientName',
                  headerName: 'Ügyfél',
                  flex: 150,
                  renderCell(params) {
                    return (
                      <EntityNavigator
                        entityType={EntityTypes.Client}
                        entityId={params.row.clientId}
                        value={params.row.clientName}
                      />
                    );
                  },
                },
                {
                  field: 'invoiceNumber',
                  headerName: 'Számlaszám',
                  flex: 100,
                },
                {
                  field: 'finalPriceGross',
                  headerName: 'Bruttó végösszeg',
                  valueFormatter: (params) => {
                    return formatCurrency(params.value.toFixed(0) ?? 0);
                  },
                  flex: 75,
                },
              ]}
              getRowId={(row) => row.id}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              setCommissionDialog({ open: false, commissionOrders: [] })
            }
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RankingTableComponent;
