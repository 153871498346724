import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SystemConfigurationForm from './SystemConfigurationForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../hooks/useCheckPermission';
import systemConfigurationService from '../../services/systemConfigurationService';
import { ModuleSettingsType } from '../../types/ModuleSettingsType';
import useModuleSettings from '../../hooks/useModuleSettings';
import { Grid } from '@mui/material';
import { is } from 'date-fns/locale';

const SystemConfigurationModuleSettings = () => {
  const { checkPermission } = useCheckPermission();

  const moduleSettings = useModuleSettings({
    type: ModuleSettingsType.ChimneySweepingCertificateInvoice,
    userId: null,
    isDefualtConfig: true,
  });

  return (
    <Grid container p={3}>
      <Grid item xs={12}>
        {checkPermission(['GetChimneySweepingCertificateModuleSettings']) && (
          <Grid item>{moduleSettings?.ModuleComponent()}</Grid>
        )}{' '}
      </Grid>
    </Grid>
  );
};

export default SystemConfigurationModuleSettings;
