import {
  Grid,
  Button,
  IconButton,
  SpeedDial,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Add, Delete } from '@mui/icons-material';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { formatCurrency } from '../../../utils/valueFormatters';
import SelectServiceDialog from '../../sales/SelectServiceDialog';

const SelectBillingServices = (props: any) => {
  const {
    values,
    setValues,
    services,
    readOnly,
    taxes,
    disableAddProduct = false,
    disableRemoveProduct = false,
  } = props;

  const [addServiceDialog, setAddServiceDialog] = useState(false);
  const { checkPermission } = useCheckPermission();
  return (
    <Grid container justifyContent="right">
      {!disableAddProduct && (
        <Grid item sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button
            disabled={readOnly}
            variant="outlined"
            onClick={() => setAddServiceDialog(true)}
          >
            Hozzáadás
          </Button>
        </Grid>
      )}
      {values?.selectedServices?.length === 0 && (
        <Grid item container xs={12}>
          Nem igényel szolgáltatásokat
        </Grid>
      )}
      <Grid item container xs={12}>
        {values?.map((x, i) => {
          let service = services.find((y) => y.id === x.product_id);
          return (
            <Grid
              item
              container
              spacing={2}
              pt={1}
              key={service?.id}
              alignItems="center"
              textAlign={'center'}
              style={{ borderBottom: '1px solid' }}
            >
              <Grid item xs={12} md={2}>
                <TextField
                  value={service?.name}
                  variant="standard"
                  label="Szolgáltatás megnevezése"
                  type="text"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={5} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    value={service?.servicePriceNet.toFixed(2)}
                    variant="standard"
                    label="Nettó egységár"
                    type="number"
                    fullWidth
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Ft</InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={service?.servicePriceGross.toFixed(2)}
                    variant="standard"
                    label="Bruttó egységár"
                    type="number"
                    name="grossPrice"
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Ft</InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                container
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={3}>
                  <TextField
                    value={x.quantity}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      // setFieldValue(`selectedServices[${i}].amount`, value);
                      setValues({ ...values });
                    }}
                    label="Egység"
                    type="number"
                    name="amount"
                    disabled={readOnly}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {service?.unitOfQuantity}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid item xs={12} md={4}>
                    <Typography fontWeight={'Bold'}>
                      Összesen(Bruttó):
                    </Typography>

                    {formatCurrency(service?.servicePriceGross * x.quantity)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                sx={{ position: 'relative', right: { xs: '0%' } }}
              >
                {!disableRemoveProduct && (
                  <IconButton
                    disabled={readOnly}
                    onClick={() => {
                      setValues([
                        ...values?.filter((y) => y.product_id !== x.product_id),
                      ]);
                    }}
                    color={'primary'}
                  >
                    <Delete />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <SelectServiceDialog
        services={services}
        open={addServiceDialog}
        taxes={taxes}
        setOpen={setAddServiceDialog}
        addToCart={(service) => {
          const i = values.selectedServices?.find((x) => x.id === service.id);

          // if (!i) {
          //   setFieldValue('selectedServices', [
          //     ...values.selectedServices,
          //     {
          //       ...service,
          //     },
          //   ]);
          // } else {
          //   setFieldValue('selectedServices', [
          //     ...values.selectedServices?.filter((x) => x.id !== service.id),
          //     {
          //       ...service,
          //       amount: i?.amount + service.amount,
          //       discount: service.discount,
          //     },
          //   ]);
          // }
          setAddServiceDialog(false);
        }}
      />
      {!disableAddProduct && (
        <SpeedDial
          ariaLabel="Szolgáltatás hozzáadása"
          onClick={() => setAddServiceDialog(true)}
          sx={{
            display: { xs: 'fixed', md: 'none' },
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
          icon={<Add />}
        ></SpeedDial>
      )}
    </Grid>
  );
};

export default SelectBillingServices;
