import {
  Add as AddIcon,
  CheckCircle as ApproveIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel as RejectIcon,
  Search,
  Visibility,
  Sync,
  Done,
} from '@mui/icons-material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import itemService from '../../../services/wms/itemService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import SupplierDialog from './SupplierDialog';
import RejectDialog from './components/RejectDialog';
import EntityNavigator from '../../../components/EntityNavigator';
import { EntityTypes } from '../../../types/EntityTypes';
import InventoryIcon from '@mui/icons-material/Inventory';
import CombineItemIdDialog from '../../../components/CombineItemIdDialog';
import { is } from 'date-fns/locale';

export interface IItem {
  id: number;
  name: string;
  itemName: string;
}

const ItemPage = (props: any) => {
  const params = useParams();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IItem[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('ItemPage.title');
  const [statusSentToApproval, setStatusSentToApproval] =
    useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusDeleted, setStatusDeleted] = useState<boolean>(false);
  const [statusApproved, setStatusApproved] = useState<boolean>(true);
  const [isMarked, setIsMarked] = useState<boolean>(false);
  const [list, setList] = useState<boolean>(false);
  const [supplierDialog, setSupplierDialog] = useState<any>({
    open: false,
    itemId: 0,
  });
  const [combineDialog, setCombineDialog] = useState<any>({
    open: false,
    itemId: 0,
  });

  const [status, setStatus] = useState(
    statusApproved
      ? EntityStatuses.Approved
      : statusSentToApproval
      ? EntityStatuses.SentToApproval
      : statusRejected
      ? EntityStatuses.Rejected
      : 'Deleted'
  );
  const [openRejectDialog, setOpenRejectDialog] = useState({
    open: false,
    entity: {},
  });

  useEffect(() => {
    const savedStatus = localStorage.getItem(
      `item_status_${window.location.hash}`
    );
    if (savedStatus) {
      setStatus(JSON.parse(savedStatus));
      setStatusApproved(savedStatus === '4');
      setStatusSentToApproval(savedStatus === '2');
      setStatusRejected(savedStatus === '3');
      setStatusDeleted(savedStatus === 'deleted');
    }
  }, []);

  const saveFilter = (status) => {
    localStorage.setItem(
      `item_status_${window.location.hash}`,
      JSON.stringify(status)
    );
  };

  const { checkPermission } = useCheckPermission();

  const fetchData = (controller: AbortController | null) => {
    dispatch({ type: 'SHOW_QUERY' });
    if (status === 'Deleted') {
      itemService
        .listDeleted(null, controller?.signal, isMarked)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) setRows(response.records);
          else if (response.errorMessages.length > 0)
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    } else {
      if (!list) {
        itemService
          .list(status as EntityStatuses, controller?.signal, isMarked)
          .then((response) => {
            if (response.canceled) return;
            if (!response.hasError) setRows(response.records);
            else if (response.errorMessages.length > 0)
              enqueueSnackbar(response.errorMessages.join(','), {
                variant: 'error',
              });
          })
          .finally(() => dispatch({ type: 'HIDE' }));
      } else {
        itemService
          .listMy(status as EntityStatuses, controller?.signal, isMarked)
          .then((response) => {
            if (response.canceled) return;
            if (!response.hasError) setRows(response.records);
            else if (response.errorMessages.length > 0)
              enqueueSnackbar(response.errorMessages.join(','), {
                variant: 'error',
              });
          })
          .finally(() => dispatch({ type: 'HIDE' }));
      }
    }
  };

  const takeOffMarking = (entity: any) => {
    itemService.update({ ...entity, takeOffMarking: true }).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres művelet', {
          variant: 'success',
        });
        fetchData(null);
      }
    });
  };

  useEffect(() => {
    let controller = new AbortController();
    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, [status, list, isMarked]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    if (
      status === EntityStatuses.SentToApproval &&
      checkPermission(['ItemApprove'])
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'success'}
          icon={
            <Tooltip title="Jóváhagyás">
              <ApproveIcon />
            </Tooltip>
          }
          label="Jóváhagyás"
          onClick={() => handleApprove(params.row.id)}
        />
      );
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'error'}
          icon={
            <Tooltip title="Elutasítás">
              <RejectIcon />
            </Tooltip>
          }
          label="Elutasítás"
          onClick={() =>
            setOpenRejectDialog({ open: true, entity: params.row })
          }
        />
      );
    }

    let readonly = !checkPermission(['ItemEdit']);

    actions.push(
      <EntityNavigator
        entityType={EntityTypes.Item}
        entityId={params.row.id}
        value={readonly ? 'Megtekintés' : 'Szerkesztés'}
        showIcon
        readonly={readonly}
        onSave={(result: any) => {
          itemService.get(result.id).then((response) => {
            if (response.canceled) return;
            if (response.hasError) {
              enqueueSnackbar(response.errorMessages.join(','), {
                variant: 'error',
              });
            } else {
              if (status === response.result.status) {
                setRows(
                  rows.map((row) => {
                    if (row.id === result.id) {
                      return response.result;
                    }
                    return row;
                  })
                );
              } else {
                setRows(rows.filter((row) => row.id !== result.id));
              }
            }
          });
        }}
      />
    );
    if (checkPermission(['ItemSuppliersView'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Beszállítók">
              <LocalShippingIcon />
            </Tooltip>
          }
          label="Beszállítók"
          onClick={() =>
            setSupplierDialog({ open: true, itemId: params.row.id })
          }
        />
      );
    }
    if (
      checkPermission(['ItemCombine']) &&
      status === EntityStatuses.Approved
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Összevonás">
              <Sync />
            </Tooltip>
          }
          label="Összevonás"
          showInMenu
          onClick={() =>
            setCombineDialog({ open: true, itemId: params.row.id })
          }
        />
      );
    }
    if (checkPermission(['ItemDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'isMarked',
      headerName: 'Megjelölt',
      description: 'Megjelölt termék',
      flex: 20,
      renderCell(params) {
        return params.value ? (
          <Tooltip title={'Megjelölt termék!'}>
            <IconButton
              onClick={() => {
                takeOffMarking(params.row);
              }}
            >
              {' '}
              <Done color="success" />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        );
      },
    },
    {
      field: 'isComplexItem',
      headerName: 'Összetett',
      description: 'Összetett termék',
      flex: 20,
      renderCell(params) {
        return params.value ? (
          <Tooltip title={'Összetett termék!'}>
            <InventoryIcon color="primary" />
          </Tooltip>
        ) : (
          ''
        );
      },
    },
    {
      field: 'itemNumber',
      headerName: 'Cikkszám',
      description: 'Cikkszám',
      flex: 50,
    },
    {
      field: 'name',
      headerName: 'Megnevezés',
      description: 'Megnevezés',
      flex: 100,
    },
    {
      field: 'brandName',
      headerName: 'Márka',
      description: 'Márka',
      flex: 100,
    },
    {
      field: 'itemGroupName',
      headerName: 'Termékcsoport',
      description: 'Termékcsoport',
      flex: 100,
    },
    {
      field: 'approverComment',
      description: 'Jóváhagyó megjegyzése',
      headerName: 'Jóváhagyó megjegyzése',
      flex: 100,
    },
    {
      minWidth: 250,
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    itemService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const handleReject = (entity: any, comment: string) => {
    setOpenRejectDialog({ open: false, entity: {} });
    itemService.reject(entity.id, comment).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres visszautasítás', {
          variant: 'success',
        });

        dispatch({ type: 'DECREASE_APPROVAL_COUNT' });
        setRows(rows.filter((row) => row.id !== entity.id));
      }
    });
  };

  const handleApprove = (id: number) => {
    itemService.approve(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres jóváhagyás', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
        dispatch({ type: 'DECREASE_APPROVAL_COUNT' });
      }
    });
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchItems = () => {
    // Convert the search term to lowercase for case-insensitive search
    const searchTermLower = searchTerm.toLowerCase();

    // Perform a fuzzy search by splitting the search term into tokens
    const searchTokens = searchTermLower.split(/\s+/);

    // Filter items based on the search tokens
    const filteredItems = rows.filter((item: any) => {
      // Convert the item name to lowercase
      let name = `${item.name} ${item.itemNumber}`;
      const itemNameLower = name.toLowerCase();

      // Check if each token is present in the item name
      return searchTokens.every((token) => itemNameLower.includes(token));
    });

    return filteredItems;
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Termékek{titleDescriptor}</h2>
        </Grid>
        <Grid container xs={12} item justifyContent="space-between">
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginBottom={2}
            >
              <RadioGroup
                row
                value={
                  statusApproved
                    ? 'approved'
                    : statusSentToApproval
                    ? 'sentToApproval'
                    : statusRejected
                    ? 'rejected'
                    : 'deleted'
                }
                onChange={(e) => {
                  const value = e.target.value;
                  setStatusApproved(value === 'approved');
                  setStatusSentToApproval(value === 'sentToApproval');
                  setStatusRejected(value === 'rejected');
                  setStatusDeleted(value === 'deleted');
                  setStatus(
                    value === 'approved'
                      ? EntityStatuses.Approved
                      : value === 'sentToApproval'
                      ? EntityStatuses.SentToApproval
                      : value === 'rejected'
                      ? EntityStatuses.Rejected
                      : 'Deleted'
                  );
                  saveFilter(
                    value === 'approved'
                      ? EntityStatuses.Approved
                      : value === 'sentToApproval'
                      ? EntityStatuses.SentToApproval
                      : value === 'rejected'
                      ? EntityStatuses.Rejected
                      : 'Deleted'
                  );
                }}
              >
                <FormControlLabel
                  value="approved"
                  control={<Radio color="primary" />}
                  label="Jóváhagyott"
                />
                <FormControlLabel
                  value="sentToApproval"
                  control={<Radio color="primary" />}
                  label="Jóváhagyásra váró"
                />
                <FormControlLabel
                  value="rejected"
                  control={<Radio color="primary" />}
                  label="Elutasított"
                />
                <FormControlLabel
                  value="deleted"
                  control={<Radio color="primary" />}
                  label="Archivált"
                />
              </RadioGroup>
            </Stack>
          </Grid>
          <Grid item xs={12} container pl={1}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={list}
                    onChange={(e, checked) => setList(checked)}
                  />
                }
                label="Saját termékek"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMarked}
                    onChange={(e, checked) => setIsMarked(checked)}
                  />
                }
                label="Megjelölt termékek"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} p={0} justifyContent="end">
            {checkPermission([
              'ItemCreate',
              'BrandView',
              'PropertyView',
              'ItemGroupView',
            ]) && (
              <Grid item xs={12} textAlign="end">
                <Tooltip title="Termék létrehozása">
                  <IconButton
                    component={RouterLink}
                    to={`/wms/items/create`}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} pb={2}>
          <TextField
            value={searchTerm ?? ''}
            onChange={handleSearchChange}
            label="Termék keresése"
            name="name"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {status === EntityStatuses.Approved && (
            <DataList
              rows={searchItems()}
              columns={columns}
              localStorageKey={'ItemPage'}
              disableSearchBar
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    approverComment: false,
                  },
                },
              }}
            />
          )}
          {status !== EntityStatuses.Approved && (
            <DataList
              rows={searchItems()}
              localStorageKey={'ItemPage'}
              columns={columns}
              disableSearchBar
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    approverComment: true,
                  },
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      <ConfirmDeleteDialog />
      <CombineItemIdDialog
        open={combineDialog.open}
        onClose={() => {
          setCombineDialog({ open: false, itemId: 0 });
        }}
        itemId={combineDialog.itemId}
        onSelected={() => {
          fetchData(null);
        }}
      />
      <RejectDialog
        open={openRejectDialog.open}
        entity={openRejectDialog.entity}
        setOpen={() =>
          setOpenRejectDialog((state) => ({ ...state, open: false }))
        }
        onReject={handleReject}
      />
      <SupplierDialog
        open={supplierDialog.open}
        setOpen={setSupplierDialog}
        itemId={supplierDialog.itemId}
      />
    </Paper>
  );
};

export default ItemPage;
