import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  DialogActions,
  Dialog,
  DialogContent,
  Badge,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  CheckCircle as ApproveIcon,
  Cancel as RejectIcon,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import offerService from '../../../services/erp/offerService';
import { parseJSON } from 'date-fns';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { offerStatus } from '../../../types/OfferStatus';
import SendIcon from '@mui/icons-material/Send';
import workItemService from '../../../services/erp/workItemService';
import { ResourcePlannerDialog } from '../workItems/resourcePlanner/ResourcePlannerDialog';
import { WorkItemStatus } from '../workItems/WorkItemForm';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import moment from 'moment';
import { formatCurrency } from '../../../utils/valueFormatters';
import clientService from '../../../services/crm/clientService';
import PrintIcon from '@mui/icons-material/Print';
import Header from '../../../layout/Header';
import { EntityStatuses } from '../../../types/EntityStatuses';
import OfferEmailDialog from './OfferEmailDialog';
import { EmailStatuses } from '../../../types/EmailStatuses';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import DraftsIcon from '@mui/icons-material/Drafts';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import useSignalREffect from '../../../hooks/useSignalREffect';
import { translateEmailStatusName } from '../../../utils/nameFormatters';
import OfferPrintView from './OfferPrintView';
import CreateEntityDialog from '../../../components/CreateEntityDialog';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import FilePageDialog from '../../../components/FilePageDialog';
import { Order } from '../../sales/orders/OrderPage';

export interface Offer {
  expireAt: Date;
  planned: Date;
  conditions: string;
  guarantee: string;
  remark: string;
  workItemId: string;
  selectedServices: any[];
  selectedItems: any[];
  clientId: number;
}

const OfferPage = (props: any) => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [emailDialog, setEmailDialog] = useState<any>({ open: false, id: 0 });
  const titleDescriptor = useFunctionDescriptor('OfferPage.title');
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const [clients, setClients] = useState<any[]>([]);
  const { offerInProgress } = useSelector<any>((state) => state.offer) as any;
  const [pdfDialog, setPdfDialog] = useState<any>({ open: false, id: 0 });

  const [resourcePlannerDialogState, setResourcePlannerDialogState] =
    useState<any>({ open: false, workItemId: 0 });
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    clientService.list(false, EntityStatuses.Approved).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
      }
    });
  }, []);

  const RejectOffer = (id: number) => {
    offerService.reject(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres elutasítás', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const handleStatusChange = (id: number, newStatus: offerStatus) => {
    const rowIndex = rows.findIndex((row) => row.id === id);
    if (rowIndex !== -1) {
      if (newStatus === offerStatus.accepted) {
        offerService.accept(id).then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            const updatedRow = { ...rows[rowIndex] };
            updatedRow.status = newStatus;
            const updatedRows = [...rows];
            updatedRows[rowIndex] = updatedRow;
            setRows(updatedRows);
          }
        });
      } else {
        offerService
          .updateStatus({ id, status: newStatus })
          .then((response) => {
            if (response.canceled) return;
            if (response.hasError) {
              enqueueSnackbar(response.errorMessages.join(','), {
                variant: 'error',
              });
            } else {
              const updatedRow = { ...rows[rowIndex] };
              updatedRow.status = newStatus;
              const updatedRows = [...rows];
              updatedRows[rowIndex] = updatedRow;
              setRows(updatedRows);
            }
          });
      }
    }
  };

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    offerService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const sendEmail = (row: any) => {
    setEmailDialog({ open: true, id: row.id });
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    if (
      params.row.status === offerStatus.draft &&
      params.row.pdfFileAttachmentId > 0
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Küldés">
              <SendIcon />
            </Tooltip>
          }
          label="Küldés"
          onClick={() => sendEmail(params.row)}
        />
      );
    }

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'success'}
        icon={
          <Tooltip title="Jóváhagyás">
            <ApproveIcon />
          </Tooltip>
        }
        label="Jóváhagyás"
        onClick={() => handleStatusChange(params.row.id, offerStatus.accepted)}
        showInMenu
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'error'}
        icon={
          <Tooltip title="Elutasítás">
            <RejectIcon />
          </Tooltip>
        }
        label="Elutasítás"
        onClick={() => handleStatusChange(params.row.id, offerStatus.denied)}
        showInMenu
      />
    );

    let readonly = !checkPermission(['OfferEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/erp/offers/edit/${params.row.id}`)}
      />
    );

    if (!readonly && params.row.workItemId > 0) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Munkalap szerkesztés">
              <AssignmentIcon />
            </Tooltip>
          }
          label={'Szerkesztés'}
          onClick={() =>
            navigate(`/erp/workitems/edit/${params.row.workItemId}/0/offer`)
          }
        />
      );
    }
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="PDF nézet">
            <PrintIcon />
          </Tooltip>
        }
        label={'PDF nézet'}
        onClick={() => setPdfDialog({ open: true, id: params.row.id })}
      />
    );

    if (params.row.status === offerStatus.sent) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'error'}
          icon={
            <Tooltip title="Végleges elutasítás">
              <RejectIcon />
            </Tooltip>
          }
          label="Végleges elutasítás"
          onClick={() => RejectOffer(params.row.id)}
          showInMenu
        />
      );
    }

    if (checkPermission(['OfferDelete']) && !(params.row.worItemId > 0)) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }
    return actions;
  };

  const getHeaders = (params: GridRowParams, color: any) => {
    var actions = [];
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus !== EmailStatuses.Sent &&
      params.row.lastEmailStatus !== EmailStatuses.Opened &&
      params.row.lastEmailStatus !== EmailStatuses.Delivered
    ) {
      actions.push(
        <GridActionsCellItem
          color={'error'}
          icon={
            <Tooltip
              title={`${translateEmailStatusName(params.row.lastEmailStatus)}`}
            >
              <UnsubscribeIcon />
            </Tooltip>
          }
          label="Sikerleten"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus === EmailStatuses.Delivered
    ) {
      actions.push(
        <GridActionsCellItem
          color={'primary'}
          icon={
            <Tooltip title="Megérkezett">
              <MarkEmailReadIcon />
            </Tooltip>
          }
          label="Megérkezett"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus === EmailStatuses.Opened
    ) {
      actions.push(
        <GridActionsCellItem
          color={'success'}
          icon={
            <Tooltip title="Megnyitva">
              <DraftsIcon />
            </Tooltip>
          }
          label="Megnyitva"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus === EmailStatuses.Sent
    ) {
      actions.push(
        <GridActionsCellItem
          color={'warning'}
          icon={
            <Tooltip title="Elküldve">
              <MailOutlineIcon />
            </Tooltip>
          }
          label="Elküldve"
          onClick={() => {}}
        />
      );
    }
    if (!params.row.lastEmailStatus) {
      actions.push(
        <GridActionsCellItem
          icon={
            <Tooltip title="Nincs kiküldve">
              <MailOutlineIcon />
            </Tooltip>
          }
          label="Elküldve"
          onClick={() => {}}
        />
      );
    }
    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'getHeaders',
      type: 'actions',
      align: 'center',
      flex: 20,
      getActions: getHeaders,
    } as GridActionsColDef,
    { field: 'offerNumber', headerName: 'Árajánlat száma', flex: 150 },
    { field: 'workItemNumber', headerName: 'Munkalapszám', flex: 100 },
    {
      field: 'clientId',
      headerName: 'Ügyfél',
      flex: 300,
      valueFormatter: (params) =>
        clients?.find((g) => g.id === params.value)?.companyName ??
        clients?.find((g) => g.id === params.value)?.name.fullName ??
        '',
    },
    {
      field: 'expireAt',
      headerName: 'Lejárat',
      flex: 200,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleDateString();
      },
    },
    {
      field: 'planned',
      headerName: 'Tervezett',
      flex: 200,
      valueFormatter: (params) => {
        if (!params.value) {
          return '';
        }
        return parseJSON(params.value).toLocaleDateString();
      },
    },

    {
      field: 'price',
      headerName: 'Bruttó végösszeg',
      flex: 200,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'status',
      headerName: 'Státusz',
      flex: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.value === 1 ? (
              <span style={{ color: 'orange' }}>Piszkozat</span>
            ) : params.value === 2 ? (
              <span style={{ color: 'lightgreen' }}>Elküldve</span>
            ) : params.value === 3 ? (
              <span style={{ color: 'green' }}>Elfogadva</span>
            ) : params.value === 4 ? (
              <span style={{ color: 'red' }}>Elutasítva</span>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 300,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    offerService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const connection = useSignalREffect('emailHub');
  const handleEmailChanged = useCallback(
    (newStatus: number, emailId: number, openetAt: Date | null) => {
      setRows((prevRows) => {
        return prevRows.map((row) => {
          let found = row.emails.find((email: any) => email.id === emailId);
          if (found) {
            found.status = newStatus as EmailStatuses;
            found.openedAt = new Date(openetAt);
            row.lastEmailStatus = newStatus as EmailStatuses;
          }
          return row;
        });
      });
    },
    []
  );

  useEffect(() => {
    if (!connection) return;

    var callback = handleEmailChanged;
    connection.on('EmailStatusChanged', callback);

    return () => {
      connection.off('EmailStatusChanged', callback);
    };
  }, [connection, handleEmailChanged]);

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Ajánlatok{titleDescriptor}</h2>
        </Grid>
        {checkPermission(['OfferCreate']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Ajánlat létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/offers/create/0`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            rowId={(row) => row.id}
            localStorageKey={'OfferPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <ResourcePlannerDialog
          open={resourcePlannerDialogState.open}
          onClose={() =>
            setResourcePlannerDialogState({ open: false, workItemId: 0 })
          }
          handleResourceSelected={(employeeId, startDate: Date, workItemId) => {
            workItemService
              .delegate({
                employeeId,
                timeWindowStart: startDate,
                id: workItemId,
                status: WorkItemStatus.Assigned,
                timeWindowEnd: moment(startDate).add(1, 'hour').toDate(),
              })
              .then((response) => {
                if (response.canceled) return;
                if (response.hasError) {
                  enqueueSnackbar(response.errorMessages.join(','), {
                    variant: 'error',
                  });
                } else {
                  enqueueSnackbar('Sikeres delegálás', {
                    variant: 'success',
                  });
                  setRows(rows.filter((row) => row.id !== workItemId));
                }
              })
              .finally(() =>
                setResourcePlannerDialogState({ open: false, workItemId: 0 })
              );
          }}
          workItemId={resourcePlannerDialogState.workItemId}
        />
      </Grid>
      <OfferEmailDialog
        open={emailDialog.open}
        setOpen={setEmailDialog}
        offerId={emailDialog.id}
        handleStatusChange={(id) => handleStatusChange(id, offerStatus.sent)}
        onSent={(result) => {
          setRows((prevRows) => {
            return prevRows.map((row) => {
              if (row.id === emailDialog.id) {
                row.lastEmailStatus = EmailStatuses.Sent;
                row.emails.push(result);
              }
              return row;
            });
          });
        }}
      />
      <CreateEntityDialog
        CreateEntityComponent={OfferPrintView}
        open={pdfDialog.open}
        handleCancel={() => setPdfDialog({ open: false, id: 0 })}
        offerId={pdfDialog.id}
      />
      <FilePageDialog
        onList={(length) => {
          setRows((prev) => {
            return prev.map((row) => {
              if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                row.attachmentCount = length;
              }
              return row;
            });
          });
        }}
        open={filePageDialogOpen.open}
        onClose={() =>
          setFilePageDialogOpen({ open: false, entityUniqueId: null })
        }
        attachmentType={AttachmentTypes.Offer}
        entityUniqueId={filePageDialogOpen.entityUniqueId}
        localStorageKey={'OfferPage'}
      />
    </Paper>
  );
};

export default OfferPage;
