import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { DocumentBlockFormats } from '../../../types/DocumentBlockFormats';
import { translateDocumentBlockTypesName } from '../../../utils/nameFormatters';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';

const DocumentBlockForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    users,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Bizonylattömb {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={values.type}
                  onChange={(event, newValue) => {
                    setFieldValue('type', newValue);
                  }}
                  options={Object.values(DocumentBlockTypes).filter((x) =>
                    Number.isFinite(x)
                  )}
                  getOptionLabel={(option) => {
                    return translateDocumentBlockTypesName(option);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Típus"
                      name="type"
                      disabled={readonly}
                      fullWidth
                      error={!!touched.type && !!validationErrors.type}
                      helperText={
                        !!touched.type &&
                        !!validationErrors.type &&
                        (validationErrors.type as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="userIds"
                  multiple
                  value={values.userIds}
                  onChange={(event, value) => {
                    setFieldValue('userIds', value);
                  }}
                  getOptionLabel={(option) => {
                    let user = users.find((g) => g.id === option);
                    return `${user?.fullName} (${user?.email ?? ''})`;
                  }}
                  options={users
                    .map((g) => g.id)
                    .filter((x) => !values.userIds?.some((y) => y === x))}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Felhasználók" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.prefix}
                  onChange={handleChange}
                  label="Prefix"
                  name="prefix"
                  required
                  disabled={readonly}
                  fullWidth
                  error={!!touched.prefix && !!validationErrors.prefix}
                  helperText={
                    !!touched.prefix &&
                    !!validationErrors.prefix &&
                    (validationErrors.prefix as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.senderName}
                  onChange={handleChange}
                  label="Küldő neve"
                  name="senderName"
                  disabled={readonly}
                  fullWidth
                  error={!!touched.senderName && !!validationErrors.senderName}
                  helperText={
                    !!touched.senderName &&
                    !!validationErrors.senderName &&
                    (validationErrors.senderName as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.senderEmail}
                  onChange={handleChange}
                  label="Küldő email címe"
                  name="senderEmail"
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.senderEmail && !!validationErrors.senderEmail
                  }
                  helperText={
                    !!touched.senderEmail &&
                    !!validationErrors.senderEmail &&
                    (validationErrors.senderEmail as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={values.format}
                  onChange={(event, newValue) => {
                    setFieldValue('format', newValue);
                  }}
                  options={Object.values(DocumentBlockFormats).filter((x) =>
                    Number.isFinite(x)
                  )}
                  getOptionLabel={(option) => {
                    return option === DocumentBlockFormats.Default
                      ? 'Alapértelmezett(XJZ-2020-109)'
                      : option === DocumentBlockFormats.LongWithSlash
                      ? 'Hosszú perjellel (XJZ / 2020-000109)'
                      : '';
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Formátum"
                      name="format"
                      disabled={readonly}
                      fullWidth
                      error={!!touched.format && !!validationErrors.format}
                      helperText={
                        !!touched.format &&
                        !!validationErrors.format &&
                        (validationErrors.format as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default DocumentBlockForm;
