import { usePDF } from '@react-pdf/renderer';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material';
import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataList from '../../../components/DataList';
import offerService from '../../../services/erp/offerService';
import { OfferPDF } from './OfferPDF';
import { parseJSON } from 'date-fns';
import { translateEmailStatusName } from '../../../utils/nameFormatters';

export default function OfferEmailDialog(props: any) {
  const { offerId, open, setOpen, handleStatusChange, onSent } = props;
  const [offer, setOffer] = React.useState<any>();
  const [address, setAddress] = React.useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const user = useSelector((state: any) => state.user.userInfo);

  useEffect(() => {
    if (offerId > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      offerService
        .get(offerId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setOffer(response.result);
            setAddress(response.result.clientEmail);
          } else {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          }
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [offerId]);

  const calculateNetSum = useCallback(() => {
    let itemNetSum = 0;
    let serviceNetSum = 0;

    if (offer && offer.selectedItems) {
      itemNetSum = offer.selectedItems.reduce(
        (sum, item) => sum + item.sellPriceNet,
        0
      );
    }

    if (offer && offer.selectedServices) {
      serviceNetSum = offer.selectedServices.reduce(
        (sum, service) => sum + service.servicePriceNet,
        0
      );
    }

    return itemNetSum + serviceNetSum;
  }, [offer]);
  const [instance, update] = usePDF({
    document: (
      <OfferPDF
        offer={offer}
        user={user}
        createdAt={new Date(offer?.createdAt).toLocaleDateString('hu-HU')}
        calculateNetSum={calculateNetSum}
      />
    ),
  });

  useEffect(() => {
    update(
      <OfferPDF
        offer={offer}
        user={user}
        createdAt={new Date(offer?.createdAt).toLocaleDateString('hu-HU')}
        calculateNetSum={calculateNetSum}
      />
    );
  }, [offer]);

  const sendEmail = async (row: any) => {
    let value = instance.blob;

    const reader = new FileReader();
    reader.readAsDataURL(value);
    reader.onloadend = () => {
      const base64String = reader.result?.toString().split(',')[1];

      const entity = {
        to: address,
        offerId: row.id,
        attachments: [{ name: 'Árajánlat.pdf', content: base64String }],
      };
      dispatch({ type: 'SHOW_QUERY' });
      offerService
        .sendEmail(entity)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Sikeres küldés', {
              variant: 'success',
            });
            onSent(response.result);
            setOpen({ open: false, id: 0 });
            handleStatusChange(row.id);
          }
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    };
  };

  const columns: GridColDef[] = [
    {
      field: 'to',
      headerName: 'Email cím',
      flex: 200,
    },
    {
      field: 'sentAt',
      headerName: 'Küldés dátuma',
      valueFormatter(params) {
        return new Date(params.value).toLocaleDateString('hu-HU');
      },
      flex: 200,
    },
    {
      field: 'openedAt',
      headerName: 'Megnyitás dátuma',
      valueFormatter(params) {
        var date = new Date(params.value);
        return date > new Date(null)
          ? parseJSON(params.value).toLocaleString()
          : 'Nincs megnyitva';
      },
      flex: 200,
    },
    {
      field: 'status',
      headerName: 'Státusz',
      valueFormatter(params) {
        return translateEmailStatusName(params.value);
      },
      flex: 150,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={() => setOpen({ open: false, id: 0 })}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Email küldése:</DialogTitle>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <Autocomplete
            disablePortal
            id="address"
            value={address ? address.split(';') : []}
            onChange={(event, value) => {
              setAddress(value.join(';'));
            }}
            multiple
            freeSolo
            autoSelect
            renderInput={(params) => (
              <TextField {...params} required label="Email cím" />
            )}
            options={[]}
          />
        </Grid>
        <Grid item xs={12} pt={2}>
          Korábbi küldések:
          <DataList
            rows={offer?.emails || []}
            columns={columns}
            getRowId={(row) => row.id}
            minimal
            localStorageKey={'OfferEmailDialog'}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!offer}
          onClick={() => {
            sendEmail(offer);
          }}
        >
          Küldés
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setOpen({ open: false, id: 0 })}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
