import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TodayMarker,
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { ResourcePlannerConfirmDialog } from './ResourcePlannerConfirmDialog';
import { useSnackbar } from 'notistack';

const ResourcePlanner = (props: any) => {
  const {
    employees = [],
    workItemId = 0,
    selectedDate,
    events = [],
    onResourceSelected,
    setSelectedDate,
    users,
    isCalendar = false,
  } = props;

  const [
    resourcePlannerConfirmDialogState,
    setResourcePlannerConfirmDialogState,
  ] = useState<any>({
    opnen: false,
    workItemId: 0,
    groupId: 0,
    time: new Date(),
  });

  const theme = useTheme();
  const [timeStamp, setTimeStamp] = useState(new Date().getTime());
  const { enqueueSnackbar } = useSnackbar();
  const [selectedShift, setSelectedShift] = useState('MorningShift');

  const groups = employees?.map((x) => ({ id: x.id, title: x.fullName }));

  const handleShiftChange = (event) => {
    setSelectedShift(event.target.value);
  };

  useEffect(() => {
    setTimeStamp(new Date().getTime());
  }, [events]);
  useEffect(() => {
    const handleResize = () => setTimeStamp(new Date().getTime());

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [visibleTimeStart, setVisibleTimeStart] = useState(
    moment(selectedDate).startOf('day').valueOf()
  );
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    moment(selectedDate).endOf('day').valueOf()
  );

  useEffect(() => {
    console.log(employees);
    if (selectedShift === 'MorningShift') {
      setVisibleTimeStart(
        moment(selectedDate).startOf('day').add(7, 'hours').valueOf()
      );
      setVisibleTimeEnd(
        moment(selectedDate).startOf('day').add(15, 'hours').valueOf()
      );
    } else if (selectedShift === 'NightShift') {
      setVisibleTimeStart(
        moment(selectedDate).startOf('day').add(15, 'hours').valueOf()
      );
      setVisibleTimeEnd(
        moment(selectedDate)
          .add(1, 'day')
          .startOf('day')
          .add(2, 'hours')
          .valueOf()
      );
    } else {
      setVisibleTimeStart(
        moment(selectedDate).startOf('day').add(10, 'hours').valueOf()
      );
      setVisibleTimeEnd(
        moment(selectedDate).startOf('day').add(17, 'hours').valueOf()
      );
    }
  }, [selectedShift]);

  const hoursBetweenStartAndEnd = moment(visibleTimeEnd).diff(
    moment(visibleTimeStart),
    'hours'
  );
  return (
    <Grid container p={2}>
      {!isCalendar && (
        <Grid item container justifyContent="center" xs={12} pb={5}>
          <Grid item>
            <IconButton
              onClick={() =>
                setSelectedDate((s) => moment(s).add(-1, 'days').toDate())
              }
            >
              <ArrowBackIosIcon />
            </IconButton>
            <DatePicker
              label="Dátum"
              inputFormat="yyyy-MM-dd"
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <IconButton
              onClick={() =>
                setSelectedDate((s) => moment(s).add(1, 'days').toDate())
              }
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <RadioGroup
          aria-label="shift"
          name="shift"
          style={{ flexDirection: 'row' }}
          value={selectedShift}
          onChange={handleShiftChange}
        >
          <FormControlLabel
            value="MorningShift"
            control={<Radio />}
            label="Délelőtti műszak"
          />
          <FormControlLabel
            value="AfternoonShift"
            control={<Radio />}
            label="Délutáni műszak"
          />
          <FormControlLabel
            value="NightShift"
            control={<Radio />}
            label="Éjszakai műszak"
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12} style={{ width: '70svh' }}>
        {timeStamp && (
          <Timeline
            groups={groups}
            items={events}
            onCanvasClick={(groupId, time, event) => {
              let timeAndDay = new Date(time).setDate(selectedDate.getDate());

              if (timeAndDay > new Date().getTime()) {
                setResourcePlannerConfirmDialogState({
                  open: true,
                  workItemId: workItemId,
                  groupId: groupId,
                  time: timeAndDay,
                });
              } else {
                enqueueSnackbar(
                  'A kezdési időpont nem lehet kisebb a jelenlegi időpontnál!',
                  {
                    variant: 'error',
                  }
                );
              }
            }}
            canSelect
            visibleTimeStart={visibleTimeStart}
            visibleTimeEnd={visibleTimeEnd}
            canvasTimeStart={visibleTimeStart}
            canvasTimeEnd={visibleTimeEnd}
            style={{ color: 'black', border: '1px solid grey' }}
            buffer={1}
            sidebarWidth={200}
            itemTouchSendsClick={true}
            groupRenderer={({ group }) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    height: '100%',
                  }}
                >
                  <strong>{group.title}</strong>
                </Box>
              );
            }}
          >
            <TimelineHeaders
              style={{
                background: theme.palette.primary.main,
                color: 'white',
                borderWidth: 0,
              }}
            >
              <SidebarHeader>
                {({ getRootProps }) => {
                  const props = getRootProps();
                  return (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      {...props}
                    >
                      <Grid item>
                        <strong>Munkatársak</strong>
                      </Grid>
                    </Grid>
                  );
                }}
              </SidebarHeader>
              <DateHeader
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                }}
                className="date_line"
                labelFormat="yyyy.MM.DD"
                unit="day"
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  return (
                    <Box
                      sx={{
                        borderRight: '1px solid grey',
                        textAlign: 'center',
                        backgroundColor: 'lightGrey',
                        height: '100%',
                        position: 'absolute',
                        left:
                          getIntervalProps().style.left > 0
                            ? getIntervalProps().style.left
                            : 0,
                        width:
                          getIntervalProps().style.width /
                          (24 / hoursBetweenStartAndEnd),
                        display: 'inline-flex',
                      }}
                    >
                      <Grid xs={12}>
                        <strong> {intervalContext.intervalText}</strong>
                      </Grid>
                    </Box>
                  );
                }}
              />
              <DateHeader
                unit="hour"
                labelFormat="HH:mm"
                style={{ height: 50 }}
                intervalRenderer={({ getIntervalProps, intervalContext }) => {
                  return (
                    <Box
                      {...getIntervalProps()}
                      sx={{
                        borderRight: '1px solid grey',
                        textAlign: 'center',
                        height: '100%',
                      }}
                    >
                      <strong> {intervalContext.intervalText}</strong>
                    </Box>
                  );
                }}
              />
            </TimelineHeaders>
            <TodayMarker />
          </Timeline>
        )}
      </Grid>
      {!isCalendar && (
        <ResourcePlannerConfirmDialog
          open={resourcePlannerConfirmDialogState.open}
          onResourceSelected={onResourceSelected}
          onClose={() =>
            setResourcePlannerConfirmDialogState({
              ...resourcePlannerConfirmDialogState,
              open: false,
            })
          }
          selectedDate={selectedDate}
          groupId={resourcePlannerConfirmDialogState.groupId}
          time={resourcePlannerConfirmDialogState.time}
          workItemId={resourcePlannerConfirmDialogState.workItemId}
          users={users}
        />
      )}
    </Grid>
  );
};

export default ResourcePlanner;
