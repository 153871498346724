import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Badge,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  FileUpload,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import projectService from '../../../services/erp/projectService';
import { ProjectStatuses } from '../../../types/ProjectStatuses';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import FilePageDialog from '../../../components/FilePageDialog';
import { ChimneySweepingCertificate } from '../../chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificatePage';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';

const ProjectPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('ProjectPage.title');
  const [checkboxValue, setCheckboxValue] = useState(true);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (checkboxValue) {
      dispatch({ type: 'SHOW_QUERY' });
      projectService
        .listmy()
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) setRows(response.records);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    } else {
      dispatch({ type: 'SHOW_QUERY' });
      projectService
        .list()
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) setRows(response.records);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [checkboxValue]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    let readonly = !checkPermission(['ProjectEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/erp/projects/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['ProjectDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Megnevezés', flex: 250 },
    { field: 'projectNumber', headerName: 'Projektszám', flex: 100 },
    {
      field: 'clientNameForQuickSearch',
      headerName: 'ÜgyfélKereső',
      flex: 100,
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'clientName',
      headerName: 'Ügyfél',
      flex: 100,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
    },
    { field: 'managerName', headerName: 'Menedzser', flex: 100 },
    { field: 'description', headerName: 'Leírás', flex: 200 },
    {
      field: 'status',
      headerName: 'Státusz',
      valueFormatter(params) {
        return params.value === ProjectStatuses.New
          ? 'Új'
          : params.value === ProjectStatuses.Active
          ? 'Aktív'
          : params.value === ProjectStatuses.Closed
          ? 'Lezárt'
          : params.value === ProjectStatuses.Suspended
          ? 'Felfüggesztett'
          : params.value === ProjectStatuses.Resolved
          ? 'Kész'
          : params.value === ProjectStatuses.Cancelled
          ? 'Törölt'
          : '';
      },
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    projectService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Projektek{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12} md={checkPermission(['ProjectCreate']) ? 10 : 12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!checkPermission(['ProjectViewAll'])}
                checked={checkboxValue}
                onChange={(e, checked) => {
                  setCheckboxValue(checked);
                }}
              />
            }
            label={'Saját adatok'}
          />
        </Grid>
        {checkPermission(['ProjectCreate']) && (
          <Grid container item xs={2} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Projekt létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/projects/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'ProjectPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.Project}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'ProjectPage'}
        />
      </Grid>
    </Paper>
  );
};

export default ProjectPage;
