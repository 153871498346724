import axios from 'axios';
import { postData } from '../axiosUtils';

const orderService = {
  list: (
    clientId: any = null,
    filterDateByPaid: boolean = false,
    filterDateByCreation: boolean = true,
    filterDateByFullfilment: boolean = false,
    startDate: any | null = null,
    endDate: any | null = null,
    hasNoNullCompletedOrder: boolean = true,
    isDeleted: boolean = false,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/order/list?isDeleted=${isDeleted}`;
    if (clientId !== null) {
      url += `&clientId=${clientId}`;
    }
    if (startDate) {
      url += `&startDate=${startDate?.toISOString()}`;
    }
    if (endDate) {
      url += `&endDate=${endDate?.toISOString()}`;
    }
    url += `&filterDateByPaid=${filterDateByPaid}`;

    url += `&filterDateByCreation=${filterDateByCreation}`;
    url += `&filterDateByFullfilment=${filterDateByFullfilment}`;
    url += `&hasNoNullCompletedOrder=${hasNoNullCompletedOrder}`;

    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  listDeleted: (signal: AbortSignal = new AbortController().signal) => {
    return axios.get(`/api/order/listDeleted`, { signal }).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/order/details/${id}`).then((response) => {
      return response.data;
    });
  },
  sendEmail: (entity: any) => {
    return postData({
      url: `/api/order/sendEmail`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  save: (entity: any) => {
    let url = `/api/order/create`;
    if (entity.id && entity.id > 0) {
      url = '/api/order/update';
    }
    return postData({
      url: url,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  addInvoice: (entity: any) => {
    let url = `/api/order/addInvoice`;
    return postData({
      url: url,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  delete: (
    id: any,
    deleteLogically: any,
    cancellationReason: string | null = null
  ) => {
    let url = `/api/order/delete/${id}/${deleteLogically}?cancellationReason=${
      cancellationReason ?? ''
    }`;
    return postData({
      url: url,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },

  recover: (id: any) => {
    return postData({
      url: `/api/order/recover/${id}`,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },

  approvePayment: (entity: any) => {
    return postData({
      url: `/api/order/approvePayment`,
      method: 'POST',
      data: entity,
    }).then((response) => {
      return response;
    });
  },

  getConversionRate: (fromCurrency: any, toCurrency: any) => {
    return axios
      .post(
        `/api/order/getConversionRate?fromCurrency=${fromCurrency}&toCurrency=${toCurrency}`
      )
      .then((response) => {
        return response.data;
      });
  },
};

export default orderService;
