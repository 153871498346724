import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, IconButton, Grid, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  Sync,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import serviceService from '../../../services/erp/serviceService';
import { formatCurrency } from '../../../utils/valueFormatters';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';
import { ChimneyType } from '../../chimneySweeping/chimneyTypes/ChimneyTypePage';
import clientService from '../../../services/crm/clientService';
import billingoService from '../../../services/billingoService';

export interface Service {
  id: string;
  serviceCategoryId: number;
  servicePriceNet: number;
  serviceCategoryName: string;
  description: string;
}

const ServicesPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('ServicePage.title');

  const { checkPermission } = useCheckPermission();

  const getData = () => {
    dispatch({ type: 'SHOW_QUERY' });
    serviceService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  useEffect(() => {
    getData();
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly = !checkPermission(['ServiceEdit']);
    actions.push(
      <EntityNavigator
        entityType={EntityTypes.Service}
        entityId={params.row.id}
        value={readonly ? 'Megtekintés' : 'Szerkesztés'}
        showIcon
        readonly={readonly}
      />
    );

    if (checkPermission(['ServiceDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Megnevezés', flex: 100 },
    { field: 'serviceCategoryName', headerName: 'Kategoria', flex: 100 },

    {
      field: 'servicePriceNet',
      headerName: 'Nettó díj',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'acquisitionPriceNet',
      headerName: 'Nettó beszerzési ár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    { field: 'unitOfQuantity', headerName: 'Egység', flex: 100 },
    {
      field: 'actions',
      type: 'actions',
      flex: 50,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    serviceService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Szolgáltatások {titleDescriptor}</h2>
        </Grid>
        <Grid container item xs={12} p={2} justifyContent="end">
          {checkPermission(['BillingoServiceSync']) && (
            <Tooltip title="Billingo termékek szinkronizálása">
              <Grid item>
                <IconButton
                  onClick={() => {
                    dispatch({ type: 'SHOW_QUERY' });
                    billingoService
                      .syncServices()
                      .then((e) => {
                        enqueueSnackbar('Sikeres szinkronizáció!', {
                          variant: 'success',
                        });
                        getData();
                      })
                      .catch((e) => {
                        enqueueSnackbar('Sikertelen szinkronizáció!', {
                          variant: 'error',
                        });
                      });
                  }}
                  color="primary"
                >
                  <Sync />
                </IconButton>
              </Grid>
            </Tooltip>
          )}
          {checkPermission(['ServiceCreate']) && (
            <Grid item>
              <Tooltip title="Szolgáltatás létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/services/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'ServicePage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default ServicesPage;
