import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  TextField,
  Autocomplete,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import cashLogService from '../../../services/billing/cashLogService';
import { formatCurrency } from '../../../utils/valueFormatters';
import moment from 'moment';
import cashRegisterService from '../../../services/billing/cashRegisterService';
import { set, parseJSON } from 'date-fns';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { DatePicker } from '@mui/x-date-pickers';
import CashLogVerifyDialog from './CashLogVerifyDialog';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';
import '../../../css/dataGrid.css';

export interface CashLog {
  id: string;
  name: string;
  description: string;
}

const CashLogsPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('CashLogsPage.title');
  const [incomeCount, setIncomeCount] = useState<number>(0);
  const [expenseCount, setExpenseCount] = useState<number>(0);
  const [closingCashTotal, setClosingCashTotal] = useState<number>(0);
  const [intervalIncomeTotal, setIntervalIncomeTotal] = useState<number>(0);
  const [intervalExpenseTotal, setIntervalExpenseTotal] = useState<number>(0);
  const user = useSelector((state: any) => state.user.userInfo);
  const localRegisterId = useLocalStorage('CashLogCreateRegisterId');
  const [query, setQuery] = useState<any>({
    startDate: moment(new Date()).startOf('year').toDate(),
    endDate: moment(new Date()).endOf('year').toDate(),
  });
  const [registers, setRegisters] = useState<any[]>([]);
  const [cashLogVerifyDialog, setCashLogVerifyDialog] = useState<any>({
    open: false,
    entity: null,
  });
  const [lastSerialNumber, setLastSerialNumber] =
    useState<string>('P2/00000000');

  const { checkPermission } = useCheckPermission();

  const getNumberFromSerialNumber = (serialNumber: string) => {
    var result = parseInt(serialNumber.split('/')[1]);
    return result;
  };

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    if (localRegisterId.value === null) {
      return;
    }
    cashLogService
      .list(
        query.startDate.toISOString(),
        query.endDate.toISOString(),
        localRegisterId.value
      )
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setRows(response.records);
          setIncomeCount(0);
          setExpenseCount(0);
          setClosingCashTotal(0);
          setIntervalIncomeTotal(0);
          setIntervalExpenseTotal(0);
          let localLastSerialNumber = response.records[0]?.serialNumber ?? '';
          setLastSerialNumber(localLastSerialNumber);
          response.records.map((element) => {
            if (
              getNumberFromSerialNumber(element.serialNumber) >
              getNumberFromSerialNumber(localLastSerialNumber)
            ) {
              setLastSerialNumber(element.serialNumber);
              localLastSerialNumber = element.serialNumber;
            }
            if (element.incomeGross > 0) {
              setIncomeCount((prev) => prev + 1);
            } else {
              setExpenseCount((prev) => prev + 1);
            }
            setIntervalIncomeTotal((prev) => prev + element?.incomeGross ?? 0);
            setIntervalExpenseTotal(
              (prev) => prev + element?.expenseGross ?? 0
            );
          });
          setClosingCashTotal(response.records[0]?.closingCashGross ?? 0);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [query, localRegisterId.value]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    cashRegisterService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setRegisters(
            response.records.filter((x) => x.userId === user?.userId)
          );
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (
      checkPermission(['VerifyCashLog']) &&
      !params.row.isVerified &&
      params.row.serialNumber === lastSerialNumber
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Ellenőrzés">
              <CheckCircleIcon />
            </Tooltip>
          }
          label="Ellenőrzés"
          onClick={() =>
            setCashLogVerifyDialog({ open: true, entity: params.row })
          }
        />
      );
    }

    let readonly =
      !checkPermission(['EditCashLog']) ||
      user?.userId !==
        registers?.find((x) => x.id === parseInt(localRegisterId.value))
          ?.userId;
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/billing/cashLogs/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['DeleteCashLog'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'serialNumber', headerName: 'Sorszám', flex: 100 },
    {
      field: 'createdAt',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleDateString();
      },
    },
    { field: 'receiptNumber', headerName: 'Biz.Szám', flex: 100 },
    {
      field: 'clientNameForQuickSearch',
      headerName: 'ÜgyfélKereső',
      flex: 100,
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'clientName',
      headerName: 'Ügyfél',
      flex: 100,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
    },
    { field: 'userName', headerName: 'Dolgozó', flex: 100 },
    { field: 'description', headerName: 'Leírás', flex: 300 },
    {
      field: 'incomeGross',
      headerName: 'Bevétel',
      flex: 100,
      valueFormatter(params) {
        return formatCurrency(params.value);
      },
    },
    {
      field: 'expenseGross',
      headerName: 'Kiadás',
      flex: 100,
      valueFormatter(params) {
        return formatCurrency(params.value);
      },
    },
    {
      field: 'closingCashGross',
      headerName: 'Záró e.',
      flex: 100,
      valueFormatter(params) {
        return formatCurrency(params.value);
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 150,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    cashLogService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Házipénztár{titleDescriptor}</h2>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} pb={2}>
            <Autocomplete
              disablePortal
              id="registerId"
              value={parseInt(localRegisterId.value)}
              onChange={(event, value) => {
                localRegisterId.setLocalStorageValue(value);
              }}
              getOptionLabel={(option) => {
                var found = registers.find((g) => g.id === option);
                if (found) {
                  return `${found.id}.${found.name}`;
                } else return '';
              }}
              options={registers.map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} label="Pénztár" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} md={'auto'} textAlign={'center'}>
            <DatePicker
              value={query.startDate}
              onChange={(value) => {
                setQuery({
                  ...query,
                  startDate: value,
                });
              }}
              label="Kezdő dátum"
              inputFormat="yyyy-MM-dd"
              componentsProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={'auto'} textAlign={'center'}>
            <DatePicker
              value={query.endDate}
              onChange={(value) => {
                setQuery({
                  ...query,
                  endDate: value,
                });
              }}
              label="Vég dátum"
              inputFormat="yyyy-MM-dd"
              componentsProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>

        {checkPermission(['CreateCashLog']) &&
          user?.userId ===
            registers?.find((x) => x.id === parseInt(localRegisterId.value))
              ?.userId && (
            <Grid container item xs={12} p={2} justifyContent="end">
              <Grid item>
                <Tooltip title="Házipénztár log létrehozása">
                  <IconButton
                    component={RouterLink}
                    to={`/billing/cashLogs/create`}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            getRowClassName={(params) => {
              return params.row.isVerified ? 'verified' : '';
            }}
            localStorageKey={'CashLogsPage'}
          />
        </Grid>
        <Grid item xs={12}>
          Bevételi bizonylat: {incomeCount} db
        </Grid>
        <Grid item xs={12}>
          Kiadási bizonylat: {expenseCount} db
        </Grid>
        <Grid item xs={12}>
          Időszaki forgalom(Bevétel): {formatCurrency(intervalIncomeTotal)}
        </Grid>
        <Grid item xs={12}>
          Időszaki forgalom(Kiadás): {formatCurrency(intervalExpenseTotal)}
        </Grid>
        <Grid item xs={12}>
          Záró egyenleg: {formatCurrency(closingCashTotal)}
        </Grid>
        <ConfirmDeleteDialog />
        <CashLogVerifyDialog
          open={cashLogVerifyDialog.open}
          entity={cashLogVerifyDialog.entity}
          onClose={() => setCashLogVerifyDialog({ open: false, entity: null })}
          onVerified={(entity) => {
            setRows(
              rows.map((row) => {
                if (row.id === entity.id) {
                  return entity;
                } else return row;
              })
            );
          }}
        />
      </Grid>
    </Paper>
  );
};

export default CashLogsPage;
