import { Edit as EditIcon } from '@mui/icons-material';
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DatePickerHeader from '../../../../components/DatePickerHeader';
import EntityNavigator from '../../../../components/EntityNavigator';
import FilePageDialog from '../../../../components/FilePageDialog';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../../hooks/useFunctionDescriptor';
import clientService from '../../../../services/crm/clientService';
import { AddressTypes } from '../../../../types/AddressTypes';
import { AttachmentTypes } from '../../../../types/AttachmentTypes';
import { ClientTaxType } from '../../../../types/ClientTaxType';
import { ClientTypes } from '../../../../types/ClientTypes';
import { EntityTypes } from '../../../../types/EntityTypes';
import AcquisitionsPage from '../../../acquisitions/AcquisitionPage';
import ChimneySweepingCertificatePage from '../../../chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificatePage';
import DecisionPage from '../../../chimneySweeping/decisions/DecisionPage';
import AddressPage from '../../../erp/addresses/AddressPage';
import WorkItemPage from '../../../erp/workItems/WorkItemPage';
import ContractPage from '../../../hr/contracts/ContractPage';
import ClientProfileTabDataList from './ClientProfileDataLists/ClientProfileTabDataList';
import ClientProfilePage from './ClientProfilePage';

const ClientProfileTabPage = (props: any) => {
  const { clientId = null, isDialog = false } = props;
  const params = useParams();
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor('ClientProfileTabPage.title');
  const [tabValue, setTabValue] = React.useState(0);
  const { checkPermission } = useCheckPermission();
  const [dateTabValue, setDateTabValue] = React.useState<any>(2);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
    entityType: null,
  });
  const [isGross, setIsGross] = useState<boolean>(true);
  const [now, setNow] = useState<any>(new Date());
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: now,
    endDate: now,
  });
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const id = clientId !== null ? clientId : params.id;
  const [client, setClient] = useState<any>({
    client: {
      name: {
        firstName: '',
        lastName: '',
        middleName: '',
        title: '',
      },
      clientType: ClientTypes.Customer,
      companyName: '',
      customerLedgerNumber: '',
      bankName: '',
      bankAccountNumber: '',
      iban: '',
      swift: '',
      clientTaxType: ClientTaxType.HasTaxNumber,
      contact: {
        phone: '',
        webAddress: '',
        email: '',
        contactName: '',
        address: {
          postalCode: 0,
          type: AddressTypes.Site,
          city: '',
          street: '',
          houseNumber: '',
          country: '',
          district: '',
          comment: '',
          hasPerson: false,
          personName: {
            firstName: '',
            lastName: '',
            middleName: '',
            title: '',
          },
          personPhone: '',
          personEmail: '',
        },
      },
      representativeName: { firstName: '', lastName: '' },
      representativePhone: '',
      addresses: [],
      isPrivatePerson: true,
      taxNumber: '',
      comment: '',
    },
    workItems: [],
    debts: [],
    orders: [],
    expenses: [],
    contracts: [],
    decisions: [],
    chimneySweepingCertificates: [],
    paidExpensesPrice: 0,
    dueExpensesPrice: 0,
    outStandingExpensesPrice: 0,
    paidExpensesPriceNet: 0,
    dueExpensesPriceNet: 0,
    outStandingExpensesPriceNet: 0,
    paidExpenses: [],
    dueExpenses: [],
    outStandingExpenses: [],
    paidOrders: [],
    dueOrders: [],
    outStandingOrders: [],
    acquisitions: [],
    paidOrdersPrice: 0,
    dueOrdersPrice: 0,
    outStandingOrdersPrice: 0,
    paidOrdersPriceNet: 0,
    dueOrdersPriceNet: 0,
    outStandingOrdersPriceNet: 0,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (id) {
      let abort = new AbortController();
      dispatch({ type: 'SHOW_QUERY' });
      clientService
        .profile(
          id,
          selectedInterval?.startDate,
          selectedInterval?.endDate,
          abort.signal
        )
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setClient(response.result);
          }
          dispatch({ type: 'HIDE' });
        })
        .finally(() => {});

      return () => {
        abort.abort();
      };
    }
  }, [clientId, params.id, selectedInterval]);

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item container xs={12} justifyContent={'space-between'}>
          <Grid item textAlign={{ xs: 'center', sm: 'left' }}>
            <h2>
              {client.client.isPrivatePerson
                ? client.client.name.fullName
                : client.client.companyName}
              {titleDescriptor}
            </h2>
          </Grid>
          {checkPermission(['ClientEdit']) && (
            <Grid item>
              {isDialog ? (
                <EntityNavigator
                  entityType={EntityTypes.ClientEdit}
                  entityId={id}
                  value={'Szerkesztés'}
                  showIcon
                />
              ) : (
                <Tooltip title="Szerkesztés">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      navigate(`/crm/clients/edit/${id}`);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <DatePickerHeader
              selectedInterval={selectedInterval}
              setSelectedInterval={setSelectedInterval}
              tabValue={dateTabValue}
              setTabValue={setDateTabValue}
              netGrossPicker={true}
              isGross={isGross}
              setIsGross={setIsGross}
              localStorageKey="ClientProfilePage"
              setNullSelectedInterval={true}
            />
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab value={0} label="Profil" sx={{ fontWeight: 'bold' }} />
                <Tab
                  value={1}
                  label={
                    <Badge
                      badgeContent={client.expenses.length}
                      color="error"
                      sx={{ marginRight: '1rem' }}
                    >
                      <Box
                        alignContent={'center'}
                        sx={{ marginRight: '0.5rem' }}
                      >
                        Kiadások{' '}
                      </Box>
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
                {checkPermission(['ChimneySweepingCertificateView']) && (
                  <Tab
                    value={2}
                    label={
                      <Badge
                        badgeContent={client.chimneySweepingCertificates.length}
                        color="error"
                        sx={{ marginRight: '1rem' }}
                      >
                        <Box
                          alignContent={'center'}
                          sx={{ marginRight: '0.5rem' }}
                        >
                          Kéményseprő-tanúsítványok{' '}
                        </Box>
                      </Badge>
                    }
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['ContractView']) && (
                  <Tab
                    value={3}
                    label={
                      <Badge
                        badgeContent={client.contracts.length}
                        color="error"
                        sx={{ marginRight: '1rem' }}
                      >
                        <Box
                          alignContent={'center'}
                          sx={{ marginRight: '0.5rem' }}
                        >
                          Szerződések{' '}
                        </Box>
                      </Badge>
                    }
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['DecisionView']) && (
                  <Tab
                    value={4}
                    label={
                      <Badge
                        badgeContent={client.decisions.length}
                        color="error"
                        sx={{ marginRight: '1rem' }}
                      >
                        <Box
                          alignContent={'center'}
                          sx={{ marginRight: '0.5rem' }}
                        >
                          Határozatok{' '}
                        </Box>
                      </Badge>
                    }
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['WorkItemView']) && (
                  <Tab
                    value={5}
                    label={
                      <Badge
                        badgeContent={client.workItems.length}
                        color="error"
                        sx={{ marginRight: '1rem' }}
                      >
                        <Box
                          alignContent={'center'}
                          sx={{ marginRight: '0.5rem' }}
                        >
                          Munkalapok{' '}
                        </Box>
                      </Badge>
                    }
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                <Tab
                  value={6}
                  label={
                    <Badge
                      badgeContent={client.client.addresses.length}
                      color="error"
                      sx={{ marginRight: '1rem' }}
                    >
                      <Box
                        alignContent={'center'}
                        sx={{ marginRight: '0.5rem' }}
                      >
                        Címek{' '}
                      </Box>
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={7}
                  label={
                    <Badge
                      badgeContent={client.acquisitions.length}
                      color="error"
                      sx={{ marginRight: '1rem' }}
                    >
                      <Box
                        alignContent={'center'}
                        sx={{ marginRight: '0.5rem' }}
                      >
                        Beszerzések{' '}
                      </Box>
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
              </Tabs>
            </Box>
            {tabValue === 0 && (
              <ClientProfilePage
                client={client}
                selectedInterval={selectedInterval}
                setSelectedInterval={setSelectedInterval}
                isGross={isGross}
              />
            )}
            {tabValue === 1 && (
              <ClientProfileTabDataList
                entityType={EntityTypes.Expense}
                paidRows={client.paidExpenses}
                outstandingRows={client.outStandingExpenses}
                DueRows={client.dueExpenses}
              />
            )}
            {tabValue === 2 &&
              checkPermission(['ChimneySweepingCertificateView']) && (
                <ChimneySweepingCertificatePage
                  externalRows={client.chimneySweepingCertificates}
                />
              )}
            {tabValue === 3 && checkPermission(['ContractView']) && (
              <ContractPage externalRows={client.contracts} />
            )}
            {tabValue === 4 && checkPermission(['DecisionView']) && (
              <DecisionPage externalRows={client.decisions} />
            )}
            {tabValue === 5 && checkPermission(['WorkItemView']) && (
              <WorkItemPage externalRows={client.workItems} />
            )}
            {tabValue === 6 && (
              <AddressPage externalRows={client.client.addresses} />
            )}
            {tabValue === 7 && checkPermission(['AcquisitionView']) && (
              <AcquisitionsPage externalRows={client.acquisitions} />
            )}
          </Box>
        </Grid>
        <FilePageDialog
          onList={(length) => {}}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={filePageDialogOpen.entityType as AttachmentTypes}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'ClientProfileTabPageFileDialog'}
        />
      </Grid>
    </Paper>
  );
};

export default ClientProfileTabPage;
