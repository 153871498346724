import { OpenInBrowser, Visibility } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import '../css/dataGrid.css';
import { formatCurrency } from '../utils/valueFormatters';
import DataList from './DataList';
import OrderPDFDialog from './OrderPDFDialog';
import orderService from '../services/sales/orderService';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { parseJSON } from 'date-fns';

export default function SelectOrderIdDialog(props: any) {
  const { open, onClose, onSelected, orders, clientId, orderId } = props;
  const [selectedOrder, setSelectedOrder] = useState<any>({
    id: 0,
  });
  const [orderPDFDialog, setOrderPDFDialog] = useState<any>({
    open: false,
    orderId: 0,
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [internalOrders, setInternalOrders] = useState<any>([]);

  const handleClose = () => {
    setSelectedOrder({ id: 0 });
    onClose();
  };

  useEffect(() => {
    if (orderId) {
      setSelectedOrder({
        id: orderId,
      });
    }
  }, [orderId]);

  useEffect(() => {
    if (!orders && clientId) {
      dispatch({ type: 'SHOW_QUERY' });
      orderService
        .list(clientId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setInternalOrders(response.records);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    } else {
      setInternalOrders(orders);
    }
  }, [clientId, orders]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <Button
        variant="contained"
        color={color ? color : 'primary'}
        onClick={() => {
          setSelectedOrder({
            id: params.row.id,
          });
        }}
        sx={{ padding: '0px 10px' }}
      >
        Kiválasztás
      </Button>
    );

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Vevői fiók megnyitása">
            <OpenInBrowser />
          </Tooltip>
        }
        label="Vevői fiók megnyitásas"
        disabled={!params.row.invoiceUrl}
        onClick={() => window.open(params.row.invoiceUrl, '_blank')}
      />
    );

    let tooltipTitle = '';

    if (params.row?.services?.length > 0) {
      params.row?.services?.forEach((service: any) => {
        tooltipTitle +=
          service.name +
          ':' +
          ' ' +
          formatCurrency(service.servicePriceGross * service.quantity) +
          '\n';
      });
    }
    if (params.row?.items?.length > 0) {
      params.row?.items?.forEach((item: any) => {
        tooltipTitle +=
          item.itemName + ':' + ' ' + item.sellPriceGross * item.amount + '\n';
      });
    }

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line', fontSize: '15px' }}>
                {tooltipTitle}
              </div>
            }
          >
            <Visibility />
          </Tooltip>
        }
        label={'Megtekintés'}
        onClick={() => {
          setOrderPDFDialog({
            open: true,
            orderId: params.row.id,
          });
        }}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'clientName',
      headerName: 'Ügyfél',
      flex: 200,
    },
    {
      field: 'invoiceNumber',
      headerName: 'Számlaszám',
      flex: 200,
    },
    {
      field: 'date',
      headerName: 'Dátum',
      valueFormatter(params) {
        return parseJSON(params.value).toLocaleDateString();
      },
      flex: 200,
    },
    {
      field: 'finalPrice',
      headerName: 'Összeg',
      valueFormatter(params) {
        return formatCurrency(params.value);
      },
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth={'lg'}>
      <DialogTitle>Válassza ki a tanúsítványhoz tartozó számlát!</DialogTitle>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <DataList
            rows={internalOrders || []}
            columns={columns}
            getRowId={(row) => row.id}
            minimal
            getRowClassName={(params) => {
              return params.row.id === selectedOrder.id ? 'verified' : '';
            }}
            localStorageKey={'SelectOrderIdDialog'}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'inherit',
              },
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!(selectedOrder.id > 0)}
          onClick={() => {
            onSelected(selectedOrder);
            handleClose();
          }}
        >
          Véglegesítés
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleClose()}
        >
          Mégse
        </Button>
      </DialogActions>
      <OrderPDFDialog
        open={orderPDFDialog.open}
        onClose={() => setOrderPDFDialog({ open: false, orderId: 0 })}
        orderId={orderPDFDialog.orderId}
      />
    </Dialog>
  );
}
