export enum DocumentBlockTypes {
  Invoice = 1,
  Decision = 2,
  Contract = 3,
  Offer = 4,
  Project = 5,
  Certificate_of_completion = 6,
  Dossier = 7,
  Order = 8,
  Receipt = 9,
  Waybill = 10,
}
