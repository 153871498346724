import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setForm } from '../../../../../stateManagement/actions/workItemActions';
import SelectItems from '../../../../wms/items/components/SelectItems';
import useCheckPermission from '../../../../../hooks/useCheckPermission';

const SelectItemsStep = (props: any) => {
  const {
    values,
    setFieldValue,
    listPrices,
    readonly,
    loadItemPrices,
    priceCategories,
    taxes,
  } = props;
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setForm(values));
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [values]);
  return (
    <SelectItems
      values={values}
      taxes={taxes}
      setFieldValue={setFieldValue}
      listPrices={listPrices}
      readOnly={readonly}
      priceCategories={priceCategories}
      loadItemPrices={(a, b) => loadItemPrices(a, b, setFieldValue)}
      isOffer={false}
      canOverrideGrossPrice={checkPermission(['WorkItemCustomPrice'])}
    />
  );
};

export default SelectItemsStep;
