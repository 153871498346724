import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Autocomplete, InputAdornment } from '@mui/material';
import ItemCreate from '../wms/items/ItemCreate';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import ServiceCreate from '../erp/services/ServiceCreate';
import useCheckPermission from '../../hooks/useCheckPermission';
import { useEffect } from 'react';

export default function SelectServiceDialog(props: any) {
  const {
    open,
    setOpen,
    addToCart,
    services,
    taxes,
    externalTaxTypeId = null,
    serviceToEdit = null,
  } = props;
  const { checkPermission } = useCheckPermission();
  const [service, setService] = React.useState({
    id: 0,
    name: '',
    description: '',
    servicePriceNet: 0,
    servicePriceGross: 0,
    serviceCategoryId: 0,
    unitOfQuantity: '',
    amount: 0,
    taxTypeId: taxes?.[0]?.id ?? 1,
    discount: 0,
    comment: '',
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (serviceToEdit !== null) {
      setService(serviceToEdit);
    }
  }, [serviceToEdit]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Szolgáltatás felvétele</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ paddingTop: 5 }}>
          <Grid item xs={12}>
            <CreateEntityDecorator
              hideAdd={!checkPermission(['ServiceCreate'])}
              AutocompleteComponent={
                <Autocomplete
                  disablePortal
                  id="service"
                  disabled={serviceToEdit !== null}
                  value={service}
                  onChange={(event, value: any) => {
                    setService({
                      ...value,
                      amount: 1,
                      discount: 0,
                      taxTypeId:
                        externalTaxTypeId === null
                          ? taxes?.[0]?.id ?? 0
                          : externalTaxTypeId,
                      servicePriceGross:
                        services?.find((g) => g.id === value.id)
                          ?.servicePriceNet *
                        (1 +
                          (externalTaxTypeId == null
                            ? taxes[0]?.value ?? 27
                            : taxes.find((g) => g.id === externalTaxTypeId)
                                ?.value ?? 27) /
                            100),
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => {
                    var service = services?.find((g) => g.id === option.id);
                    if (service == null || service === undefined) {
                      return '';
                    }

                    return `${service.name}`;
                  }}
                  options={services}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Szolgáltatás"
                      fullWidth
                    />
                  )}
                />
              }
              onSave={(service) => {
                setService({
                  ...service,
                  amount: 1,
                  discount: 0,
                  servicePriceGross:
                    service.servicePriceNet *
                    (1 +
                      (externalTaxTypeId == null
                        ? taxes[0]?.value ?? 27
                        : taxes.find((g) => g.id === externalTaxTypeId)
                            ?.value ?? 27) /
                        100),
                });
              }}
              CreateEntityComponent={ServiceCreate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={service.amount}
              onChange={(event) => {
                let amount = parseFloat(event.target.value);
                setService({ ...service, amount: amount });
              }}
              label="Egység"
              disabled={service.id === 0}
              name="amount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {service.unitOfQuantity}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={service.servicePriceNet}
              onChange={(event) => {
                let net = parseFloat(event.target.value);
                setService({
                  ...service,
                  servicePriceNet: net,
                  servicePriceGross:
                    net *
                    (1 +
                      (externalTaxTypeId == null
                        ? taxes?.find((g) => g.id === service.taxTypeId)
                            ?.value ?? 27
                        : taxes.find((g) => g.id === externalTaxTypeId)
                            ?.value ?? 27) /
                        100),
                });
              }}
              label="Nettó ár"
              disabled={service.id === 0}
              name="amount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Ft</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={service.servicePriceGross}
              onChange={(event) => {
                let gross = parseFloat(event.target.value);
                setService({
                  ...service,
                  servicePriceGross: gross,
                  servicePriceNet:
                    gross /
                    (1 +
                      (externalTaxTypeId == null
                        ? taxes?.find((g) => g.id === service.taxTypeId)
                            ?.value ?? 27
                        : taxes.find((g) => g.id === externalTaxTypeId)
                            ?.value ?? 27) /
                        100),
                });
              }}
              label="Bruttó ár"
              disabled={service.id === 0}
              name="amount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Ft</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="taxTypeId"
              disabled={service.id === 0 || externalTaxTypeId !== null}
              value={service.taxTypeId ?? ''}
              onChange={(event, value: number) => {
                setService({
                  ...service,
                  taxTypeId: value,
                  servicePriceGross:
                    service.servicePriceNet *
                    (1 +
                      (taxes?.find((g) => g.id === value)?.value ?? 27) / 100),
                });
              }}
              getOptionLabel={(option) =>
                taxes?.find((g) => g.id === option)?.name ?? ''
              }
              options={taxes?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="ÁFA típusa" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={service.discount}
              onChange={(event) => {
                let discount = parseFloat(event.target.value);
                if (discount >= 0 && discount <= 100) {
                  setService({ ...service, discount: discount });
                }
              }}
              label="Kedvezmény"
              disabled={service.id === 0}
              name="discount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={service.comment}
              onChange={(event) => {
                setService({ ...service, comment: event.target.value });
              }}
              label="Megjegyzés"
              disabled={service.id === 0}
              name="comment"
              multiline
              minRows={2}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (service.id !== 0) {
              addToCart(service);
              setService({
                id: 0,
                name: '',
                description: '',
                servicePriceNet: 0,
                servicePriceGross: 0,
                serviceCategoryId: 0,
                unitOfQuantity: '',
                amount: 0,
                discount: 0,
                taxTypeId: taxes?.[0]?.id ?? 0,
                comment: '',
              });
            }
          }}
        >
          Hozzáadás
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
