import { Box, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import clientService from '../../services/crm/clientService';
import DatePickerHeader from '../DatePickerHeader';
import ClientsPage from '../../pages/crm/clients/ClientsPage';
import ItemPage from '../../pages/wms/items/ItemPage';
import OfferPage from '../../pages/erp/offers/OfferPage';
import useCheckPermission from '../../hooks/useCheckPermission';
import WorkItemsPage from '../../pages/erp/workItems/WorkItemPage';
import OrdersPage from '../../pages/sales/orders/OrderPage';

const SmartSearchTabPage = (props: any) => {
  const params = useParams();
  const [tabValue, setTabValue] = React.useState(0);
  const [dateTabValue, setDateTabValue] = React.useState<any>(2);
  const [isGross, setIsGross] = useState<boolean>(true);
  const [now, setNow] = useState<any>(new Date());
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: now,
    endDate: now,
  });
  const dispatch = useDispatch();
  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const [clients, setClients] = useState<any>([]);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(false, null, abort.signal)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setClients(response.records);
        }
        dispatch({ type: 'HIDE' });
      })
      .finally(() => {});

    return () => {
      abort.abort();
    };
  }, []);

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            {/* {tabValue !== 0 && (
              <DatePickerHeader
                selectedInterval={selectedInterval}
                setSelectedInterval={setSelectedInterval}
                tabValue={dateTabValue}
                setTabValue={setDateTabValue}
                netGrossPicker={true}
                isGross={isGross}
                setIsGross={setIsGross}
                localStorageKey="SmartSearchTabPage"
                setNullSelectedInterval={true}
              />
            )} */}
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 0,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                {checkPermission(['ClientView']) && (
                  <Tab value={0} label="Ügyfél" sx={{ fontWeight: 'bold' }} />
                )}
                {checkPermission(['ItemView']) && (
                  <Tab value={1} label="Termék" sx={{ fontWeight: 'bold' }} />
                )}
                {checkPermission(['OfferView']) && (
                  <Tab
                    value={2}
                    label="Árajánlat"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['WorkItemView']) && (
                  <Tab value={3} label="Munkalap" sx={{ fontWeight: 'bold' }} />
                )}
                {checkPermission(['OrderView']) && (
                  <Tab
                    value={4}
                    label="Megrendelés"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
              </Tabs>
            </Box>
            {checkPermission(['ClientView']) && tabValue === 0 && (
              <ClientsPage isSmartSearch={true} />
            )}
            {checkPermission(['ItemView']) && tabValue === 1 && (
              <ItemPage isSmartSearch={true} />
            )}
            {checkPermission(['OfferView']) && tabValue === 2 && (
              <OfferPage isSmartSearch={true} />
            )}
            {checkPermission(['WorkItemView']) && tabValue === 3 && (
              <WorkItemsPage isSmartSearch={true} />
            )}
            {checkPermission(['OrderView']) && tabValue === 4 && (
              <OrdersPage isSmartSearch={true} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SmartSearchTabPage;
