import { Delete, Info, Print, Warning } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Autocomplete,
  Tooltip,
  Divider,
} from '@mui/material';
import usePrintComponent from '../../hooks/usePrintComponent';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import { useEffect, useState } from 'react';
import AcquisitionHistoryDialog from '../pricing/acquisitionPrices/AcquisitionHistoryDialog';
import moment from 'moment';
var Barcode = require('react-barcode');

const AcquisitionItem = (props) => {
  const {
    values,
    items,
    onDelete,
    setFieldValue,
    index,
    disabled,
    errors,
    touched,
    taxes,
    setOpenDialog,
    itemStocks,
  } = props;

  const { printRef, handlePrint } = usePrintComponent();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [hasBeenCheaper, setHasBeenCheaper] = useState<boolean>(false);
  const acquisition = values.acquisitions[index];

  useEffect(() => {
    if (itemStocks && acquisition) {
      var now = new Date();
      var acquisitionHistory = itemStocks?.find(
        (x) => x.itemId === acquisition?.itemId
      )?.acquisitionHistory;
      if (
        acquisitionHistory?.length > 0 &&
        acquisitionHistory?.some(
          (x) =>
            x.unitPrice < parseFloat(acquisition.unitPrice) &&
            x.clientId !== values.clientId &&
            moment(x.acquisitionDate).isAfter(moment(now).subtract(1, 'year'))
        )
      ) {
        setHasBeenCheaper(true);
      } else {
        setHasBeenCheaper(false);
      }
    }
  }, [itemStocks, acquisition, values.clientId]);

  if (!acquisition) {
    return null;
  }

  const item = items.find((g) => g.id === values.acquisitions[index].itemId);
  if (!item) {
    return null;
  }

  return (
    <>
      <Grid item container xs={6} style={{ display: 'none' }}>
        <Grid item container display="inline-block" ref={printRef}>
          <Grid item display={'inline-block'} xs={12}>
            {item.name.toUpperCase().slice(0, 27)}
          </Grid>
          <Grid item style={{ height: 0 }} xs={12}></Grid>
          <Grid item xs={12}>
            {item.barcode && <Barcode value={item.barcode} height="40" />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          disablePortal
          id="itemId"
          value={acquisition?.itemId ?? ''}
          disabled
          getOptionLabel={(option) => {
            var item = items.find((g) => g.id === option);
            if (item === null || item === undefined) {
              return '';
            }

            return `${item.name} (${
              item.externalItemNumber ?? item.itemNumber
            })`;
          }}
          options={items.map((g) => g.id)}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Termék" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          size="small"
          fullWidth
          value={values.acquisitions[index]?.externalItemNumber ?? ''}
          disabled={disabled}
          onChange={(event) => {
            setFieldValue(
              `acquisitions[${index}].externalItemNumber`,
              event.target.value
            );
          }}
          required
          error={!!touched?.externalItemNumber && !!errors?.externalItemNumber}
          helperText={
            !!touched?.externalItemNumber &&
            !!errors?.externalItemNumber &&
            (errors?.externalItemNumber as string)
          }
          label="Beszállítói cikkszám"
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          size="small"
          label="Nettó egységár"
          required
          disabled={disabled}
          type="number"
          onChange={(event) => {
            setFieldValue(
              `acquisitions[${index}].unitPrice`,
              event.target.value
            );
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">HUF</InputAdornment>,
          }}
          fullWidth
          error={!!touched?.unitPrice && !!errors?.unitPrice}
          helperText={
            !!touched?.unitPrice &&
            !!errors?.unitPrice &&
            (errors?.unitPrice as string)
          }
          value={values.acquisitions[index]?.unitPrice ?? ''}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <TextField
          size="small"
          type="number"
          fullWidth
          disabled={disabled}
          onChange={(event) => {
            setFieldValue(`acquisitions[${index}].count`, event.target.value);
          }}
          label="Mennyiség"
          InputProps={{
            endAdornment: <InputAdornment position="end">db</InputAdornment>,
          }}
          error={!!touched?.count && !!errors?.count}
          helperText={
            !!touched?.count && !!errors?.count && (errors?.count as string)
          }
          value={values.acquisitions[index]?.count ?? ''}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Autocomplete
          disablePortal
          id="taxTypeId"
          value={acquisition?.taxTypeId ?? ''}
          disabled={disabled}
          onChange={(event, value) => {
            setFieldValue(`acquisitions[${index}].taxTypeId`, value);
          }}
          getOptionLabel={(option) => {
            var tax = taxes?.find((g) => g.id === option);
            if (tax === null || tax === undefined) {
              return '';
            }

            return `${tax.name} (${tax.value}%)`;
          }}
          options={taxes?.map((g) => g.id) ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Adótípus"
              fullWidth
              error={!!touched?.taxTypeId && !!errors?.taxTypeId}
              helperText={
                !!touched?.taxTypeId &&
                !!errors?.taxTypeId &&
                (errors?.taxTypeId as string)
              }
            />
          )}
        />
      </Grid>
      <Grid item>
        <Tooltip title="Címke nyomtatása">
          <IconButton onClick={() => handlePrint()}>
            <Print />
          </IconButton>
        </Tooltip>
        {disabled === false && (
          <Tooltip title="Törlés">
            <IconButton
              onClick={() => {
                setParams({
                  open: true,
                  name: 'Biztosan törölni szeretné?',
                  onConfirm: async () =>
                    onDelete(values.acquisitions[index]?.itemId ?? ''),
                });
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip
          title={
            hasBeenCheaper
              ? 'A terméket korábban olcsóbban sikerült beszerezni!'
              : 'Beszerzési árak'
          }
        >
          <IconButton
            onClick={() =>
              setOpenDialog({
                row: { itemId: acquisition?.itemId },
                open: true,
              })
            }
          >
            {hasBeenCheaper ? <Warning color="error" /> : <Info color="info" />}
          </IconButton>
        </Tooltip>
      </Grid>
      <ConfirmDeleteDialog />

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ mb: 2 }}></Divider>
      </Grid>
    </>
  );
};

export default AcquisitionItem;
