import { OpenInNew } from '@mui/icons-material';
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete,
} from '@mui/material';

const ContactForm = (props: any) => {
  const {
    values,
    setFieldValue,
    errors,
    touched,
    readonly = false,
    showDifference = () => {},
    entity = {},
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disablePortal
          id="phone"
          value={values.contact?.phone ? values.contact?.phone.split(';') : []}
          disabled={readonly}
          autoSelect
          onChange={(event, value) => {
            setFieldValue('contact.phone', value.join(';'));
          }}
          multiple
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Telefonszám"
              error={!!touched.contact?.phone && !!errors.contact?.phone}
              helperText={
                !!touched.contact?.phone &&
                !!errors.contact?.phone &&
                errors.contact?.phone
              }
            />
          )}
          options={[]}
        />
        {showDifference(
          `${entity?.contact?.phone ?? ''}`,
          `${values.contact?.phone ?? ''}`
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disablePortal
          id="email"
          value={values.contact?.email ? values.contact?.email.split(';') : []}
          disabled={readonly}
          onChange={(event, value) => {
            setFieldValue('contact.email', value.join(';'));
          }}
          multiple
          freeSolo
          autoSelect
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="E-mail cím"
              error={!!touched.contact?.email && !!errors.contact?.email}
              helperText={
                !!touched.contact?.email &&
                !!errors.contact?.email &&
                errors.contact?.email
              }
            />
          )}
          options={[]}
        />
        {showDifference(
          `${entity?.contact?.email ?? ''}`,
          `${values.contact?.email ?? ''}`
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={values.contact?.webAddress ?? ''}
          onChange={(e) => setFieldValue('contact.webAddress', e.target.value)}
          label="Weboldal"
          name="webAddress"
          fullWidth
          disabled={readonly}
          error={!!touched.contact?.webAddress && !!errors.contact?.webAddress}
          helperText={
            !!touched.contact?.webAddress &&
            !!errors.contact?.webAddress &&
            errors.contact?.webAddress
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    try {
                      let urlString = values.contact?.webAddress;
                      if (!urlString.startsWith('http')) {
                        urlString = 'https://' + urlString;
                      }

                      window.open(urlString, '_blank').focus();
                    } catch (error) {
                      console.error(
                        'Invalid URL: ',
                        values.contact?.webAddress
                      );
                    }
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {showDifference(
          `${entity?.contact?.webAddress ?? ''}`,
          `${values.contact?.webAddress ?? ''}`
        )}
      </Grid>
    </Grid>
  );
};

export default ContactForm;
