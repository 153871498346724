import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
} from 'date-fns';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import DataList from '../../components/DataList';
import useCheckPermission from '../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import financeService from '../../services/statistics/financeService';
import itemService from '../../services/wms/itemService';
import { EntityStatuses } from '../../types/EntityStatuses';
import { formatCurrency } from '../../utils/valueFormatters';
import userService from '../../services/authority/userService';

const ItemsProfitStatistics = () => {
  const { ConfirmDeleteDialog } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();
  const titleDescriptor = useFunctionDescriptor('IncomeStatistics.title');
  const [itemProfitSum, setItemProfitSum] = useState<number>(0);
  const [itemExpenseSum, setItemExpenseSum] = useState<number>(0);
  const [users, setUsers] = useState<any[]>([]);

  const [itemIncomeSum, setItemIncomeSum] = useState<number>(0);
  const [selectedInterval, setSelectedInterval] = useState<string>('');
  const dispatch = useDispatch();

  const timeIntervalOptions = [
    { label: 'Napi', value: 'day' },
    { label: 'Heti', value: 'week' },
    { label: 'Havi', value: 'month' },
    { label: 'Negyedéves', value: 'quarter' },
    { label: 'Éves', value: 'year' },
  ];

  const [entity, setEntity] = useState<any>({
    startDate: null,
    itemName: '',
    endDate: null,
    itemId: null,
    IsWeighedAverage: true,
    employeeId: null,
  });

  const columns: GridColDef[] = [
    { field: 'itemNumber', headerName: 'Cikkszám', flex: 100 },
    { field: 'itemName', headerName: 'Temék', flex: 100 },
    {
      field: 'itemIncome',
      headerName: 'Bevétel',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value.toFixed(0)),
    },
    {
      field: 'itemExpense',
      headerName: 'Kiadás',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value.toFixed(0)),
    },
    {
      field: 'itemProfit',
      headerName: 'Profit(Nettó)',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value.toFixed(0)),
    },
  ];

  useEffect(() => {
    let abort = new AbortController();

    itemService.list(EntityStatuses.Approved, abort.signal).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setItems(response.records);
      }
    });

    return () => {
      abort.abort();
    };
  }, []);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  const handleSearch = () => {
    dispatch({ type: 'SHOW_QUERY' });
    financeService
      .itemsProfitQuery(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setRows(response.records);
          const itemProfitSum = response.records.reduce(
            (total, row) => total + row.itemProfit,
            0
          );
          setItemProfitSum(itemProfitSum);
          const itemExpenseSum = response.records.reduce(
            (total, row) => total + row.itemExpense,
            0
          );
          setItemExpenseSum(itemExpenseSum);
          const itemIncomeSum = response.records.reduce(
            (total, row) => total + row.itemIncome,
            0
          );
          setItemIncomeSum(itemIncomeSum);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Termék profit statisztika{titleDescriptor}</h2>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                id="selectedInterval"
                value={selectedInterval}
                onChange={(event, value) => {
                  setSelectedInterval(value);
                  const nowstart = moment(new Date()).startOf('day').toDate();
                  const nowend = moment(new Date()).endOf('day').toDate();
                  switch (value) {
                    case 'day':
                      setEntity({
                        ...entity,
                        startDate: nowstart,
                        endDate: nowend,
                      });
                      break;
                    case 'week':
                      const startOfWeekDate = startOfWeek(nowstart, {
                        weekStartsOn: 1,
                      });
                      const endOfWeekDate = endOfWeek(nowend, {
                        weekStartsOn: 1,
                      });
                      setEntity({
                        ...entity,
                        startDate: startOfWeekDate,
                        endDate: endOfWeekDate,
                      });
                      break;
                    case 'month':
                      const startOfMonthDate = startOfMonth(nowstart);
                      const endOfMonthDate = endOfMonth(nowend);
                      setEntity({
                        ...entity,
                        startDate: startOfMonthDate,
                        endDate: endOfMonthDate,
                      });
                      break;
                    case 'quarter':
                      const quarterStart = startOfQuarter(nowstart);
                      const quarterEnd = endOfQuarter(nowend);
                      setEntity({
                        ...entity,
                        startDate: quarterStart,
                        endDate: quarterEnd,
                      });
                      break;
                    case 'year':
                      const startOfYearDate = startOfYear(nowstart);
                      const endOfYearDate = endOfYear(nowend);
                      setEntity({
                        ...entity,
                        startDate: startOfYearDate,
                        endDate: endOfYearDate,
                      });
                      break;
                    default:
                      setEntity({ ...entity, startDate: null, endDate: null });
                      break;
                  }
                }}
                getOptionLabel={(option) => {
                  if (option === null) {
                    return 'Mindegy';
                  }
                  return (
                    timeIntervalOptions.find((o) => o.value === option)
                      ?.label ?? ''
                  );
                }}
                options={timeIntervalOptions.map((option) => option.value)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Időintervallum" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <DatePicker
                value={entity.startDate}
                onChange={(value) => setEntity({ ...entity, startDate: value })}
                label="Kezdő dátum"
                inputFormat="yyyy-MM-dd"
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <DatePicker
                value={entity.endDate}
                onChange={(value) => {
                  if (value !== null) {
                    setEntity({
                      ...entity,
                      endDate: moment(value).endOf('day').toDate(),
                    });
                  } else {
                    setEntity({
                      ...entity,
                      endDate: null,
                    });
                  }
                }}
                label="Vég dátum"
                inputFormat="yyyy-MM-dd"
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="itemId"
              value={entity.itemId}
              onChange={(event, value) => {
                setEntity({ ...entity, itemId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let item = items.find((g) => g.id === option);
                return `${item?.name} (${item?.ItemNumber ?? ''})`;
              }}
              options={[null, ...items.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Termék" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="employeeId"
              disabled={!checkPermission(['ItemsIncomeQueryAll'])}
              value={entity.employeeId}
              onChange={(event, value) => {
                setEntity({ ...entity, employeeId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let user = users.find((g) => g.id === option);
                return `${user?.fullName} (${user?.email ?? ''})`;
              }}
              options={[null, ...users.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Dolgozó" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={'auto'}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entity.IsWeighedAverage}
                  onChange={(e, checked) =>
                    setEntity({ ...entity, IsWeighedAverage: checked })
                  }
                  color="primary"
                />
              }
              label="Súlyozott átlag használata"
            />
          </Grid>
          <Grid item xs={12} textAlign={'right'}>
            <Button variant="contained" onClick={() => handleSearch()}>
              Keresés
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={'bold'}>
              Összesen(Nettó):
            </Typography>
          </Grid>
          <Grid item>
            {' '}
            <Typography variant="body1">
              {' '}
              Kiadás(Nettó): {formatCurrency(itemExpenseSum.toFixed(0))}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Bevétel(Nettó): {formatCurrency(itemIncomeSum.toFixed(0))}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Profit(Nettó): {formatCurrency(itemProfitSum.toFixed(0))}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2}>
          <DataList
            rows={rows}
            columns={columns}
            getRowId={(row) => row.itemId}
            localStorageKey={'ItemsProfitStatistics'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default ItemsProfitStatistics;
