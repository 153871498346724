import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  IconButton,
  InputAdornment,
  ButtonBaseActions,
} from '@mui/material';
import { Form, Formik } from 'formik';
import CreateEntityDecorator from '../../../../components/CreateEntityDecorator';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import AddUserCertificationDialog from './AddUserCertificationDialog';
import { Delete } from '@mui/icons-material';
import UserWorkHourDialog from './UserWorkHourDialog';
import useImageSelect from '../../../../hooks/useImageSelect';
import { set } from 'date-fns';
import AddUserAbilityDialog from './AddUserAbilityDialog';

const UserForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    users,
    certifications,
    setCertifications,
    abilities,
    setAbilities,
  } = props;
  const { checkPermission } = useCheckPermission();

  const [certificationDialog, setCertificationDialog] = React.useState(false);
  const [abilityDialog, setAbilityDialog] = React.useState(false);
  const [workHourDialog, setWorkHourDialog] = React.useState({
    open: false,
    workHourHistory: [],
  });

  const { ImageSelectComponent, uploadImage, uploading, error, uploadedFile } =
    useImageSelect('profilepictures');

  const handleUploaded = (image: any, setFieldValue: any) => {
    setFieldValue('imageId', image.id);
    setFieldValue('image', image);
  };
  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.fullName) {
          errors.fullName = 'Kötelező mező';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        let newValues = { ...values };
        uploadImage(
          (image) =>
            (newValues = { ...values, image: image, imageId: image.id }),
          'profilePicture'
        ).then(() => {
          onSubmit(newValues, setSubmitting);
        });
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Felhasználó {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={'Profilkép'}
                  value={values.image?.fileName ?? ''}
                  fullWidth
                  disabled
                  variant="standard"
                />
                <ImageSelectComponent
                  image={values.image}
                  onSelect={(image: any) => {
                    setFieldValue('imageId', image.id);
                    setFieldValue('image', image);
                  }}
                  alt="Profilkép"
                  onUploaded={(image: any) =>
                    handleUploaded(image, setFieldValue)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.fullName}
                  onChange={handleChange}
                  label="Megjelenítési név"
                  name="fullName"
                  fullWidth
                  autoFocus
                  error={!!touched.fullName && !!validationErrors.fullName}
                  helperText={
                    !!touched.fullName &&
                    !!validationErrors.fullName &&
                    (validationErrors.fullName as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isHidden}
                      onChange={(e, checked) =>
                        setFieldValue('isHidden', checked)
                      }
                      color="primary"
                    />
                  }
                  label="Felhasználó elrejtése"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.email}
                  onChange={handleChange}
                  label="Email"
                  name="UserEmail"
                  fullWidth
                  error={!!touched.email && !!validationErrors.email}
                  helperText={
                    !!touched.email &&
                    !!validationErrors.email &&
                    (validationErrors.email as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.personalEmail}
                  onChange={handleChange}
                  label="Személyes email"
                  name="personalEmail"
                  fullWidth
                  error={
                    !!touched.personalEmail && !!validationErrors.personalEmail
                  }
                  helperText={
                    !!touched.personalEmail &&
                    !!validationErrors.personalEmail &&
                    (validationErrors.personalEmail as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.phone}
                  onChange={handleChange}
                  label="Telefonszám"
                  name="phone"
                  fullWidth
                  error={!!touched.phone && !!validationErrors.phone}
                  helperText={
                    !!touched.phone &&
                    !!validationErrors.phone &&
                    (validationErrors.phone as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.invoicePrefixes}
                  onChange={handleChange}
                  label="Számla előtagok"
                  name="invoicePrefixes"
                  fullWidth
                  error={
                    !!touched.invoicePrefixes &&
                    !!validationErrors.invoicePrefixes
                  }
                  helperText={
                    !!touched.invoicePrefixes &&
                    !!validationErrors.invoicePrefixes &&
                    (validationErrors.invoicePrefixes as string)
                  }
                />
                <Typography variant="caption">
                  A számla előtagokat pontosvesszővel válassza el egymástól :
                  "AAA;BBB;CCC".
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.incomeTarget}
                  onChange={handleChange}
                  label="Bevétel cél"
                  name="incomeTarget"
                  type="number"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Ft</InputAdornment>
                    ),
                  }}
                  error={
                    !!touched.incomeTarget && !!validationErrors.incomeTarget
                  }
                  helperText={
                    !!touched.incomeTarget &&
                    !!validationErrors.incomeTarget &&
                    (validationErrors.incomeTarget as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.commissionRate}
                  onChange={handleChange}
                  label="Jutalék"
                  name="commissionRate"
                  type="number"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  error={
                    !!touched.commissionRate &&
                    !!validationErrors.commissionRate
                  }
                  helperText={
                    !!touched.commissionRate &&
                    !!validationErrors.commissionRate &&
                    (validationErrors.commissionRate as string)
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="superiorId"
                  value={values.superiorId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('superiorId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName}`;
                    } else return '';
                  }}
                  options={users.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Felettes"
                      required
                      fullWidth
                      error={
                        !!touched.superiorId && !!validationErrors.superiorId
                      }
                      helperText={
                        !!touched.superiorId &&
                        !!validationErrors.superiorId &&
                        (validationErrors.superiorId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item container xs={12} spacing={2} alignItems={'center'}>
                <Grid item xs={10}>
                  <TextField
                    value={values.workHour}
                    onChange={handleChange}
                    label="Munkaóra"
                    name="workHour"
                    type="number"
                    fullWidth
                    error={!!touched.workHour && !!validationErrors.workHour}
                    helperText={
                      !!touched.workHour &&
                      !!validationErrors.workHour &&
                      (validationErrors.workHour as string)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setWorkHourDialog({
                        open: true,
                        workHourHistory: values.userWorkHourHistories,
                      });
                    }}
                  >
                    Munkaóra napló
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Van jogosítványa"
                  control={
                    <Checkbox
                      checked={values.hasDrivingLicense}
                      onChange={(e, checked) =>
                        setFieldValue('hasDrivingLicense', checked)
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Születési dátum"
                  value={values.birthDate}
                  onChange={(date) => setFieldValue('birthDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                  inputFormat="yyyy.MM.dd"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Orvosi vizsgálat dátuma"
                  value={values.medicalExaminationDate}
                  onChange={(date) =>
                    setFieldValue('medicalExaminationDate', date)
                  }
                  renderInput={(props) => <TextField {...props} />}
                  inputFormat="yyyy.MM.dd"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mt: 2 }}>
                  Képesítések
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} pb={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setCertificationDialog(true);
                    }}
                  >
                    Képesítés hozzáadása
                  </Button>
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  {values?.userCertifications?.map((certification: any) => {
                    return (
                      <Grid
                        item
                        container
                        xs={12}
                        spacing={2}
                        key={certification.documentNumber}
                      >
                        <Grid item xs={3}>
                          <TextField
                            value={
                              certifications.find(
                                (x) => x.id === certification.certificationId
                              )?.name ?? certification.certificationName
                            }
                            disabled
                            InputLabelProps={{ shrink: true }}
                            label="Képesítés"
                            name="certificationId"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            value={certification.documentNumber}
                            disabled
                            label="Okmány szám"
                            name="documentNumber"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            value={new Date(
                              certification.validityDate
                            ).toLocaleDateString()}
                            disabled
                            label="Érvényesség"
                            name="validityDate"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            value={new Date(
                              certification.obtainingDate
                            ).toLocaleDateString()}
                            disabled
                            label="Megszerzés dátuma"
                            name="obtainingDate"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{ position: 'relative', right: { xs: '0%' } }}
                        >
                          <IconButton
                            onClick={() => {
                              setFieldValue('userCertifications', [
                                ...values.userCertifications?.filter(
                                  (y) =>
                                    y.certificationId !==
                                    certification.certificationId
                                ),
                              ]);
                            }}
                            color={'primary'}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mt: 2 }}>
                  Képességek
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} pb={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setAbilityDialog(true);
                    }}
                  >
                    Képesség hozzáadása
                  </Button>
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  {values?.abilities?.map((ability: any) => {
                    return (
                      <Grid item container xs={12} spacing={2} key={ability.id}>
                        <Grid item xs={4}>
                          <TextField
                            value={ability.name}
                            disabled
                            InputLabelProps={{ shrink: true }}
                            label="Képesítés"
                            name="certificationId"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            value={ability.description}
                            disabled
                            label="Leírás"
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{ position: 'relative', right: { xs: '0%' } }}
                        >
                          <IconButton
                            onClick={() => {
                              setFieldValue('abilities', [
                                ...values.abilities?.filter(
                                  (y) => y.id !== ability.id
                                ),
                              ]);
                            }}
                            color={'primary'}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                <Grid item p={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Mentés
                  </Button>
                </Grid>
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <AddUserCertificationDialog
              open={certificationDialog}
              setOpen={setCertificationDialog}
              onSave={(certification: any) => {
                setFieldValue('userCertifications', [
                  ...values.userCertifications,
                  certification,
                ]);
              }}
              certifications={certifications}
              setCertifications={setCertifications}
            />
            <AddUserAbilityDialog
              open={abilityDialog}
              setOpen={setAbilityDialog}
              onSave={(ability: any) => {
                setFieldValue('abilities', [
                  ...(values?.abilities ?? []),
                  ability,
                ]);
              }}
              abilities={abilities.filter(
                (x) => !values.abilities?.map((y) => y.id).includes(x.id)
              )}
              setAbilities={setAbilities}
            />
          </Paper>
          <UserWorkHourDialog
            open={workHourDialog.open}
            setOpen={setWorkHourDialog}
            workHourHistory={workHourDialog.workHourHistory}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
