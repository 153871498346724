import {
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { CardHeader } from '@mui/material';
import itemService from '../services/wms/itemService';
import EntityNavigator from './EntityNavigator';
import { EntityTypes } from '../types/EntityTypes';

const DataCard = (props: any) => {
  const { row, headers, ...other } = props;

  if (!headers || headers.length === 0 || !row) {
    return <div>Nincs adat</div>;
  }

  var actions = headers.find((h) => h.field === 'actions');

  return (
    <Card
      variant="outlined"
      sx={{
        margin: 'auto',
        transition: '0.3s',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        boxShadow: '0 8px 40px -12px rgba(1,1,1,1)',
      }}
    >
      <CardHeader
        sx={{
          background: '#009be5',
          display: 'block',
          alignItems: 'center',
          color: '#fff',
          boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
        }}
        action={
          <Grid
            container
            justifyContent="center"
            display={'flex'}
            sx={{ width: '100%', textAlign: 'center' }}
          >
            {actions?.getActions({ row }, 'inherit').map((x, index) => {
              if (x.type.name === 'EntityNavigator') {
                return (
                  <Grid item key={`${row.id}-${index}`}>
                    <EntityNavigator
                      entityType={x.props.entityType}
                      entityId={x.props.entityId}
                      value={x.props.value}
                      isMobileView
                      showIcon={x.props.showIcon}
                      readonly={x.props.readonly}
                      onSave={(result: any) => {
                        x.props.onSave(result);
                      }}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item key={`${row.id}-${index}`}>
                    <Tooltip title={x?.props?.label}>
                      <IconButton
                        aria-label="settings"
                        onClick={() => x?.props?.onClick()}
                        color={x?.props?.color}
                      >
                        {x?.props?.icon}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                );
              }
            })}
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          justifyContent="center"
          sx={{ width: '100%', textAlign: 'center' }}
        >
          {headers.map(
            (h) =>
              h.field !== 'actions' && (
                <React.Fragment key={h.field}>
                  <Grid item xs={12} container textAlign="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        component="span"
                        color="textPrimary"
                        sx={{ fontWeight: 'bold', fontSize: '1rem' }}
                      >
                        {h.headerName}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent={'center'}>
                      {!h.renderCell ? (
                        <TextField
                          value={
                            (!h.valueFormatter
                              ? !h.valueGetter
                                ? !h.renderCell
                                  ? !h._valueGetter
                                    ? row[h.field]
                                    : null
                                  : h._valueGetter({ row: row })
                                : h.valueGetter({ row })
                              : h.valueFormatter({
                                  id: row.id ? row.id : other.getRowId(row),
                                  value: row[h.field],
                                })) ?? ''
                          }
                          multiline
                          title={h.headerName}
                          disabled
                          sx={{ border: 0 }}
                          fullWidth
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'center' },
                          }}
                        />
                      ) : (
                        <Grid item textAlign={'center'}>
                          {h.renderCell({ row, value: row[h.field] })}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DataCard;
