import { Configuration, PopupRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: 'a04e65c3-35d3-43ee-b29b-b50d77c417da',
        authority:
            'https://login.microsoftonline.com/0bda74c0-f18f-47cb-bb15-f419dfd096d4',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'localStorage',
    },
};

export const loginRequest: PopupRequest = {
    scopes: ['api://letis.jacana.api/read_write'],
};
