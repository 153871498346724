import {
  GridColDef,
  GridRowParams,
  GridActionsColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  TextField,
  Autocomplete,
  Button,
  Dialog,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import DataList from '../../components/DataList';
import { useBarcodeScanner } from '../../components/barcode-scanner/useBarcodeScanner';
import warehouseService from '../../services/wms/warehouseService';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import stockTakingService from '../../services/stockTaking/stockTakingService';
import useCheckPermission from '../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import ArchiveIcon from '@mui/icons-material/Archive';
import itemService from '../../services/wms/itemService';

const StockTakingPage = () => {
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<number>(0);
  const [selectedStockTaking, setSelectedStockTaking] = useState<number>(0);
  const [stockTakings, setStockTakings] = useState<any[]>([]);
  const [stockTakingItems, setStockTakingItems] = useState<any[]>([]);
  const [stockTaking, setStockTaking] = useState<any>();
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [stockTakingItemDialogOpen, setStockTakingItemDialogOpen] = useState({
    open: false,
    stockTakingItem: { itemName: '', count: 0, actualCount: 0, itemId: 0 },
  });
  const titleDescriptor = useFunctionDescriptor('StockTakingPage.title');

  const { checkPermission } = useCheckPermission();

  const { enqueueSnackbar } = useSnackbar();
  const handleBarcodeRead = (barcode) => {
    if (barcode.item) {
      setStockTakingItemDialogOpen({
        open: true,
        stockTakingItem: { ...barcode.item },
      });
    } else {
      enqueueSnackbar('Ismeretlen árucikk', {
        variant: 'error',
      });
    }
  };

  useBarcodeScanner(handleBarcodeRead, stockTakingItems);
  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    if (params.row.count === 0) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Archiválás">
              <ArchiveIcon />
            </Tooltip>
          }
          label={'Archiválás'}
          onClick={() => handleArchive(params)}
        />
      );
    }

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Szerkesztés">
            <EditIcon />
          </Tooltip>
        }
        label={'Szerkesztés'}
        onClick={() =>
          setStockTakingItemDialogOpen({
            open: true,
            stockTakingItem: { ...params.row },
          })
        }
      />
    );
    return actions;
  };

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    stockTakingService.listStockTaking(false).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setStockTakings(response.records);
      }
    });
  }, []);

  useEffect(() => {
    if (!selectedStockTaking) {
      return;
    }

    stockTakingService.getStockTaking(selectedStockTaking).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setStockTaking(response.result);
      }
    });

    stockTakingService
      .listRemainingStockTakingItem(selectedStockTaking)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setStockTakingItems(response.records);
        }
      });

    stockTakingService.listStockTaking(false).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setStockTakings(response.records);
      }
    });
  }, [selectedStockTaking]);

  const columns: GridColDef[] = [
    {
      field: 'itemName',
      headerName: 'Termék',
      flex: 100,
      editable: false,
    },
    {
      field: 'count',
      headerName: 'Regisztrált mennyiség',
      editable: false,
      flex: 100,
    },
    {
      field: 'actualCount',
      headerName: 'Aktuális mennyiség',
      editable: false,
      flex: 100,
    },

    {
      minWidth: 20,
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleArchive = (params: GridRowParams) => {
    itemService.delete(params.row.itemId).then((x) => {
      if (x.hasError) {
        enqueueSnackbar(x.errorMessages, {
          variant: 'error',
        });
        return;
      }

      enqueueSnackbar('Sikeres mentés!', {
        variant: 'success',
      });
      setStockTakingItems(
        stockTakingItems.filter((x) => x.itemId !== params.row.itemId)
      );
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          {stockTaking != null ? (
            <h2>
              {warehouses.find((x) => x.id === stockTaking.warehouseId)?.name}{' '}
              leltározása{titleDescriptor}
            </h2>
          ) : (
            <h2>Leltározás{titleDescriptor}</h2>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            id="stockTaking"
            value={selectedStockTaking}
            onChange={(event, value) => {
              setSelectedStockTaking(value);
            }}
            getOptionLabel={(option) =>
              stockTakings.find((g: any) => g.id === option)?.warehouseName ??
              ''
            }
            options={stockTakings.map((g) => g.id)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Folyamatban lévő leltárazások"
              />
            )}
          />
        </Grid>
        {checkPermission(['StockTakingCreate']) && (
          <Grid container item xs={12} md={6} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Leltározás indítása">
                <IconButton
                  color="primary"
                  onClick={() => setCreateDialogOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            onRowDoubleClick={(params: GridRowParams) => {
              setStockTakingItemDialogOpen({
                open: true,
                stockTakingItem: { ...params.row },
              });
            }}
            rows={stockTakingItems}
            columns={columns}
            getRowId={(row) => row.itemId}
            exportFields={['itemName', 'actualCount', 'count']}
            localStorageKey={'StockTakingPage'}
          />{' '}
          <Dialog
            open={createDialogOpen}
            style={{ minHeight: 500 }}
            fullWidth
            maxWidth="lg"
            onClose={() => setCreateDialogOpen(false)}
          >
            <DialogTitle>Leltározás indítása</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Kérem válassza ki leltározni kivánt raktárt!
              </DialogContentText>
              <Grid container spacing={2} pt={5}>
                <Grid item xs={12} pb={5}>
                  <Autocomplete
                    id="warehouse"
                    value={selectedWarehouse}
                    onChange={(event, value: number) => {
                      setSelectedWarehouse(value);
                    }}
                    getOptionLabel={(option) =>
                      warehouses.find((g: any) => g.id === option)?.name ?? ''
                    }
                    options={warehouses.map((g) => g.id)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth label="Raktárak" />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="center"
                  spacing={2}
                  pt={5}
                >
                  <Grid item>
                    <Button
                      disabled={!selectedWarehouse}
                      variant="contained"
                      onClick={() => {
                        stockTakingService
                          .createStockTaking({
                            warehouseId: selectedWarehouse,
                          })
                          .then((x) => {
                            if (x.hasError) {
                              enqueueSnackbar(x.errorMessages, {
                                variant: 'error',
                              });
                              return;
                            }

                            setSelectedStockTaking(x.result.id);
                            setSelectedWarehouse(0);
                            setCreateDialogOpen(false);
                          });
                      }}
                    >
                      Leltározás indítása
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => setCreateDialogOpen(false)}
                    >
                      Mégse
                    </Button>
                  </Grid>
                </Grid>
              </Grid>{' '}
            </DialogContent>
          </Dialog>
          {stockTakingItemDialogOpen.stockTakingItem && (
            <Dialog
              open={stockTakingItemDialogOpen.open}
              style={{ minHeight: 500 }}
              fullWidth
              maxWidth="sm"
              onClose={() =>
                setStockTakingItemDialogOpen({
                  open: false,
                  stockTakingItem: {
                    itemName: '',
                    count: 0,
                    actualCount: 0,
                    itemId: 0,
                  },
                })
              }
            >
              <DialogTitle>
                {stockTakingItemDialogOpen.stockTakingItem.itemName}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Kérem adja meg az összeszámolt mennyiséget!
                </DialogContentText>
                <Grid container spacing={2} pt={5}>
                  <Grid item xs={12} pb={2}>
                    Raktárkészlet:{' '}
                    {stockTakingItemDialogOpen.stockTakingItem.count}
                  </Grid>
                  <Grid item xs={12} pb={5}>
                    <TextField
                      size="small"
                      type="number"
                      fullWidth
                      onChange={(event) => {
                        setStockTakingItemDialogOpen((s) => {
                          return {
                            ...s,
                            stockTakingItem: {
                              ...stockTakingItemDialogOpen.stockTakingItem,
                              actualCount: parseInt(event.target.value),
                            },
                          };
                        });
                      }}
                      label="Tényleges mennyiség"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">db</InputAdornment>
                        ),
                      }}
                      value={
                        stockTakingItemDialogOpen.stockTakingItem.actualCount
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="center"
                    spacing={2}
                    pt={5}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (
                            stockTakingItemDialogOpen.stockTakingItem
                              .itemName !== ''
                          ) {
                            stockTakingService
                              .createStockTakingItem(
                                stockTakingItemDialogOpen.stockTakingItem
                              )
                              .then((x) => {
                                if (x.hasError) {
                                  enqueueSnackbar(x.errorMessages, {
                                    variant: 'error',
                                  });
                                  return;
                                }

                                enqueueSnackbar('Sikeres mentés!', {
                                  variant: 'success',
                                });
                                setStockTakingItems(
                                  stockTakingItems.filter(
                                    (x) =>
                                      x.itemId !==
                                      stockTakingItemDialogOpen.stockTakingItem
                                        .itemId
                                  )
                                );
                                setStockTakingItemDialogOpen({
                                  open: false,
                                  stockTakingItem: {
                                    itemName: '',
                                    count: 0,
                                    actualCount: 0,
                                    itemId: 0,
                                  },
                                });
                              });
                          }
                        }}
                      >
                        Raktár frissítése
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          setStockTakingItemDialogOpen({
                            open: false,
                            stockTakingItem: {
                              itemName: '',
                              count: 0,
                              actualCount: 0,
                              itemId: 0,
                            },
                          })
                        }
                      >
                        Mégse
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="center" pb={3} spacing={2}>
        {stockTaking && checkPermission(['StockTakingClose']) && (
          <Grid item>
            <Button
              variant="contained"
              disabled={!selectedStockTaking}
              onClick={() => {
                stockTakingService
                  .closeStockTaking(selectedStockTaking)
                  .then((x) => {
                    if (x.hasError) {
                      enqueueSnackbar(x.errorMessages, {
                        variant: 'error',
                      });
                      return;
                    }

                    setStockTakingItems([]);
                    setStockTakings(
                      stockTakings.filter((x) => x.id !== selectedStockTaking)
                    );
                    setSelectedStockTaking(0);
                  });
              }}
            >
              Leltározás lezárása
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default StockTakingPage;
