const initialState = {
  waitLayerVisible: false,
  message: "The request is being processed please wait",
};

export const waitLayerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "TOGGLE":
      return {
        ...state,
        waitLayerVisible: !state.waitLayerVisible,
        message: action.payload || initialState.message,
      };
    case "SHOW":
      return {
        ...state,
        waitLayerVisible: true,
        message: action.payload || initialState.message,
      };
    case "SHOW_SAVE":
      return {
        ...state,
        waitLayerVisible: true,
        message: "Mentés folyamatban...",
      };
    case "SHOW_QUERY":
      return {
        ...state,
        waitLayerVisible: true,
        message: "Lekérdezés folyamatban...",
      };
    case "SHOW_SERVICE_UNAVAILABLE":
      return {
        ...state,
        waitLayerVisible: true,
        message: "Szerver nem elérhető, kérem várjon!",
      };
    case "HIDE":
      return {
        ...state,
        waitLayerVisible: false,
        message: initialState.message,
      };
    case "SET_MESSAGE":
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
};
