import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import ChimneySweepingCertificateForm from './ChimneySweepingCertificateForm';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import userService from '../../../services/authority/userService';
import { ClientTypes } from '../../../types/ClientTypes';
import chimneyTypeService from '../../../services/chimneySweeping/chimneyTypeService';
import chimneyUseCaseService from '../../../services/chimneySweeping/chimneyUseCaseService';
import performanceCategoryService from '../../../services/chimneySweeping/performanceCategoryService';
import chimneyErrorCodeService from '../../../services/chimneySweeping/chimneyErrorCodeService';
import { ChimneySweepingCertificateStatus } from '../../../types/ChimneySweepingCertificateStatus';
import carbonMonoxideSensorService from '../../../services/chimneySweeping/carbonMonoxideSensorService';

const ChimneySweepingCertificateEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { clientId = null, isDialog = false, onSave } = props;
  const params = useParams();
  const id = clientId !== null ? clientId : params.id;
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [addresses, setAddresses] = useState<any[]>([]);
  const [chimneyTypes, setChimneyTypes] = useState<any[]>([]);
  const [useCases, setUseCases] = useState<any[]>([]);
  const [performanceCategories, setPerformanceCategories] = useState<any[]>([]);
  const [errorCodes, setErrorCodes] = useState<any[]>([]);
  const [now, setNow] = useState<Date>(new Date());
  const [coSensors, setCoSensors] = useState<any[]>([]);
  const [representative, setRepresentative] = useState<any>({
    clientId: 0,
    representativeName: '',
    representativePhone: '',
  });
  const [entity, setEntity] = useState<any>({
    airSpaceConnectionAmount: 0,
    employeeId: 0,
    clientId: 0,
    name: '',
    documentNumber: '',
    serviceProviderId: 0,
    approverName: '',
    approverTitle: '',
    warehouseId: 0,
    carbonMonoxideSensors: [],
    others: '',
    date: now,
    hasDeliveryFee: true,
    isPaid: true,
    paymentMethod: 'Készpénz',
    workAddressId: 0,
    obligedToInstallCODevice: false,
    obligedToInstallCODevicePlaceNumber: 0,
    hasCODevice: false,
    cODevicePlace: '',
    cODeviceWorks: false,
    cleaningDone: false,
    calibrationDone: false,
    hasDocuments: false,
    expired: false,
    errorMessage: '',
    appearedOnLocation: false,
    inspection: false,
    cleaning: false,
    technicalReview: false,
    chimneyControls: [],
    billingAddressId: 0,
    status: ChimneySweepingCertificateStatus.Valid,
  });

  const [users, setUsers] = useState<string[]>([]);
  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (id) {
      chimneySweepingCertificateService.get(id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEntity(response.result);
        }
      });
    }
  }, [id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    chimneySweepingCertificateService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (!isDialog) {
            navigate(-1);
          }
          if (isDialog) {
            onSave(response.result);
          }
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    clientService.list(true, null, abort.signal).then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setClients(response.records);
        if (!(entity.id > 0)) {
          setEntity((prev) => ({
            ...prev,
            serviceProviderId: response.records.filter(
              (x) => x.clientType === ClientTypes.Distributor
            )[0]?.id,
          }));
        }
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
      dispatch({ type: 'HIDE' });
    });
    return () => {
      abort.abort();
    };
  }, []);

  const handleClientSelected = (clientId: number) => {
    if (!(clientId > 0)) return;

    clientService.get(clientId).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setAddresses(response.result.addresses);
        setRepresentative({
          clientId: response.result.id,
          representativeName: response.result.representativeName,
          representativePhone: response.result.representativePhone,
        });
      }
    });
  };

  useEffect(() => {
    chimneyTypeService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setChimneyTypes(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    chimneyUseCaseService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setUseCases(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    chimneyErrorCodeService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setErrorCodes(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    performanceCategoryService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setPerformanceCategories(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    carbonMonoxideSensorService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setCoSensors(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  return (
    <ChimneySweepingCertificateForm
      entity={entity}
      readonly={!checkPermission(['ChimneySweepingCertificateEdit'])}
      onSubmit={onSubmit}
      errors={errors}
      clients={clients}
      setClients={setClients}
      addresses={addresses}
      setAddresses={setAddresses}
      onAddressAdded={(address) => {
        setAddresses((addresses) => [...addresses, address]);
      }}
      onClientAdded={(client) => {
        if (clients.find((x) => x.id === client.id)) {
          setClients((clients) =>
            clients.map((x) => (x.id === client.id ? client : x))
          );
        } else {
          setClients((clients) => [...clients, client]);
        }
      }}
      representative={representative}
      setRepresentative={setRepresentative}
      handleClientSelected={handleClientSelected}
      chimneyTypes={chimneyTypes}
      useCases={useCases}
      users={users}
      errorCodes={errorCodes}
      coSensors={coSensors}
      performanceCategories={performanceCategories}
      handleCancel={() => navigate(-1)}
      isDialog={isDialog}
    />
  );
};

export default ChimneySweepingCertificateEdit;
