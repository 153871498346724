import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/dashboard/Dashboard';

import TransactionLogs from '../pages/wms/transactionLogs/TransactionLogs';

import WarehouseGroupCreate from '../pages/wms/warehouseGroups/WarehouseGroupCreate';
import WarehouseGroupEdit from '../pages/wms/warehouseGroups/WarehouseGroupEdit';
import WarehouseGroupsPage from '../pages/wms/warehouseGroups/WarehouseGroupsPage';

import WarehouseCreate from '../pages/wms/warehouses/WarehouseCreate';
import WarehouseEdit from '../pages/wms/warehouses/WarehouseEdit';
import WarehousesPage from '../pages/wms/warehouses/WarehousesPage';

import ItemGroupCreate from '../pages/wms/itemGroups/ItemGroupCreate';
import ItemGroupEdit from '../pages/wms/itemGroups/ItemGroupEdit';
import ItemGroupPage from '../pages/wms/itemGroups/ItemGroupPage';

import ItemCreate from '../pages/wms/items/ItemCreate';
import ItemEdit from '../pages/wms/items/ItemEdit';
import ItemPage from '../pages/wms/items/ItemPage';

import PropertyCreate from '../pages/wms/properties/PropertyCreate';
import PropertyEdit from '../pages/wms/properties/PropertyEdit';
import PropertyPage from '../pages/wms/properties/PropertyPage';

import ClientCreate from '../pages/crm/clients/ClientCreate';
import ClientEdit from '../pages/crm/clients/ClientEdit';
import ClientsPage from '../pages/crm/clients/ClientsPage';

import AcquisitionCreate from '../pages/acquisitions/AcquisitionCreate';
import AcquisitionEdit from '../pages/acquisitions/AcquisitionEdit';
import AcquisitionsPage from '../pages/acquisitions/AcquisitionPage';

import PriceCategoryCreate from '../pages/pricing/priceCategories/PriceCategoryCreate';
import PriceCategoryEdit from '../pages/pricing/priceCategories/PriceCategoryEdit';
import PriceCategoryPage from '../pages/pricing/priceCategories/PriceCategoryPage';

import ItemPricePage from '../pages/pricing/itemPrices/ItemPricePage';
import BrandCreate from '../pages/wms/brands/BrandCreate';
import BrandEdit from '../pages/wms/brands/BrandEdit';
import BrandPage from '../pages/wms/brands/BrandPage';

import CustomPriceCreate from '../pages/pricing/customPrices/CustomPriceCreate';
import CustomPriceEdit from '../pages/pricing/customPrices/CustomPriceEdit';
import CustomPricePage from '../pages/pricing/customPrices/CustomPricePage';
import SalesPage from '../pages/sales/SalesPage';
import { TransactionCommands } from '../pages/wms/transactionLogs/TransactionCommands';
import TransactionCreate from '../pages/wms/transactionLogs/TransactionCreate';

import RoleCreate from '../pages/authority/roles/RoleCreate';
import RoleEdit from '../pages/authority/roles/RoleEdit';
import RolePage from '../pages/authority/roles/RolePage';

import UserGroupCreate from '../pages/authority/userGroups/UserGroupCreate';
import UserGroupEdit from '../pages/authority/userGroups/UserGroupEdit';
import UserGroupPage from '../pages/authority/userGroups/UserGroupPage';
import UserEdit from '../pages/authority/users/roles/UserEdit';
import UserPage from '../pages/authority/users/roles/UserPage';
import ChimneyErrorCodeCreate from '../pages/chimneySweeping/chimneyErrorCodes/ChimneyErrorCodeCreate';
import ChimneyErrorCodeEdit from '../pages/chimneySweeping/chimneyErrorCodes/ChimneyErrorCodeEdit';
import ChimneyErrorCodePage from '../pages/chimneySweeping/chimneyErrorCodes/ChimneyErrorCodePage';
import ChimneySweepingCertificateCreate from '../pages/chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificateCreate';
import ChimneySweepingCertificateEdit from '../pages/chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificateEdit';
import ChimneySweepingCertificatePage from '../pages/chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificatePage';
import ChimneyTypeCreate from '../pages/chimneySweeping/chimneyTypes/ChimneyTypeCreate';
import ChimneyTypeEdit from '../pages/chimneySweeping/chimneyTypes/ChimneyTypeEdit';
import ChimneyTypePage from '../pages/chimneySweeping/chimneyTypes/ChimneyTypePage';
import ChimneyUseCaseCreate from '../pages/chimneySweeping/chimneyUseCases/ChimneyUseCaseCreate';
import ChimneyUseCaseEdit from '../pages/chimneySweeping/chimneyUseCases/ChimneyUseCaseEdit';
import ChimneyUseCasePage from '../pages/chimneySweeping/chimneyUseCases/ChimneyUseCasePage';
import ChimneyCreate from '../pages/chimneySweeping/chimneys/ChimneyCreate';
import ChimneyEdit from '../pages/chimneySweeping/chimneys/ChimneyEdit';
import ChimneyPage from '../pages/chimneySweeping/chimneys/ChimneyPage';
import DecisionCreate from '../pages/chimneySweeping/decisions/DecisionCreate';
import DecisionEdit from '../pages/chimneySweeping/decisions/DecisionEdit';
import DecisionPage from '../pages/chimneySweeping/decisions/DecisionPage';
import PerformanceCategoryCreate from '../pages/chimneySweeping/performanceCategories/PerformanceCategoryCreate';
import PerformanceCategoryEdit from '../pages/chimneySweeping/performanceCategories/PerformanceCategoryEdit';
import PerformanceCategoryPage from '../pages/chimneySweeping/performanceCategories/PerformanceCategoryPage';
import CustomerCreate from '../pages/crm/clients/customers/CustomerCreate';
import CustomerEdit from '../pages/crm/clients/customers/CustomerEdit';
import CustomersPage from '../pages/crm/clients/customers/CustomersPage';
import DeviceManagementPage from '../pages/deviceManagement/DeviceManagementPage';
import ComputerCreate from '../pages/deviceManagement/computers/ComputerCreate';
import ComputerEdit from '../pages/deviceManagement/computers/ComputerEdit';
import ComputerPage from '../pages/deviceManagement/computers/ComputerPage';
import InstrumentCreate from '../pages/deviceManagement/instruments/InstrumentCreate';
import InstrumentEdit from '../pages/deviceManagement/instruments/InstrumentEdit';
import InstrumentPage from '../pages/deviceManagement/instruments/InstrumentPage';
import MobileCreate from '../pages/deviceManagement/mobiles/MobileCreate';
import MobileEdit from '../pages/deviceManagement/mobiles/MobileEdit';
import MobilePage from '../pages/deviceManagement/mobiles/MobilePage';
import PeripheryCreate from '../pages/deviceManagement/periherals/PeripheryCreate';
import PeripheryEdit from '../pages/deviceManagement/periherals/PeripheryEdit';
import PeripheryPage from '../pages/deviceManagement/periherals/PeripheryPage';
import SerialNumberedBlockTypeCreate from '../pages/deviceManagement/serialNumberedBlockTypes/SerialNumberedBlockTypeCreate';
import SerialNumberedBlockTypeEdit from '../pages/deviceManagement/serialNumberedBlockTypes/SerialNumberedBlockTypeEdit';
import SerialNumberedBlockTypePage from '../pages/deviceManagement/serialNumberedBlockTypes/SerialNumberedBlockTypePage';
import SimCardCreate from '../pages/deviceManagement/simCards/SimCardCreate';
import SimCardEdit from '../pages/deviceManagement/simCards/SimCardEdit';
import SimCardPage from '../pages/deviceManagement/simCards/SimCardPage';
import ToolCreate from '../pages/deviceManagement/tools/ToolCreate';
import ToolEdit from '../pages/deviceManagement/tools/ToolEdit';
import ToolPage from '../pages/deviceManagement/tools/ToolPage';
import VehicleCreate from '../pages/deviceManagement/vehicles/VehicleCreate';
import VehicleEdit from '../pages/deviceManagement/vehicles/VehicleEdit';
import VehiclePage from '../pages/deviceManagement/vehicles/VehiclePage';
import UserCalendar from '../pages/erp/Calendar/UserCalendar';
import ClosingSheetEdit from '../pages/erp/closingSheets/ClosingSheetEdit';
import ClosingSheetPage from '../pages/erp/closingSheets/ClosingSheetPage';
import ExpenseTypeCreate from '../pages/erp/expenseTypes/ExpenseTypeCreate';
import ExpenseTypeEdit from '../pages/erp/expenseTypes/ExpenseTypeEdit';
import ExpenseTypePage from '../pages/erp/expenseTypes/ExpenseTypePage';
import ExpenseCreate from '../pages/erp/expenses/ExpenseCreate';
import ExpenseEdit from '../pages/erp/expenses/ExpenseEdit';
import ExpensePage from '../pages/erp/expenses/ExpensePage';
import HolidayCreate from '../pages/erp/holidays/HolidayCreate';
import HolidayEdit from '../pages/erp/holidays/HolidayEdit';
import HolidayPage from '../pages/erp/holidays/HolidayPage';
import IrregularWorkdayCreate from '../pages/erp/irregularWorkdays/IrregularWorkdayCreate';
import IrregularWorkdayEdit from '../pages/erp/irregularWorkdays/IrregularWorkdayEdit';
import IrregularWorkdayPage from '../pages/erp/irregularWorkdays/IrregularWorkdayPage';
import OfferCreate from '../pages/erp/offers/OfferCreate';
import OfferEdit from '../pages/erp/offers/OfferEdit';
import OfferPage from '../pages/erp/offers/OfferPage';
import OfferPrintView from '../pages/erp/offers/OfferPrintView';
import ServiceCategoryCreate from '../pages/erp/serviceCategories/ServiceCategoryCreate';
import ServiceCategoryEdit from '../pages/erp/serviceCategories/ServiceCategoryEdit';
import ServiceCategorysPage from '../pages/erp/serviceCategories/ServiceCategoryPage';
import ServiceCreate from '../pages/erp/services/ServiceCreate';
import ServiceEdit from '../pages/erp/services/ServiceEdit';
import ServicesPage from '../pages/erp/services/ServicePage';
import TaskTypeCreate from '../pages/erp/taskTypes/TaskTypesCreate';
import TaskTypeEdit from '../pages/erp/taskTypes/TaskTypesEdit';
import TaskTypesPage from '../pages/erp/taskTypes/TaskTypesPage';
import TaskCreate from '../pages/erp/tasks/TaskCreate';
import TaskEdit from '../pages/erp/tasks/TaskEdit';
import TaskPage from '../pages/erp/tasks/TaskPage';
import WorkItemEdit from '../pages/erp/workItems/WorkItemEdit';
import WorkItemsPage from '../pages/erp/workItems/WorkItemPage';
import WorkItemPrintView from '../pages/erp/workItems/WorkItemPrintView';
import ItemsUnderThresholdPage from '../pages/itemsUnderThreshold/ItemsUnderThresholdPage';
import Logout from '../pages/logout/LoggedOut';
import TaxTypeCreate from '../pages/pricing/TaxTypes/TaxTypeCreate';
import TaxTypeEdit from '../pages/pricing/TaxTypes/TaxTypeEdit';
import TaxTypePage from '../pages/pricing/TaxTypes/TaxTypePage';
import AcquisitionPricePage from '../pages/pricing/acquisitionPrices/AcquisitionPricePage';
import SalesPagePrintView from '../pages/sales/SalesPagePrintView';
import OrderPage from '../pages/sales/orders/OrderPage';
import OrderView from '../pages/sales/orders/OrderView';
import ExpenseStatistics from '../pages/statistics/ExpenseStatistics';
import FinancePage from '../pages/statistics/FinancePage';
import IncomeStatistics from '../pages/statistics/IncomeStatistics';
import StockReplenishmentPage from '../pages/stockReplenishment/StockReplenishmentPage';
import StockTakingHistory from '../pages/stockTaking/StockTakingHistory';
import StockTakingPage from '../pages/stockTaking/StockTakingPage';
import SystemConfigurationEdit from '../pages/systemConfigurations/SystemConfigurationEdit';
import SystemConfigurationsPage from '../pages/systemConfigurations/SystemConfigurationPage';
import ItemSupplyCreate from '../pages/wms/ItemSupplies/ItemSupplyCreate';
import ItemSupplyEdit from '../pages/wms/ItemSupplies/ItemSupplyEdit';
import ItemSupplyPage from '../pages/wms/ItemSupplies/ItemSupplyPage';
import WarehouseItems from '../pages/wms/warehouses/WarehouseItems';
import SerialNumberedBlockPage from '../pages/deviceManagement/serialNumberedBlocks/SerialNumberedBlockPage';
import SerialNumberedBlockCreate from '../pages/deviceManagement/serialNumberedBlocks/SerialNumberedBlockCreate';
import SerialNumberedBlockEdit from '../pages/deviceManagement/serialNumberedBlocks/SerialNumberedBlockEdit';
import WorkClothesPage from '../pages/deviceManagement/workClothes/WorkClothesPage';
import WorkClothesCreate from '../pages/deviceManagement/workClothes/WorkClothesCreate';
import WorkClothesEdit from '../pages/deviceManagement/workClothes/WorkClothesEdit';
import VehicleServicePage from '../pages/deviceManagement/vehicleServices/VehicleServicePage';
import VehicleServiceCreate from '../pages/deviceManagement/vehicleServices/VehicleServiceCreate';
import VehicleServiceEdit from '../pages/deviceManagement/vehicleServices/VehicleServiceEdit';
import AccessManagementPage from '../pages/hr/accessManagements/AccessManagementPage';
import AccessManagementEdit from '../pages/hr/accessManagements/AccessManagementEdit';
import AccessManagementCreate from '../pages/hr/accessManagements/AccessManagementCreate';
import HRPage from '../pages/hr/HRPage';
import AccessSubjectTypePage from '../pages/hr/accessSubjectTypes/AccessSubjectTypePage';
import AccessSubjectTypeEdit from '../pages/hr/accessSubjectTypes/AccessSubjectTypeEdit';
import AccessSubjectTypeCreate from '../pages/hr/accessSubjectTypes/AccessSubjectTypeCreate';
import CertificationPage from '../pages/hr/certifications/CertificationPage';
import CertificationCreate from '../pages/hr/certifications/CertificationCreate';
import CertificationEdit from '../pages/hr/certifications/CertificationEdit';
import ContractTypePage from '../pages/hr/contractTypes/ContractTypePage';
import ContractTypeCreate from '../pages/hr/contractTypes/ContractTypeCreate';
import ContractTypeEdit from '../pages/hr/contractTypes/ContractTypeEdit';
import MandatoryEducationPage from '../pages/hr/mandatoryEducations/MandatoryEducationPage';
import MandatoryEducationCreate from '../pages/hr/mandatoryEducations/MandatoryEducationCreate';
import MandatoryEducationEdit from '../pages/hr/mandatoryEducations/MandatoryEducationEdit';
import ContractPage from '../pages/hr/contracts/ContractPage';
import ContractCreate from '../pages/hr/contracts/ContractCreate';
import ContractEdit from '../pages/hr/contracts/ContractEdit';
import MissionPage from '../pages/hr/missions/MissionPage';
import MissionCreate from '../pages/hr/missions/MissionCreate';
import MissionEdit from '../pages/hr/missions/MissionEdit';
import UserCertificationPage from '../pages/hr/userCertifications/UserCertificationPage';
import EmailTemplateHomePage from '../pages/emails/emailTemplates/EmailTemplateHomePage';
import EmailTemplateCreate from '../pages/emails/emailTemplates/EmailTemplateCreate';
import EmailTemplateEdit from '../pages/emails/emailTemplates/EmailTemplateEdit';
import AddressPage from '../pages/erp/addresses/AddressPage';
import ProjectPage from '../pages/erp/projects/ProjectPage';
import ProjectCreate from '../pages/erp/projects/ProjectCreate';
import ProjectEdit from '../pages/erp/projects/ProjectEdit';
import EmployeeRankingPage from '../pages/statistics/EmployeeRankingPage';
import CashLogsPage from '../pages/statistics/cashLogs/CashLogsPage';
import CashLogCreate from '../pages/statistics/cashLogs/CashLogCreate';
import CashLogEdit from '../pages/statistics/cashLogs/CashLogEdit';
import CashRegisterPage from '../pages/statistics/cashRegisters/CashRegisterPage';
import CashRegisterCreate from '../pages/statistics/cashRegisters/CashRegisterCreate';
import CashRegisterEdit from '../pages/statistics/cashRegisters/CashRegisterEdit';
import CashPage from '../pages/statistics/cashLogs/CashPage';
import ChimneySweepingCertificateTabPage from '../pages/chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificateTabPage';
import FilesPage from '../pages/chimneySweeping/Files/FilesPage';
import DocumentBlockPage from '../pages/blling/documentBlocks/DocumentBlockPage';
import DocumentBlockCreate from '../pages/blling/documentBlocks/DocumentBlockCreate';
import DocumentBlockEdit from '../pages/blling/documentBlocks/DocumentBlockEdit';
import ReportCategoryPage from '../pages/reports/reportCategories/ReportCategoryPage';
import ReportCategoryCreate from '../pages/reports/reportCategories/ReportCategoryCreate';
import ReportCategoryEdit from '../pages/reports/reportCategories/ReportCategoryEdit';
import ReportPage from '../pages/reports/reports/ReportPage';
import ReportCreate from '../pages/reports/reports/ReportCreate';
import ReportEdit from '../pages/reports/reports/ReportEdit';
import VehicleDamageReportPage from '../pages/reports/vehicleDamageReports/VehicleDamageReportPage';
import VehicleDamageReportCreate from '../pages/reports/vehicleDamageReports/VehicleDamageReportCreate';
import VehicleDamageReportEdit from '../pages/reports/vehicleDamageReports/VehicleDamageReportEdit';
import UserAttendancePage from '../pages/hr/userAttendances/UserAttendancePage';
import ClientProfilePage from '../pages/crm/clients/clientProfile/ClientProfilePage';
import ClientProfileTabPage from '../pages/crm/clients/clientProfile/ClientProfileTabPage';
import BillingPage from '../pages/blling/BillingPage/BillingPage';
import WorkItemsWithInvoiceAfterwards from '../pages/erp/workItems/WorkItemsWithInvoiceAfterwards';
import AbilityPage from '../pages/authority/abilities/AbilityPage';
import AbilityCreate from '../pages/authority/abilities/AbilityCreate';
import AbilityEdit from '../pages/authority/abilities/AbilityEdit';
import CarbonMonoxideSensorCreate from '../pages/chimneySweeping/carbonMonoxideSensors/CarbonMonoxideSensorCreate';
import CarbonMonoxideSensorEdit from '../pages/chimneySweeping/carbonMonoxideSensors/CarbonMonoxideSensorEdit';
import CarbonMonoxideSensorPage from '../pages/chimneySweeping/carbonMonoxideSensors/CarbonMonoxideSensorPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/loggedOut" element={<Logout />} />
      <Route path="/stockTaking" element={<StockTakingPage />} />
      <Route path="/stockTakingHistory" element={<StockTakingHistory />} />
      <Route
        path="/itemsUnderThreshold"
        element={<ItemsUnderThresholdPage />}
      />
      <Route path="/stockReplenishment" element={<StockReplenishmentPage />} />
      <Route path="/sales/orders" element={<OrderPage />} />
      <Route path="/sales/order/view/:id" element={<OrderView />} />
      <Route path="/sales/order/print" element={<SalesPagePrintView />} />
      <Route path="/sales/order" element={<SalesPage />} />
      <Route path="/pricing/customPrices" element={<CustomPricePage />} />
      <Route
        path="/pricing/customPrices/create"
        element={<CustomPriceCreate />}
      />
      <Route
        path="/pricing/customPrices/edit/:id"
        element={<CustomPriceEdit />}
      />
      <Route
        path="/pricing/acquisitionPrices"
        element={<AcquisitionPricePage />}
      />
      <Route path="/pricing/itemPrices" element={<ItemPricePage />} />
      <Route path="/pricing/priceCategories" element={<PriceCategoryPage />} />
      <Route
        path="/pricing/priceCategories/create"
        element={<PriceCategoryCreate />}
      />
      <Route
        path="/pricing/priceCategories/edit/:id"
        element={<PriceCategoryEdit />}
      />
      <Route path="sales/billing" element={<BillingPage />} />
      <Route path="/acquisitions" element={<AcquisitionsPage />} />
      <Route path="/acquisitions/create" element={<AcquisitionCreate />} />
      <Route path="/acquisitions/edit/:id" element={<AcquisitionEdit />} />
      <Route path="/wms/properties" element={<PropertyPage />} />
      <Route path="/wms/properties/create" element={<PropertyCreate />} />
      <Route path="/wms/properties/edit/:id" element={<PropertyEdit />} />
      <Route path="/wms/items" element={<ItemPage />} />
      <Route path="/wms/items/:approval" element={<ItemPage />} />
      <Route path="/wms/items/create" element={<ItemCreate />} />
      <Route path="/wms/items/edit/:id" element={<ItemEdit />} />
      <Route path="/wms/groups" element={<WarehouseGroupsPage />} />
      <Route path="/wms/groups/create" element={<WarehouseGroupCreate />} />
      <Route path="/wms/groups/edit/:id" element={<WarehouseGroupEdit />} />
      <Route path="/wms/transactionLogs" element={<TransactionLogs />} />
      <Route
        path="/wms/transactions/add"
        element={<TransactionCreate command={TransactionCommands.Add} />}
      />
      <Route
        path="/wms/transactions/remove"
        element={<TransactionCreate command={TransactionCommands.Remove} />}
      />
      <Route
        path="/wms/transactions/discard"
        element={<TransactionCreate command={TransactionCommands.Discard} />}
      />
      <Route
        path="/wms/transactions/move"
        element={<TransactionCreate command={TransactionCommands.Move} />}
      />
      <Route path="/wms/warehouses" element={<WarehousesPage />} />
      <Route path="/wms/warehouses/create" element={<WarehouseCreate />} />
      <Route path="/wms/warehouses/edit/:id" element={<WarehouseEdit />} />
      <Route path="/wms/warehouses/:id/items" element={<WarehouseItems />} />
      <Route path="/wms/itemGroups" element={<ItemGroupPage />} />
      <Route path="/wms/itemGroups/create" element={<ItemGroupCreate />} />
      <Route path="/wms/itemGroups/edit/:id" element={<ItemGroupEdit />} />
      <Route path="/wms/brands" element={<BrandPage />} />
      <Route path="/wms/brands/create" element={<BrandCreate />} />
      <Route path="/wms/brands/edit/:id" element={<BrandEdit />} />
      <Route path="/crm/clients" element={<ClientsPage />} />
      <Route path="/crm/addresses" element={<AddressPage />} />
      <Route path="/crm/clients/create" element={<ClientCreate />} />
      <Route path="/crm/clients/edit/:id" element={<ClientEdit />} />
      <Route
        path="/crm/clients/profile/:id"
        element={<ClientProfileTabPage />}
      />
      <Route path="/crm/customers" element={<CustomersPage />} />
      <Route path="/crm/customers/create" element={<CustomerCreate />} />
      <Route path="/crm/customers/edit/:id" element={<CustomerEdit />} />
      <Route path="/authority/userGroups" element={<UserGroupPage />} />
      <Route
        path="/authority/userGroups/create"
        element={<UserGroupCreate />}
      />
      <Route
        path="/authority/userGroups/edit/:id"
        element={<UserGroupEdit />}
      />
      <Route path="/authority/roles" element={<RolePage />} />
      <Route path="/authority/roles/create" element={<RoleCreate />} />
      <Route path="/authority/roles/edit/:id" element={<RoleEdit />} />

      <Route path="/authority/abilitys" element={<AbilityPage />} />
      <Route path="/authority/abilitys/create" element={<AbilityCreate />} />
      <Route path="/authority/abilitys/edit/:id" element={<AbilityEdit />} />

      <Route path="/authority/users" element={<UserPage />} />
      <Route path="/authority/users/edit/:id" element={<UserEdit />} />
      <Route
        path="/systemConfigurations"
        element={<SystemConfigurationsPage />}
      />
      <Route
        path="/systemConfigurations/edit/:id"
        element={<SystemConfigurationEdit />}
      />
      <Route path="/erp/serviceCategories" element={<ServiceCategorysPage />} />
      <Route
        path="/erp/serviceCategories/create"
        element={<ServiceCategoryCreate />}
      />
      <Route
        path="/erp/serviceCategories/edit/:id"
        element={<ServiceCategoryEdit />}
      />
      <Route path="/erp/services" element={<ServicesPage />} />
      <Route path="/erp/services/create" element={<ServiceCreate />} />
      <Route path="/erp/services/edit/:id" element={<ServiceEdit />} />
      <Route path="/erp/taskTypes" element={<TaskTypesPage />} />
      <Route path="/erp/taskTypes/create" element={<TaskTypeCreate />} />
      <Route path="/erp/taskTypes/edit/:id" element={<TaskTypeEdit />} />
      <Route path="/erp/expenseTypes" element={<ExpenseTypePage />} />
      <Route path="/erp/expenseTypes/create" element={<ExpenseTypeCreate />} />
      <Route path="/erp/expenseTypes/edit/:id" element={<ExpenseTypeEdit />} />
      <Route path="/erp/expenses" element={<ExpensePage />} />
      <Route path="/erp/expenses/create" element={<ExpenseCreate />} />
      <Route path="/erp/expenses/edit/:id" element={<ExpenseEdit />} />
      <Route path="/erp/tasks" element={<TaskPage />} />
      <Route path="/erp/tasks/create" element={<TaskCreate />} />
      <Route path="/erp/tasks/edit/:id" element={<TaskEdit />} />
      <Route path="/erp/closingSheets" element={<ClosingSheetPage />} />

      <Route
        path="/erp/closingSheets/edit/:id"
        element={<ClosingSheetEdit />}
      />
      <Route path="/wms/itemSupplies" element={<ItemSupplyPage />} />
      <Route
        path="/wms/itemSupplies/create/:workItemId?"
        element={<ItemSupplyCreate />}
      />
      <Route path="/wms/itemSupplies/edit/:id" element={<ItemSupplyEdit />} />

      <Route path="/erp/workItems" element={<WorkItemsPage />} />
      <Route
        path="/erp/workItemsWithInvoiceAfterwards"
        element={<WorkItemsWithInvoiceAfterwards />}
      />

      <Route
        path="/erp/workItems/edit/:id/:step/:source?"
        element={<WorkItemEdit />}
      />
      <Route path="/erp/workItems/PDF/:id" element={<WorkItemPrintView />} />

      <Route path="/erp/offers" element={<OfferPage />} />
      <Route path="/erp/offers/create/:workItemId" element={<OfferCreate />} />
      <Route path="/erp/offers/edit/:id" element={<OfferEdit />} />
      <Route path="/erp/offers/PDF/:id" element={<OfferPrintView />} />
      <Route path="/erp/userCalendar" element={<UserCalendar />} />
      <Route path="/erp/irregularWorkdays" element={<IrregularWorkdayPage />} />
      <Route
        path="/erp/irregularWorkdays/create"
        element={<IrregularWorkdayCreate />}
      />
      <Route
        path="/erp/irregularWorkdays/edit/:id"
        element={<IrregularWorkdayEdit />}
      />
      <Route path="/erp/holidays" element={<HolidayPage />} />
      <Route path="/erp/holidays/create" element={<HolidayCreate />} />
      <Route path="/erp/holidays/edit/:id" element={<HolidayEdit />} />

      <Route path="/erp/emailTemplates" element={<EmailTemplateHomePage />} />
      <Route
        path="/erp/emailTemplates/create"
        element={<EmailTemplateCreate />}
      />
      <Route
        path="/erp/emailTemplates/edit/:id"
        element={<EmailTemplateEdit />}
      />

      <Route
        path="/statistics/employeeRanking"
        element={<EmployeeRankingPage />}
      />
      <Route path="/statistics/expenses" element={<ExpenseStatistics />} />
      <Route path="/statistics/incomes" element={<IncomeStatistics />} />
      <Route path="/statistics/finance" element={<FinancePage />} />

      <Route path="/pricing/taxTypes" element={<TaxTypePage />} />
      <Route path="/pricing/taxTypes/create" element={<TaxTypeCreate />} />
      <Route path="/pricing/taxTypes/edit/:id" element={<TaxTypeEdit />} />

      <Route path="/billing/cashLogs" element={<CashLogsPage />} />
      <Route path="/billing/cashLogs/create" element={<CashLogCreate />} />
      <Route path="/billing/cashLogs/edit/:id" element={<CashLogEdit />} />

      <Route path="/billing/documentBlocks" element={<DocumentBlockPage />} />
      <Route
        path="/billing/documentBlocks/create"
        element={<DocumentBlockCreate />}
      />
      <Route
        path="/billing/documentBlocks/edit/:id"
        element={<DocumentBlockEdit />}
      />

      <Route path="/billing/cashRegisters" element={<CashRegisterPage />} />
      <Route
        path="/billing/cashRegisters/create"
        element={<CashRegisterCreate />}
      />
      <Route
        path="/billing/cashRegisters/edit/:id"
        element={<CashRegisterEdit />}
      />
      <Route path="/billing/cashPage" element={<CashPage />} />

      <Route
        path="/erp/performanceCategories"
        element={<PerformanceCategoryPage />}
      />
      <Route
        path="/erp/performanceCategories/create"
        element={<PerformanceCategoryCreate />}
      />
      <Route
        path="/erp/performanceCategories/edit/:id"
        element={<PerformanceCategoryEdit />}
      />
      <Route path="/erp/chimneyUseCases" element={<ChimneyUseCasePage />} />
      <Route
        path="/erp/chimneyUseCases/create"
        element={<ChimneyUseCaseCreate />}
      />
      <Route
        path="/erp/chimneyUseCases/edit/:id"
        element={<ChimneyUseCaseEdit />}
      />
      <Route path="/erp/chimneyErrorCodes" element={<ChimneyErrorCodePage />} />
      <Route
        path="/erp/chimneyErrorCodes/create"
        element={<ChimneyErrorCodeCreate />}
      />
      <Route
        path="/erp/chimneyErrorCodes/edit/:id"
        element={<ChimneyErrorCodeEdit />}
      />
      <Route path="/erp/chimneyTypes" element={<ChimneyTypePage />} />
      <Route path="/erp/chimneyTypes/create" element={<ChimneyTypeCreate />} />
      <Route path="/erp/chimneyTypes/edit/:id" element={<ChimneyTypeEdit />} />
      <Route path="/erp/decisions" element={<DecisionPage />} />
      <Route path="/erp/decisions/create" element={<DecisionCreate />} />
      <Route path="/erp/decisions/edit/:id" element={<DecisionEdit />} />
      <Route path="/erp/projects" element={<ProjectPage />} />
      <Route path="/erp/projects/create" element={<ProjectCreate />} />
      <Route path="/erp/projects/edit/:id" element={<ProjectEdit />} />
      <Route path="/erp/chimneys" element={<ChimneyPage />} />
      <Route path="/erp/chimneys/create" element={<ChimneyCreate />} />
      <Route path="/erp/chimneys/edit/:id" element={<ChimneyEdit />} />
      <Route
        path="/erp/carbonMonoxideSensor"
        element={<CarbonMonoxideSensorPage />}
      />
      <Route
        path="/erp/carbonMonoxideSensor/create"
        element={<CarbonMonoxideSensorCreate />}
      />
      <Route
        path="/erp/carbonMonoxideSensor/edit/:id"
        element={<CarbonMonoxideSensorEdit />}
      />
      <Route path="/erp/files" element={<FilesPage />} />
      <Route
        path="/erp/chimneySweepingCertificates"
        element={<ChimneySweepingCertificatePage />}
      />
      <Route
        path="/erp/chimneySweepingCertificateTabPage"
        element={<ChimneySweepingCertificateTabPage />}
      />
      <Route
        path="/erp/chimneySweepingCertificates/create"
        element={<ChimneySweepingCertificateCreate />}
      />
      <Route
        path="/erp/chimneySweepingCertificates/create/:documentNumber"
        element={<ChimneySweepingCertificateCreate />}
      />
      <Route
        path="/erp/chimneySweepingCertificates/edit/:id"
        element={<ChimneySweepingCertificateEdit />}
      />
      <Route path="/erp/computers" element={<ComputerPage />} />
      <Route path="/erp/computers/create" element={<ComputerCreate />} />
      <Route path="/erp/computers/edit/:id" element={<ComputerEdit />} />
      <Route path="/erp/mobiles" element={<MobilePage />} />
      <Route
        path="/erp/userCertifications"
        element={<UserCertificationPage />}
      />
      <Route path="/erp/mobiles/create" element={<MobileCreate />} />
      <Route path="/erp/mobiles/edit/:id" element={<MobileEdit />} />
      <Route path="/erp/tools" element={<ToolPage />} />
      <Route path="/erp/tools/create" element={<ToolCreate />} />
      <Route path="/erp/tools/edit/:id" element={<ToolEdit />} />
      <Route path="/erp/missions" element={<MissionPage />} />
      <Route path="/erp/missions/create" element={<MissionCreate />} />
      <Route path="/erp/missions/edit/:id" element={<MissionEdit />} />
      <Route path="/erp/contracts" element={<ContractPage />} />
      <Route path="/erp/contracts/create" element={<ContractCreate />} />
      <Route path="/erp/contracts/edit/:id" element={<ContractEdit />} />
      <Route
        path="/erp/mandatoryEducations"
        element={<MandatoryEducationPage />}
      />
      <Route
        path="/erp/mandatoryEducations/create"
        element={<MandatoryEducationCreate />}
      />
      <Route
        path="/erp/mandatoryEducations/edit/:id"
        element={<MandatoryEducationEdit />}
      />
      <Route path="/erp/contractTypes" element={<ContractTypePage />} />
      <Route
        path="/erp/contractTypes/create"
        element={<ContractTypeCreate />}
      />
      <Route
        path="/erp/contractTypes/edit/:id"
        element={<ContractTypeEdit />}
      />
      <Route path="/reports/reportCategorys" element={<ReportCategoryPage />} />
      <Route
        path="/reports/reportCategorys/create"
        element={<ReportCategoryCreate />}
      />
      <Route
        path="/reports/reportCategorys/edit/:id"
        element={<ReportCategoryEdit />}
      />
      <Route path="/reports/reports" element={<ReportPage />} />
      <Route path="/reports/reports/create" element={<ReportCreate />} />
      <Route path="/reports/reports/edit/:id" element={<ReportEdit />} />
      <Route
        path="/reports/vehicleDamageReports"
        element={<VehicleDamageReportPage />}
      />
      <Route
        path="/reports/vehicleDamageReports/create"
        element={<VehicleDamageReportCreate />}
      />
      <Route
        path="/reports/vehicleDamageReports/edit/:id"
        element={<VehicleDamageReportEdit />}
      />
      <Route path="/erp/userAttendances" element={<UserAttendancePage />} />
      <Route path="/erp/certifications" element={<CertificationPage />} />
      <Route
        path="/erp/certifications/create"
        element={<CertificationCreate />}
      />
      <Route
        path="/erp/certifications/edit/:id"
        element={<CertificationEdit />}
      />
      <Route
        path="/erp/accessSubjectTypes"
        element={<AccessSubjectTypePage />}
      />
      <Route
        path="/erp/accessSubjectTypes/create"
        element={<AccessSubjectTypeCreate />}
      />
      <Route
        path="/erp/accessSubjectTypes/edit/:id"
        element={<AccessSubjectTypeEdit />}
      />
      <Route path="/erp/hr" element={<HRPage />} />
      <Route path="/erp/accessManagements" element={<AccessManagementPage />} />
      <Route
        path="/erp/accessManagements/create"
        element={<AccessManagementCreate />}
      />
      <Route
        path="/erp/accessManagements/edit/:id"
        element={<AccessManagementEdit />}
      />
      <Route path="/erp/vehicleServices" element={<VehicleServicePage />} />
      <Route
        path="/erp/vehicleServices/create"
        element={<VehicleServiceCreate />}
      />
      <Route
        path="/erp/vehicleServices/edit/:id"
        element={<VehicleServiceEdit />}
      />
      <Route path="/erp/workClothes" element={<WorkClothesPage />} />
      <Route path="/erp/workClothes/create" element={<WorkClothesCreate />} />
      <Route path="/erp/workClothes/edit/:id" element={<WorkClothesEdit />} />
      <Route path="/erp/vehicles" element={<VehiclePage />} />
      <Route path="/erp/vehicles/create" element={<VehicleCreate />} />
      <Route path="/erp/vehicles/edit/:id" element={<VehicleEdit />} />
      <Route path="/erp/simCards" element={<SimCardPage />} />
      <Route path="/erp/simCards/create" element={<SimCardCreate />} />
      <Route path="/erp/simCards/edit/:id" element={<SimCardEdit />} />
      <Route path="/erp/instruments" element={<InstrumentPage />} />
      <Route path="/erp/instruments/create" element={<InstrumentCreate />} />
      <Route path="/erp/instruments/edit/:id" element={<InstrumentEdit />} />
      <Route path="/erp/peripherals" element={<PeripheryPage />} />
      <Route path="/erp/peripherals/create" element={<PeripheryCreate />} />
      <Route path="/erp/peripherals/edit/:id" element={<PeripheryEdit />} />
      <Route
        path="/erp/serialnumberedblocks"
        element={<SerialNumberedBlockPage />}
      />
      <Route
        path="/erp/serialnumberedblocks/create"
        element={<SerialNumberedBlockCreate />}
      />
      <Route
        path="/erp/serialnumberedblocks/edit/:id"
        element={<SerialNumberedBlockEdit />}
      />
      <Route
        path="/erp/serialnumberedblocktypes"
        element={<SerialNumberedBlockTypePage />}
      />
      <Route
        path="/erp/serialnumberedblocktypes/create"
        element={<SerialNumberedBlockTypeCreate />}
      />
      <Route
        path="/erp/serialnumberedblocktypes/edit/:id"
        element={<SerialNumberedBlockTypeEdit />}
      />
      <Route
        path="/erp/deviceManagementPage"
        element={<DeviceManagementPage />}
      />
      <Route path="/" element={<Dashboard />} />
    </Routes>
  );
};

export default AppRoutes;
