import { PDFViewer } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import clientService from '../../../services/crm/clientService';
import orderService from '../../../services/sales/orderService';
import { OrderPDF } from './OrderPDF';

const OrderView = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { orderId, values = null } = props;
  const [errors, setErrors] = useState<string[]>([]);
  const [client, setClient] = React.useState<any>();
  const dispatch = useDispatch<any>();
  const [order, setOrder] = useState<any>({
    items: [],
    invoiceUrl: '',
    invoiceNumber: '',
    discount: '',
  });

  useEffect(() => {
    if (order.clientId) {
      dispatch({ type: 'SHOW_QUERY' });
      clientService
        .get(order?.clientId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setClient(response.result);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [order?.clientId]);

  useEffect(() => {
    if (orderId > 0 ? orderId > 0 : parseInt(params.id) > 0) {
      orderService.get(orderId > 0 ? orderId : params.id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setOrder(response.result);
        }
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (values != null) {
      setOrder(values);
    }
  }, [values]);

  const calculatefinalPrice = (isGross: boolean) => {
    let itemSum = 0;
    let serviceSum = 0;
    let itemPackageSum = 0;

    if (order && order.items) {
      itemSum = order.items.reduce(
        (sum, item) =>
          sum +
          (isGross ? item.sellPriceGross : item.sellPriceNet) * item.amount,
        0
      );
    }

    if (order && order.services) {
      serviceSum = order.services.reduce(
        (sum, service) =>
          sum +
          (isGross ? service.servicePriceGross : service.servicePriceNet) *
            (service.quantity > 0 ? service.quantity : service.amount),
        0
      );
    }

    if (order && order.itemPackages) {
      itemPackageSum = order.itemPackages.reduce(
        (sum, itemPackage) =>
          sum +
          (isGross ? itemPackage.sellPriceGross : itemPackage.sellPriceNet) *
            itemPackage.amount,
        0
      );
    }
    return itemSum + serviceSum + itemPackageSum;
  };
  return (
    <div>
      <PDFViewer
        width={'100%'}
        height={window.outerHeight - 50}
        children={
          <OrderPDF
            order={order}
            client={client}
            calculatefinalPrice={calculatefinalPrice}
          />
        }
      ></PDFViewer>
    </div>
  );
};

export default OrderView;
