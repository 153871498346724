import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OfferForm from './OfferForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import offerService from '../../../services/erp/offerService';
import serviceService from '../../../services/erp/serviceService';
import clientService from '../../../services/crm/clientService';
import itemPriceService from '../../../services/pricing/itemPriceService';
import priceCategoryService from '../../../services/pricing/priceCategoryService';
import { reset } from '../../../stateManagement/actions/offerActions';
import workItemService from '../../../services/erp/workItemService';
import taxTypeService from '../../../services/erp/taxTypeService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import documentBlockService from '../../../services/billing/documentBlockService';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';
import moment from 'moment';

const OfferCreate = (props: any) => {
  const { onSave = null, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [now] = useState(new Date());

  const { checkPermission } = useCheckPermission();

  const [services, setServices] = useState<any[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const params = useParams();
  const [listPrices, setListPrices] = useState<any[]>([]);
  const [priceCategories, setPriceCategories] = useState<any[]>([]);
  const [priceCategoryId, setPriceCategoryId] = useState<number>(0);
  const [documentBlocks, setDocumentBlocks] = useState<any[]>([]);
  const { form, offerInProgress } = useSelector<any>(
    (state) => state.offer
  ) as any;
  const [entity, setEntity] = useState<any>(
    offerInProgress
      ? form
      : {
          expireAt: moment(now).add(30, 'days').toDate(),
          clientId: null,
          clientName: '',
          documentBlockId: null,
          offerNumber: '',
          planned: null,
          workItemId: '',
          conditions:
            '- egyedileg gyártott termékek esetén 100% anyagköltség-előleg\n- végösszeg fizetése átadáskor készpénzben, azonnali átutalással, vagy egyedi megállapodás/szerződés szerint (bankkártyával, vagy utólagos átutalással)',
          guarantee:
            '- munka és szerelvények tekintetében 1 év, vagy az aktuális jogszabályok alapján- új nyílászárók esetén 5 év, amelyből 1 év jótállás, 4 év szavatosság',
          remark:
            '- az ajánlat felülvizsgálat nélkül 30 napig érvényes\n- a feltüntetett árak tartalmazzák a segédanyagok és a logisztikai költségek díját is\n- a feltüntetett díjak nem tartalmazzák az esetlegesen felmerülő további szakipari munkákat, pl. a végleges felületképzés díját (festés, esetleges burkolás), ill. az esetlegesen felmerülő további anyagszükséglet díját\n- a feltüntetett árak tartalmazzák az esetlegesen felhasználandó elektronikus szerelvények bekötéshez szükséges előkészítést, de az épületfelügyeleti rendszerre való rákötést nem\n- a feltüntetetett árak a teljes mennyiség egy ütemben való megrendelését feltételezik',
          selectedServices: [],
          deadLine:
            '- megrendeléskori raktárkészlet és kapacitás szerint (jellemzően 2-10 munkanap)',
          selectedItems: [],
          itemPackages: [],
          workAddressId: null,
          billingAddressId: null,
          priceCategoryId: null,
          task: '',
          taskDescription: '',
          representativeName: {},
          representativePhone: '',
          createdAt: now,
        }
  );

  useEffect(() => {
    documentBlockService.list(DocumentBlockTypes.Offer).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setDocumentBlocks(response.records);
      }
    });
  }, []);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    if (parseInt(params.workItemId) > 0) {
      workItemService.get(params.workItemId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          if (!form.id)
            setEntity({
              ...entity,
              workItemId: response.result.id,
              selectedItems: response.result.selectedItems,
              selectedServices: response.result.selectedServices,
              itemPackages: response.result.itemPackages,
              clientId: response.result.clientId,
              workAddressId: response.result.workAddressId,
              billingAddressId: response.result.billingAddressId,
              priceCategoryId: response.result.priceCategoryId,
            });
        }
      });
    }
  }, [params.workItemId]);

  useEffect(() => {
    priceCategoryService.list(null).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setPriceCategories(response.records);
      }
    });
  }, []);

  useEffect(() => {
    if (priceCategoryId > 0) {
      itemPriceService
        .listListPrices(null, priceCategoryId, true)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setListPrices(response.records);
          }
        });
    }
  }, [priceCategoryId]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    clientService.list(false, null).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
      }
      dispatch({ type: 'HIDE' });
    });
  }, []);

  useEffect(() => {
    serviceService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setServices(response.records);
      }
    });
  }, []);

  const handleLoadItemPrices = (
    warehouseId: number,
    priceCategoryId: number,
    setFieldValue: any
  ) => {
    itemPriceService
      .listListPrices(warehouseId, priceCategoryId, true)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setListPrices(response.records);
          var clonedSelectedItems = [
            ...(form.selectedItems ?? entity.selectedItems),
          ];
          var updatedSelectedItems = clonedSelectedItems.map((selectedItem) => {
            var matchedItem = response.records.find(
              (item) => item.itemId === selectedItem.itemId
            );
            if (matchedItem) {
              return {
                ...selectedItem,
                sellPriceGross: matchedItem.gross,
                sellPriceNet: matchedItem.net,
              };
            }
            return selectedItem;
          });
          setFieldValue('selectedItems', [...updatedSelectedItems]);
          if (form.itemPackages && form.itemPackages.length > 0) {
            var clonedItemPackages = [...form.itemPackages];

            let newItemPackages = clonedItemPackages.map((itemPackage) => {
              let sellPriceGross = 0;
              let sellPriceNet = 0;
              let newItems = itemPackage.items?.map((item) => {
                let found = response.records.find(
                  (y) => y.itemId === item.itemId
                );

                sellPriceGross += found?.gross ?? 0 * item?.amount ?? 0;
                sellPriceNet += found?.net ?? 0 * item?.amount ?? 0;
                return {
                  ...item,
                  sellPriceNet: found?.net ?? 0,
                  sellPriceGross: found?.gross ?? 0,
                };
              });
              itemPackage.services?.map((service) => {
                sellPriceGross +=
                  service?.servicePriceGross ?? 0 * service?.amount ?? 0;
                sellPriceNet +=
                  service?.servicePriceNet ?? 0 * service?.amount ?? 0;
              });
              return {
                ...itemPackage,
                items: newItems,
                sellPriceGross,
                sellPriceNet,
              };
            });
            setFieldValue('itemPackages', [...newItemPackages]);
          }
        }
      });
  };

  const onSubmit = (
    entity: any,
    setSubmitting: any,
    representative: any,
    pdfData: any
  ) => {
    dispatch({ type: 'SHOW_SAVE' });
    if (representative) {
      clientService.updateRepresentative(representative).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      });
    }
    offerService
      .create({ ...entity, pdfData })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          dispatch(reset());
          navigate(`/erp/offers`);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <OfferForm
      entity={entity}
      readonly={!checkPermission(['OfferCreate'])}
      onSubmit={onSubmit}
      errors={errors}
      clients={clients}
      setClients={setClients}
      services={services}
      taxes={taxes}
      listPrices={listPrices}
      isWorkItem={parseInt(params.workItemId) > 0}
      priceCategories={priceCategories}
      priceCategoryId={priceCategoryId}
      loadItemPrices={handleLoadItemPrices}
      setPriceCategoryId={setPriceCategoryId}
      documentBlocks={documentBlocks}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default OfferCreate;
