import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility,
} from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from "@mui/x-data-grid";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import { DatePicker } from "@mui/x-date-pickers";
import { isSameDay, parseJSON, subDays } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import DataList from "../../../components/DataList";
import useCheckPermission from "../../../hooks/useCheckPermission";
import useConfirmDeleteDialog from "../../../hooks/useConfirmDeleteDialog";
import useFunctionDescriptor from "../../../hooks/useFunctionDescriptor";
import closingSheetService from "../../../services/erp/closingSheetService";
import { formatCurrency, formatTime } from "../../../utils/valueFormatters";
import moment from "moment";
import userService from "../../../services/authority/userService";
import { ClosingSheetWorkItemsDialog } from "./ClosingSheetWorkItemsDialog";
import AssignmentIcon from "@mui/icons-material/Assignment";

export interface ClosingSheet {
  id: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  comment: string;
  openBalance: number;
  cash: number;
  creditCard: number;
  transfer: number;
  expense: number;
}

const ClosingSheetPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor("ClosingSheetPage.title");
  const [selectedDate, setSelectedDate] = useState<any>({
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
  });
  const [checkboxValue, setCheckboxValue] = useState<boolean>(true);
  const [users, setUsers] = useState<any[]>([]);
  const [userId, setUserId] = useState<number | null>(null);
  const [workItemsDialogState, setWorkItemsDialogState] = useState({
    open: false,
    userId: 0,
    startDate: new Date(),
    endDate: new Date(),
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    dispatch({ type: "SHOW_QUERY" });
    if (checkPermission(["ClosingSheetViewAll"]) && !checkboxValue) {
      closingSheetService
        .list(userId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            const filteredRows = response.records.filter(
              (row) =>
                new Date(row.date) >= selectedDate.startDate &&
                new Date(row.date) <= selectedDate.endDate
            );
            setRows(filteredRows);
          } else {
            enqueueSnackbar(response.errorMessages.join(","), {
              variant: "error",
            });
          }
        })
        .finally(() => dispatch({ type: "HIDE" }));
    } else {
      closingSheetService
        .listmy()
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            const filteredRows = response.records.filter(
              (row) =>
                new Date(row.date) >= selectedDate.startDate &&
                new Date(row.date) <= selectedDate.endDate
            );
            setRows(filteredRows);
          } else {
            enqueueSnackbar(response.errorMessages.join(","), {
              variant: "error",
            });
          }
        })
        .finally(() => dispatch({ type: "HIDE" }));
    }
  }, [selectedDate, checkboxValue, userId]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <GridActionsCellItem
        color={color ? color : "primary"}
        icon={
          <Tooltip title="Munkalapok">
            <AssignmentIcon />
          </Tooltip>
        }
        label="Munkalapok"
        onClick={() => {
          let startTime = new Date(params.row.startTime);
          let endTime = new Date(params.row.endTime);

          setWorkItemsDialogState({
            open: true,
            userId: params.row.createdById,
            startDate: new Date(
              new Date(params.row.date).setHours(
                startTime.getHours(),
                startTime.getMinutes(),
                0,
                0
              )
            ),
            endDate: new Date(
              new Date(params.row.date).setHours(
                endTime.getHours(),
                endTime.getMinutes(),
                0,
                0
              )
            ),
          });
        }}
      />
    );

    let readonly =
      !checkPermission(["ClosingSheetEdit"]) ||
      new Date().toDateString() !==
        moment.utc(params.row.date).local().toDate().toDateString();

    actions.push(
      <GridActionsCellItem
        color={color ? color : "primary"}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? "Megtekintés" : "Szerkesztés"}
        onClick={() => navigate(`/erp/closingSheets/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(["ClosingSheetDelete"])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : "primary"}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: "",
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };
  const columns: GridColDef[] = [
    {
      field: "employeeName",
      headerName: "Dolgozó",
      flex: 200,
    },
    {
      field: "date",
      headerName: "Dátum",
      flex: 200,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleDateString();
      },
    },
    {
      field: "startTime",
      headerName: "Kezdés",
      flex: 100,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleTimeString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      },
    },
    {
      field: "endTime",
      headerName: "Végzés",
      flex: 100,
      valueFormatter: (params) => {
        if (!params.value) return "Nincs";
        return new Date(params.value).toLocaleTimeString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      },
    },
    {
      field: "totalIncome",
      headerName: "Összes bevétel",
      flex: 200,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "totalExpense",
      headerName: "Kiadás",
      flex: 200,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "handOver",
      headerName: "Asztal",
      flex: 200,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    { field: "comment", headerName: "Megjegyzés", flex: 200 },
    {
      field: "actions",
      type: "actions",
      flex: 150,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    closingSheetService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Sikeres törlés", {
          variant: "success",
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3} spacing={2}>
        <Grid item xs={12}>
          <h2>Zárólapok{titleDescriptor}</h2>
        </Grid>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={"auto"}>
            <DatePicker
              value={selectedDate.startDate}
              onChange={(value) =>
                setSelectedDate({ ...selectedDate, startDate: value })
              }
              label="Kezdő dátum"
              inputFormat="yyyy-MM-dd"
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={"auto"}>
            <DatePicker
              value={selectedDate.endDate}
              onChange={(value) =>
                setSelectedDate({
                  ...selectedDate,
                  endDate: moment(value).endOf("day").toDate(),
                })
              }
              label="Vég dátum"
              inputFormat="yyyy-MM-dd"
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          {checkPermission(["ClosingSheetViewAll"]) && (
            <Grid item xs={12} md={"auto"}>
              <Autocomplete
                disablePortal
                id="userId"
                value={userId}
                style={{ minWidth: 300 }}
                onChange={(event, value) => {
                  setUserId(value);
                  setCheckboxValue(false);
                }}
                getOptionLabel={(option) => {
                  if (option === null) {
                    return "Mindegy";
                  }
                  let user = users.find((g) => g.id === option);
                  return `${user?.fullName} (${user?.email ?? ""})`;
                }}
                options={users.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Dolgozó" />
                )}
              />
            </Grid>
          )}
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!checkPermission(["ClosingSheetViewAll"])}
                  checked={checkboxValue}
                  onChange={(e, checked) => {
                    setCheckboxValue(checked);
                    setUserId(null);
                  }}
                />
              }
              label={"Saját adatok"}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={"ClosingSheetPage"}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <ClosingSheetWorkItemsDialog
          open={workItemsDialogState.open}
          onClose={() =>
            setWorkItemsDialogState({
              open: false,
              userId: 0,
              startDate: new Date(),
              endDate: new Date(),
            })
          }
          startDate={workItemsDialogState.startDate}
          endDate={workItemsDialogState.endDate}
          userId={workItemsDialogState.userId}
        />
      </Grid>
    </Paper>
  );
};

export default ClosingSheetPage;
