import { PDFViewer } from '@react-pdf/renderer';

import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import offerService from '../../../services/erp/offerService';
import { OfferPDF } from './OfferPDF';

const OfferPrintView = (props: any) => {
  const { id } = useParams();
  const { offerId = 0 } = props;
  const [offer, SetOffer] = React.useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const user = useSelector((state: any) => state.user.userInfo);

  useEffect(() => {
    let getId = offerId > 0 ? offerId : parseInt(id ?? '0');
    if (!(getId > 0)) return;
    dispatch({ type: 'SHOW_QUERY' });
    offerService
      .get(getId)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) SetOffer(response.result);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const calculateNetSum = useCallback(() => {
    let itemNetSum = 0;
    let serviceNetSum = 0;
    let itemPackagesNetSum = 0;

    if (offer && offer.selectedItems) {
      itemNetSum = offer.selectedItems.reduce(
        (sum, item) =>
          sum + item.sellPriceNet * (1 - item.discount / 100) * item.amount,
        0
      );
    }

    if (offer && offer.selectedServices) {
      serviceNetSum = offer.selectedServices.reduce(
        (sum, service) =>
          sum +
          service.servicePriceNet *
            (1 - service.discount / 100) *
            service.amount,
        0
      );
    }
    if (offer && offer.itemPackages) {
      itemPackagesNetSum = offer.itemPackages.reduce(
        (sum, itemPackage) =>
          sum +
          itemPackage.sellPriceNet *
            (1 - itemPackage.discount / 100) *
            itemPackage.amount,
        0
      );
    }

    return itemNetSum + serviceNetSum + itemPackagesNetSum;
  }, [offer]);

  return (
    <div>
      <PDFViewer
        width={'100%'}
        height={window.outerHeight - 50}
        children={
          <OfferPDF
            offer={offer}
            user={user}
            createdAt={new Date(offer?.createdAt).toLocaleDateString('hu-HU')}
            calculateNetSum={calculateNetSum}
          />
        }
      ></PDFViewer>
    </div>
  );
};

export default OfferPrintView;
